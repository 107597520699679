import initChannelsSettings from "../Settings/Channels";
import initClassifiersSettings from "../Settings/Classifiers";
import initRepliesSettings from "../Settings/RepliesTemplate";
import initVipClients from "../Settings/VipClients";
import initSystemSettings from "../Settings/SystemSettings";
import initAutorepliesSettings from "../Settings/Autoreplies";
import initRolesSettings from "../Settings/Roles";
import initChatbotSettings from "../Settings/Chatbots";
import initUserSettings from "../Settings/Users";

import {
  userAdd,
  skillAdd,
  groupGo,
  groupsCount,
  groupsShow,
  userCheckAll,
  userCheck,
  userClick,
  userShow,
  usersDelete,
  dropDownShow,
  dropDownHide,
  userMove,
  userMoveShowGroups,
  userSearch,
  restorePassword,
  usersSettings,
  skillDelete,
  showInformerAfterReloadKey
} from "./Constructors/UserSettingsConstructor";
import Informer from "./Wrappers/Informer";

export default {
  draw: {
    tpls: function(data) {
      var tpls = {};
      var section = msg.settings.nav.get_section(data);
      if (data.urlCur.sec !== "settings") tpls.settings = "body";

      if (section == "replies") {
        if (data.urlCur.params.section == "replies") {
          tpls.settings_replies = "settings_body";
        }
      } else if (section == "groups") {
        if (
          data.urlCur.params.section == "groups" &&
          data.url.params.skill_id != undefined
        ) {
          tpls.settings_groups_users_header = "settings_groups_users_header";
          tpls.settings_groups_users = "groups_users";
        }
      } else if (section == "themes") {
        if (data.urlCur.params.section == "themes") {
          tpls.settings_themes = "settings_body";
        }
      } else if (section == "reports") {
        tpls.settings_reports = "settings_body";
      } else if (section == "bots") {
      } else if (section == "channels") {
        initChannelsSettings();
      } else if (section == "autoreplies") {
        if (data.urlCur.params.type !== data.url.params.type) {
          tpls.settings_autoreplies_items_list =
            "settings_autoreplies_items_list";
          tpls.settings_autoreplies_preview = "settings_autoreplies_preview";
        }
      } else if (section == "roles") {
        if (
          data.urlCur.params.section == "roles" &&
          data.url.params.role_id != undefined
        ) {
          tpls.settings_roles_rights = "settings_roles_rights";
          tpls.settings_roles_list = "settings_roles_list";
        }
      }

      if (data.el && data.urlCur.params.section !== data.url.params.section) {
        $(".settings_menu")
          .find(".sel")
          .removeClass("sel");
        data.el.addClass("sel");
      }
      return tpls;
    }
  },

  nav: {
    /**
     * MES-2803
     * Получение "section" для правильной отработки функций after(), init() и т.п. для разделов меню
     * Например: инициализация пагинатора для раздела "Сотрудники"
     * P.S.: Ранее "section" получался из REQUEST_URI, что вызывало ошибку в случае первого перехода на страницу настроек
     */
    get_section: function(data) {
      return (
        data.url.params.section || $(".settings_panel a.sel").data("section")
      );
    },

    init: function(data) {
      var section = msg.settings.nav.get_section(data);
      $(".body").removeClass("load");

      $(window)
        .on({
          resize: msg.settings.resize
        })
        .trigger("resize");

      core.tools.loadCode("scroll.js", function() {
        $(".wsScroll").nanoScroller();

        if (section === "clients") {
          $(".settings_panel a.sel").removeClass("sel");
          $(".settings_panel a.clients").addClass("sel");
          initVipClients();
        } else if (section == "system") {
          $(".settings_panel a.sel").removeClass("sel");
          $(".settings_panel a.system").addClass("sel");
          initSystemSettings();
        } else if (section == "replies") {
          initRepliesSettings();
        } else if (section == "themes") {
          initClassifiersSettings();
        } else if (section == "channels") {
          initChannelsSettings();
        } else if (section == "autoreplies") {
          initAutorepliesSettings();
        } else if (section == "groups") {
          msg.settings.groups_scroll();
          if (!/all/.test($(".replies_categoryWrap.sel").attr("id")))
            $(".groups_firstScroll").nanoScroller({
              scrollTop:
                $(".groups_skillWrap.sel").position().top -
                window.innerHeight / 2
            });

          msg.pagination.init({
            el: $(".groups_users"),
            search: $("[name=skill_user_search]"),
            tpl: "settings_groups_user_list"
          });
          initUserSettings();
        } else if (section == "roles") {
          msg.settings.roles_scroll();
          initRolesSettings();
        } else if (section == "reports") {
          msg.settings.reports_scroll();
        } else if (section == "bots") {
          msg.pagination.init({
            el: $(".settings_bots_users_list"),
            search: null,
            tpl: "settings_bots_users_list"
          });
          initChatbotSettings();
        }
      });

      const showInformer = window.sessionStorage.getItem(
        showInformerAfterReloadKey
      );
      if (showInformer) {
        window.sessionStorage.removeItem(showInformerAfterReloadKey);
        new Informer("Изменения успешно сохранены", 3000).show();
      }
    },

    destroy: function() {
      msg.settings.height = 0;

      msg.pagination.destroy();
    },

    before: function(data) {
      msg.settings.groups.users = [];
      msg.settings.groups.users_marked = false;
      var section = msg.settings.nav.get_section(data);

      if (section == "groups") {
        msg.settings.groups_scroll(false);
      } else if (section == "roles") {
        msg.settings.roles_scroll(false);
      } else if (section == "reports") {
        msg.settings.reports_scroll(false);
      }
    },

    after: function(data) {
      msg.pagination.destroy();
      var section = msg.settings.nav.get_section(data);
      $(".wsScroll").nanoScroller();

      if (section == "replies") {
        initRepliesSettings();
      } else if (section == "autoreplies") {
        initAutorepliesSettings();
      } else if (section == "themes") {
        initClassifiersSettings();
      } else if (section == "groups") {
        msg.settings.groups_scroll();
        $(".groups_usersScroll").nanoScroller({
          scrollTop: msg.settings.users_scroll
        });
        msg.pagination.init({
          el: $(".groups_users"),
          search: $(".groups_usersWrap .search"),
          tpl: "settings_groups_user_list"
        });
        initUserSettings();
      } else if (section == "reports") {
        msg.settings.reports_scroll();
      } else if (section == "channels") {
        var channel = $(".settings_channels")
          .find('select[name="filter_group"]')
          .val();
        msg.settings.channels.channel_id = channel;
      } else if (section == "roles") {
        msg.settings.roles_scroll();
        initRolesSettings();
      } else if (section == "bots") {
        msg.pagination.init({
          el: $(".settings_bots_users_list"),
          search: null,
          tpl: "settings_bots_users_list"
        });
        initChatbotSettings();
      }
    }
  },

  confirm_modal: function(
    el,
    firstData,
    secondData,
    currentCategory,
    currentAction
  ) {
    if (el.hasClass("load")) return false;

    el.addClass("load");

    $.ajax({
      url: "/msg?sec=settings_confirm_modal",
      data: {
        project_id: core.nav.url.params.project_id,
        firstData: firstData,
        secondData: secondData,
        currentCategory: currentCategory,
        currentAction: currentAction,
        tpls: {
          settings_confirm_modal: "window"
        }
      },
      dataType: "JSON",
      type: "GET",
      success: function(r) {
        core.draw.window({
          id: "settings_confirm_modal",
          body: r.tpls.window,
          close: {
            overlay: false,
            esc: false
          }
        });
        el.removeClass("load");
      }
    });
  },

  resize: function() {
    $("#msg-module .settings").css({
      height: core.user.winHeight
    });
  },

  users_scroll: 0,

  groups_scroll: function() {
    $(".groups_firstScroll").nanoScroller({
      flash: !0,
      alwaysVisible: true
    });
    $(".groups_usersScroll").nanoScroller({
      flash: !0,
      alwaysVisible: true
    });

    $(".groups_usersScroll").on("update", function(event, vals) {
      msg.settings.users_scroll = vals.position;
    });
  },

  reports_scroll: function() {
    $(".settings_reportsWrap").nanoScroller({
      flash: !0,
      alwaysVisible: true
    });
  },

  roles_scroll: function() {
    $(".settings_rolesScroll").nanoScroller({
      flash: !0,
      alwaysVisible: true
    });
    $(".roles_permissionScroll").nanoScroller({
      flash: !0,
      alwaysVisible: true
    });
  },

  height: 0,

  roles: {
    modal: {},

    roleChange: function(e, el) {
      $(".roles_list .sel").removeClass("sel");
      el.addClass("sel");

      var new_url = el.attr("href");

      $.ajax({
        url: new_url,
        data: {
          tpls: {
            settings_roles_rights: "settings_roles_rights"
          }
        },
        type: "GET",
        dataType: "JSON",
        success: function(d) {
          history.pushState(null, null, new_url);
          var settings_roles_rights = $(".settings_roles_rights");
          settings_roles_rights.html(d.tpls.settings_roles_rights);
          settings_roles_rights.find(".wsScroll").nanoScroller();
        }
      });
    },

    openEditRightsModal: function(e, el, project_id, role_id) {
      if (el.hasClass("load")) return false;

      if (e) e.stopPropagation();
      var url = "/msg?sec=" + core.nav.url.sec;

      el.addClass("load");

      $.ajax({
        url: url,
        data: {
          project_id: project_id,
          role_id: role_id,
          tpls: {
            settings_roles_rights_edit: "window"
          }
        },
        dataType: "JSON",
        type: "GET",
        success: function(r) {
          el.removeClass("load");
          core.draw.window({
            id: "msg_userEditor",
            body: r.tpls.window,
            callback: {
              beforeOpen: function(data) {
                msg.settings.roles.modal = data.body;
                msg.settings.roles.modal.find("input").on({
                  change: msg.settings.roles.rightsDependencies.changeHandler
                });

                msg.settings.roles.modal
                  .find("input:checked")
                  .trigger("change");

                msg.settings.roles.hideAllDependentRights();
              },
              afterOpen: function() {},
              beforeClose: function() {
                msg.settings.roles.modal = {};
                $(window).off({
                  resize: msg.settings.resize,
                  change: msg.settings.roles.rightsDependencies.changeHandler
                });
              }
            }
          });
        }
      });
    },

    hideAllDependentRights: () => {
      const begininGroupName = "dashboard";
      const begininGroupRights =
        msg.settings.roles.rightsDependencies.all[begininGroupName];
      for (let i in begininGroupRights) {
        const right = msg.settings.roles.modal.find(`${i} input`);
        if (begininGroupRights[i] && right.prop("checked") === false) {
          const rightsGroup =
            msg.settings.roles.rightsDependencies.all[begininGroupRights[i]];
          for (let j in rightsGroup) {
            const div = msg.settings.roles.modal.find(j);
            div.addClass("hidden");
          }
        }
      }
    },

    openEditRolesModal: function(e, el, project_id, role_id) {
      if (el.hasClass("load")) return false;

      if (e) e.stopPropagation();
      var url = "/msg?sec=" + core.nav.url.sec;

      el.addClass("load");

      var data = {
        project_id: project_id,
        tpls: {
          settings_roles_edit: "window"
        }
      };

      if (role_id) {
        data.role_id = role_id;
      }

      $.ajax({
        url: url,
        data: data,
        dataType: "JSON",
        type: "GET",
        success: function(r) {
          el.removeClass("load");
          core.draw.window({
            id: "msg_userEditor",
            body: r.tpls.window,
            callback: {
              beforeOpen: function(data) {
                msg.settings.roles.modal = data.body;
              },
              afterOpen: function() {},
              beforeClose: function() {
                msg.settings.roles.modal = {};
              }
            }
          });
        }
      });
    },

    role_delete: function(el, role_id) {
      if (el.hasClass("load")) return false;

      el.addClass("load");

      $.ajax({
        url: "/msg?sec=settings_role_delete",
        data: {
          project_id: core.nav.url.params.project_id,
          role_id: role_id
        },
        type: "GET",
        dataType: "JSON",
        success: function(r) {
          if (r.data.success) {
            $(".modal-container").modal("close");
            core.nav.do({
              el: undefined,
              url:
                "/msg?sec=settings&project_id=" +
                core.nav.url.params.project_id +
                "&section=roles"
            });
            core.informer.show(msg.lng.settings.changeSuccess, 1500);
            core.informer.el.css({
              left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
            });
          } else {
            var errorText = msg.lng.settings.groupDeleteError;
            if (
              typeof r.errors.informer === "string" &&
              r.errors.informer.length
            ) {
              errorText = r.errors.informer;
            }
            core.informer.show(errorText, 1500);
            core.informer.el.css({
              left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
            });
          }
          el.removeClass("load");
        },
        error: function() {
          core.informer.show(msg.lng.settings.groupDeleteError, 1500);
          core.informer.el.css({
            left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
          });
        }
      });
    },

    saveRoles: (form) => {
      $.ajax({
        url: "/msg?sec=save_role",
        data: form.serializeArray(),
        dataType: "JSON",
        type: "POST",
        success: (r) => {
          if (core.form.check(form, "roleEdit_form", r.errors)) {
            core.informer.show(msg.lng.settings.changeSuccess, 1500);
            $(".modal-container").modal("close");

            var new_url =
              "/msg?sec=settings&project_id=" +
              core.nav.url.params.project_id +
              "&section=roles&role_id=" +
              r.data.role_id;

            $.ajax({
              url: new_url,
              data: {
                tpls: {
                  settings_roles_rights: "settings_roles_rights",
                  settings_roles_list: "settings_roles_list"
                }
              },
              type: "GET",
              dataType: "JSON",
              success: (d) => {
                // FIXME:
                history.pushState(null, null, new_url);

                const settings_roles_rights = $(".roles_permission_list");
                const settings_roles_list = $(".roles_listWrap");

                settings_roles_rights.html(d.tpls.settings_roles_rights);
                settings_roles_list
                  .find(".wsScroll_content")
                  .html(d.tpls.settings_roles_list);

                settings_roles_list.find(".wsScroll").nanoScroller({
                  flash: !0,
                  alwaysVisible: true
                });
              }
            });
          } else {
            core.informer.show(msg.lng.settings.changeError, 1500);
            core.informer.el.css({
              left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
            });
          }
        }
      });
    },

    purgeHiddenRights: function(form) {
      var rights = form.find("div[id^='field']");
      if (!rights.length) return;
      rights.each(function(i, right) {
        if ($(right).hasClass("hidden")) {
          $(right)
            .find("input")
            .prop("checked", false);
        }
      });
    },

    saveRights: function(form) {
      msg.settings.roles.purgeHiddenRights(form);
      $.ajax({
        url: "/msg?sec=save_role_rights",
        data: form.serializeArray(),
        dataType: "JSON",
        type: "POST",
        success: function(r) {
          if (r.data.success) {
            core.informer.show(msg.lng.settings.changeSuccess, 1500);

            $(".modal-container").modal("close");

            core.nav.do({
              el: undefined,
              url:
                "/msg?sec=settings&project_id=" +
                core.nav.url.params.project_id +
                "&section=roles&role_id=" +
                r.data.role_id
            });
          } else {
            core.informer.show(msg.lng.settings.changeError, 1500);
            core.informer.el.css({
              left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
            });
          }
        }
      });
    },

    /**
     * Группа методов и правил зафисимостей для логики скрытия/отображения зависимых прав
     */
    rightsDependencies: {
      all: {
        dashboard: {
          "#group_dashboard_appeals": 0,
          "#field_appeal_close": 0,
          "#field_appeal_transfer": 0,
          "#field_appeal_reopen": 0,
          "#group_dashboard_excel": 0,
          "#field_excel": 0,
          "#field_report_appeals": 0,
          "#group_dashboard_agent_limit": 0,
          "#group_dashboard_skill_limit": 0,
          "#field_monitoring": "monitoring"
        },
        monitoring: {
          "#field_skill_limit": 0,
          "#field_agent_limit": 0,
          "#field_user_groups": 0
        }
      },
      /**
       * список зависимостей для контролов
       */
      rules: {
        /**
         * целевой контролл
         * контрол в котором изменяется значение
         * проверяем на что изменилось его значение
         * работа проверена только на radio
         */
        dashboard: {
          /**
           * значение контрола: [зависимые контролы]
           * список контролов которые видимы если целевой контрол имеет указанное в ключе значение
           */
          system_dashboard: [
            "#group_dashboard_appeals",
            "#field_appeal_close",
            "#field_appeal_transfer",
            "#field_appeal_reopen",
            "#group_dashboard_excel",
            "#field_excel",
            "#field_report_appeals",
            "#group_dashboard_agent_limit",
            "#group_dashboard_skill_limit",
            "#field_monitoring"
          ],
          person_dashboard: []
        },

        monitoring: {
          monitoring: [
            "#field_skill_limit",
            "#field_agent_limit",
            "#field_user_groups"
          ]
        }
      },

      changeHandler: function(e) {
        e = e || window.event;
        let target = $(e.target);

        const controlName = target.attr("name");
        const subControlName = target.val();
        const isControlActive = target.prop("checked");
        var currentRightRules =
          msg.settings.roles.rightsDependencies.rules[controlName];
        if (currentRightRules) {
          const visibledRights = isControlActive
            ? currentRightRules[subControlName] || []
            : [];
          const allRights =
            msg.settings.roles.rightsDependencies.all[controlName];
          msg.settings.roles.rightsDependencies.changeVisible(
            allRights,
            visibledRights
          );
        }
      },

      changeVisible: (allRights, visibleControls) => {
        if (!visibleControls) {
          return;
        }

        for (let key in allRights) {
          const shouldBeVisible = visibleControls.indexOf(key) !== -1;
          const div = msg.settings.roles.modal.find(key);
          const isVisible = !div.hasClass("hidden");
          const currentInput = div.find("input");

          if (shouldBeVisible && !isVisible) {
            div.removeClass("hidden");
          } else if (!shouldBeVisible && isVisible) {
            div.addClass("hidden");

            if (allRights[key] && currentInput.prop("checked")) {
              $(currentInput).trigger("click");
            }
          }
        }
      }
    }
  },

  groups: {
    userEditor_modal: {},
    user_profile: false,
    users: [],
    skill_modal: {},
    selected_skill_id: !1,
    users_marked: false,
    dropdown_show_el: undefined,
    dropdown_showed: false,
    user_search_delay: undefined,
    users_settings: usersSettings,
    restore_password: restorePassword,
    user_add: userAdd,
    group_go: groupGo,
    groups_count: groupsCount,
    groups_show: groupsShow,
    skill_add: skillAdd,

    dropdown_show: dropDownShow,
    dropdown_hide: dropDownHide,
    user_move: userMove,
    user_move_show_groups: userMoveShowGroups,
    user_search: userSearch,
    user_check: userCheck,
    user_check_all: userCheckAll,
    user_click: userClick,
    users_show: userShow,
    users_delete: usersDelete,
    skill_delete: skillDelete,
    get_selected_users: () => {
      const checkedElements = document.querySelectorAll(
        ".groups_users_list input:checked"
      );
      return [].map.call(checkedElements, (e) => {
        return +e.value;
      });
    }
  },

  vip_clients_init: function(el) {
    window.location.replace(el[0].href);
  },

  system_settings_init: function(el) {
    window.location.replace(el[0].href);
  },

  autoreplies_init: function(el, project_id) {
    if (el.hasClass("load")) return false;

    el.addClass("load");
    $.ajax({
      url: "/msg?sec=settings",
      data: {
        project_id: project_id,
        tpls: {
          settings_autoreplies: "window"
        }
      },
      dataType: "JSON",
      type: "GET",
      success: function(r) {
        core.draw.window({
          id: "settings_autoreplies",
          body: r.tpls.window
        });
        el.removeClass("load");
      }
    });
  },

  autoreplies_submit: function(form) {
    $.ajax({
      url: "/msg?sec=save_autoreplies",
      data: form.serializeArray(),
      dataType: "JSON",
      type: "GET",
      success: function(r) {
        if (core.form.check(form, "autorepliesEditor", r.errors)) {
          $("#msg_autorepliesEditor").modal("close");
          core.informer.show(msg.lng.settings.changeSuccess, 1500);
        } else {
          var input = $("#autorepliesEditor_form")
            .find(".imp")
            .find("input, textarea");
          $.each(input, function(i, item) {
            if ($(item).val() == 0) {
              $(item)
                .closest(".wsForm_field")
                .addClass("fail");
            }
          });

          core.informer.show(msg.lng.settings.changeError, 1500);
          core.informer.el.css({
            left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
          });
        }
      }
    });
  },

  tst_init: function(project_id) {
    $.ajax({
      url: "/msg?sec=settings",
      data: {
        project_id: project_id,
        tpls: {
          settings_tst: "window"
        }
      },
      dataType: "JSON",
      type: "GET",
      success: function(r) {
        core.draw.window({
          id: "settings_tst",
          body: r.tpls.window
        });
      }
    });
  },

  tst_submit: function(form) {
    $.ajax({
      url: "/msg?sec=save_autoreplies",
      data: form.serializeArray(),
      dataType: "JSON",
      type: "GET",
      success: function(r) {
        if (core.form.check(form, "tstEditor", r.errors)) {
          $("#msg_tstEditor").modal("close");
          core.informer.show(msg.lng.settings.changeSuccess, 1500);
        } else {
          core.informer.show(msg.lng.settings.changeError, 1500);
          core.informer.el.css({
            left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
          });
        }
      }
    });
  },

  score: function(el) {
    var elem = el.closest(".wsForm_field");
    if (el.val() == 0) elem.find(".score").html("Время не установлено");
    else elem.find(".score").html(el.val() + " мин");
  },

  score_queue: function(el) {
    var elem = el.closest(".wsForm_field");
    var buf = el.val().slice(-1);

    if (el.val() == 0) elem.find(".score").html(msg.lng.chat.noLimitations);
    else elem.find(".score").html(el.val() + " Обращений");
  },

  channels: {
    modal: undefined,

    channel_id: 1,

    editor: function(project_id, channel_id) {
      $.ajax({
        url: "/msg?sec=settings",
        data: {
          channel_id: channel_id || 0,
          tpls: {
            settings_channels_editor: "window"
          }
        },
        dataType: "JSON",
        type: "POST",
        success: function(r) {
          core.draw.window({
            id: "channelEditor",
            body: r.tpls.window,
            callback: {
              beforeOpen: function(data) {
                msg.settings.channels.modal = data.body;
              },
              beforeClose: function() {
                msg.settings.channels.modal = null;
              }
            }
          });
        }
      });
    },

    prepareData: function(form) {
      var formFieldArray = form.serializeArray();

      function takeSimpleSettings(data) {
        var temp_arr = [];

        data.forEach(function(item, i, arr) {
          if (!/\$/.test(item.name)) {
            temp_arr.push(item);
          }
        });

        return temp_arr;
      }

      function takeWidgetSettings(data) {
        var temp_obj = {};

        data.forEach(function(item) {
          if (/\$/.test(item.name)) {
            var element = $("[name='" + item.name + "']"),
              value = item.value;
            if (value || element.parents(".wsForm_field").find(".imp").length) {
              temp_obj[item.name] =
                element.attr("type") === "text" ? value.trim() : value;
            }
          }
        });

        return temp_obj;
      }

      function takeEmptyCheckboxSettings(form) {
        var temp_obj = {},
          checkboxNotChecked = form.find("[type=checkbox]:not(:checked)");

        checkboxNotChecked.each(function(item, i, arr) {
          temp_obj[i.name] = "0";
        });

        return temp_obj;
      }

      var mainData = takeSimpleSettings(formFieldArray);
      var widgetData = takeWidgetSettings(formFieldArray);
      var emptyCheckboxData = takeEmptyCheckboxSettings(form);

      var finalWidgetSettings = $.extend({}, widgetData, emptyCheckboxData);

      if (Object.keys(finalWidgetSettings).length) {
        mainData.push({
          name: "data",
          value: JSON.stringify(finalWidgetSettings)
        });
      }

      return mainData;
    },

    imgToBase64: function(el) {
      if (!el.files.length) return;
      var reader = new FileReader();
      reader.readAsDataURL(el.files[0]);
      reader.onloadend = function() {
        const base64data = reader.result;
        $(el)
          .closest(".wsForm_field")
          .find("[type=hidden][name*='imgLabel']")
          .val(el.files[0].name)
          .end()
          .find("[type=hidden][name*='imgBase64']")
          .val(base64data);
      };
    },

    channel_delete: function(el, channel_id) {
      if (!channel_id || el.hasClass("load")) {
        return false;
      }
      el.addClass("load");

      $.ajax({
        url: "/msg?sec=delete_channel",
        data: {
          project_id: core.nav.url.params.project_id,
          channel_id: channel_id,
          channel_type_id: core.nav.url.params.channel_type_id
        },
        dataType: "JSON",
        type: "GET",
        success: function(r) {
          console.log(r);
          if (
            typeof r["errors"] === "object" &&
            typeof r["errors"]["informer"] === "string"
          ) {
            $("#msg_confirm_action").modal("close");
            core.informer.show(r["errors"]["informer"], 1500);
            core.informer.el.css({
              left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
            });
            return;
          }

          var url =
            "/msg?sec=settings&project_id=" +
            core.nav.url.params.project_id +
            "&section=channels" +
            (core.nav.url.params.channel_type_id
              ? "&channel_type_id=" + core.nav.url.params.channel_type_id
              : "");
          core.nav.do({
            el: undefined,
            url: url
          });
          core.informer.show(msg.lng.settings.channelDeletedSuccess, 1500);
          core.informer.el.css({
            left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
          });
        }
      });
    },

    scriptUpdate: function(el, project_id) {
      msg.settings.channels.channel_id = el.val();

      if (el.val() != 1) {
        $(".settings_channel_edit").removeClass("disable");
      } else {
        $(".settings_channel_edit").addClass("disable");
      }

      $(".settings_channel_script").addClass("load");
      core.nav.do({
        url:
          "/msg?sec=settings&project_id=" +
          project_id +
          "&section=channels&channel_id=" +
          el.val()
      });
      $(".settings_channel_script").removeClass("load");
    }
  },

  multiChoice: {
    focus: function(event, el) {
      event.stopPropagation();
      var showed_blocks = msg.settings.groups.skill_modal.tags_list.filter(
        ".show"
      );

      if (showed_blocks[0] && el.parent()[0] != showed_blocks.parent()[0]) {
        if (
          !showed_blocks
            .siblings(".wsForm_multiChoiceInput")
            .children(".wsForm_tagWrap").length
        )
          showed_blocks
            .siblings(".wsForm_multiChoiceInput")
            .prepend(
              '<div class="wsForm_multiChoiceInput_placeholder">Не указаны</div>'
            );

        this.clear_search(
          showed_blocks
            .siblings(".wsForm_multiChoiceInput")
            .find(".multiChoiceInput_textarea")
        );
      }

      msg.settings.groups.skill_modal.tags_box.removeClass("focus");
      msg.settings.groups.skill_modal.tags_list.removeClass("show");

      el.addClass("focus");

      el.find(".multiChoiceInput_textarea").focus();

      var list = el.parent().find(msg.settings.groups.skill_modal.tags_list);

      el.parent()
        .find(".wsForm_multiChoiceInput_placeholder")
        .remove();

      document.addEventListener("click", msg.settings.multiChoice.blur);

      if (list.find(".wsScroll_content").children().length) {
        list.addClass("show");
        list.find(".wsScroll").nanoScroller();
      }
    },

    blur: () => {
      // FIXME:
      let tags_box = $(".wsForm_multiChoiceInput");
      let tags_list = $(".wsForm_multiChoice_list");
      let placeholder = `<div class="wsForm_multiChoiceInput_placeholder">Не указаны</div>`;

      tags_list.removeClass("show");
      tags_box.removeClass("focus");
      document.removeEventListener("click", msg.settings.multiChoice.blur);

      tags_box.each(function(key, obj) {
        if (
          $(obj).find(".wsForm_tagWrap") &&
          !$(obj).find(".wsForm_tagWrap").length
        ) {
          $(obj)
            .removeClass("full")
            .html(
              placeholder +
                $(obj)
                  .removeClass("full")
                  .html()
            );
          $(obj).blur();
        }
      });
    },

    select: function(event, el, key) {
      event.stopPropagation();

      let multiChoiceParent = el.parents(".wsForm_multiChoice");

      if (multiChoiceParent.attr("data-multiChoice-blocked-keys") == key) {
        return;
      }

      let tag_box = msg.settings.groups.skill_modal.find(".focus");
      let placeholder = tag_box.find(".wsForm_multiChoiceInput_placeholder");

      this.clear_search(
        msg.settings.groups.skill_modal.tags_box.find(
          ".multiChoiceInput_textarea"
        )
      );

      let mark = multiChoiceParent.attr("data-multiChoice-selector");
      let name = el.find(".wsForm_multiChoice_item").text();

      let buffer = `<div class="wsForm_tagWrap
        key_${key}" contenteditable="false" 
        onclick="msg.settings.multiChoice.remove(event, $(this), ${key});">
          <div class="wsForm_tag">${name}</div>
        </div>`;

      let input = `<input type="hidden" name="${mark}[]" value="${key}"/>`;

      if (el.siblings(":not(.hidden)").length < 6)
        el.parents(".wsForm_multiChoice_list").addClass("height_stretch_block");

      el.parents(".wsForm_field")
        .prepend(input)
        .end()
        .remove();

      if (placeholder) {
        placeholder.remove();
      }

      tag_box.addClass("full");
      tag_box.find(".multiChoiceInput_textarea_wrap").before(buffer);
      msg.settings.groups.skill_modal.tags_box.removeClass("focus");
      msg.settings.groups.skill_modal.tags_list.removeClass("show");
    },

    remove: function(event, el, key) {
      event.stopPropagation();

      var tags_box = el.parent(),
        list = tags_box.next(".wsForm_multiChoice_list"),
        mark = el
          .parents(".wsForm_multiChoice")
          .attr("data-multiChoice-selector"),
        buffer =
          '<div class="wsForm_multiChoice_itemWrap channel key_' +
          key +
          '" \
                onclick="msg.settings.multiChoice.select(event, $(this), ' +
          key +
          ');">\
                                        <div class="wsForm_multiChoice_item">\
                                            ' +
          el.find(".wsForm_tag").text() +
          "\
                                        </div>\
                                    </div>";

      el.parents(".wsForm_field")
        .find('input[value="' + key + '"]')
        .remove();
      el.parents(".wsForm_field")
        .find(".wsScroll_content")
        .append(buffer);

      if (
        $(
          '[data-multiChoice-selector="' + mark + '"] .wsScroll_content'
        ).children(":not(.hidden)").length >= 6
      )
        el.parents(".wsForm_multiChoice")
          .find(".wsForm_multiChoice_list")
          .removeClass("height_stretch_block");

      el.parents(".wsForm_multiChoice")
        .find(".wsScroll")
        .nanoScroller();
      el.remove();

      if (!tags_box.find(".wsForm_tagWrap").length) {
        this.blur();
      }

      this.clear_search(
        tags_box
          .parents(".wsForm_multiChoice")
          .find(".multiChoiceInput_textarea")
      );
    },

    search_item: function(el) {
      var str_item = "",
        str_search = "",
        count_search = 0,
        parent = el.parents(".wsForm_multiChoice");

      parent.find(".wsForm_multiChoice_itemWrap.hover").removeClass("hover");

      $.each(parent.find(".wsForm_multiChoice_itemWrap"), function(key, obj) {
        str_item = $(obj)
          .find(".wsForm_multiChoice_item")
          .text()
          .toLowerCase();
        str_search = el
          .val()
          .trim()
          .toLowerCase();

        if (str_search && !~str_item.indexOf(str_search)) {
          $(obj).addClass("hidden");
        } else {
          count_search++;
          $(obj).removeClass("hidden");
        }
      });

      if (!count_search) {
        if (!parent.find(".empty_search")[0])
          parent
            .find(".wsScroll_content")
            .prepend(
              '<div class="empty_search">По запросу ничего не найдено</div>'
            );
      } else parent.find(".empty_search").remove();

      if (
        parent.find(".wsScroll_content").children(":not(.hidden)").length >= 6
      )
        parent
          .find(".wsForm_multiChoice_list")
          .removeClass("height_stretch_block");
      else
        parent
          .find(".wsForm_multiChoice_list")
          .addClass("height_stretch_block");

      parent
        .find(".wsForm_multiChoice_itemWrap")
        .removeClass("last")
        .filter(":not(.hidden)")
        .last()
        .addClass("last");

      parent.find(".wsScroll").nanoScroller();
    },

    clear_search: function(el) {
      el.val("");
      this.search_item(el);
    },

    botsRelationChange: function(el) {
      var studentsBotsField = $("[data-multiChoice-selector=students_bots]");
      studentsBotsField
        .attr("data-multiChoice-blocked-keys", el.val())
        .find(".wsForm_tagWrap.key_" + el.val())
        .trigger("click");
    }
  },

  bots: {
    bot_delete: function(el, bot_id, user_id) {
      if (el.hasClass("load") || !bot_id || !user_id) {
        return false;
      }
      el.addClass("load");

      $.ajax({
        url: "/msg?sec=bot_user_delete",
        data: {
          project_id: core.nav.url.params.project_id,
          bot_id: bot_id,
          user_id: user_id
        },
        dataType: "JSON",
        type: "POST",
        success: function(r) {
          if (r.data.success === 1) {
            var url =
              "/msg?sec=settings&project_id=" +
              core.nav.url.params.project_id +
              "&section=bots";

            core.nav.do({
              el: undefined,
              url: url
            });
            core.informer.show(msg.lng.settings.changeSuccess, 1500);
            core.informer.el.css({
              left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
            });
            return;
          }
          core.informer.show(msg.lng.settings.busyBotMsg, 1500);
          core.informer.el.css({
            left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
          });
          el.removeClass("load");
        }
      });
    },

    save: function(form) {
      if (form.hasClass("load")) {
        return false;
      }
      form.addClass("load");
      var data = new FormData(form[0]);
      $.ajax({
        url: "/msg?sec=bot_user_edit_save",
        data: data,
        dataType: "JSON",
        type: "POST",
        processData: false,
        async: false,
        cache: false,
        contentType: false,
        success: function(r) {
          form.removeClass("load");
          if (core.form.check(form, "botEditor", r.errors)) {
            $("#msg_settingsBotsList").modal("close");

            var url =
              "/msg?sec=settings&project_id=" +
              core.nav.url.params.project_id +
              "&section=bots";

            core.nav.do({
              el: undefined,
              url: url
            });
            core.informer.show(msg.lng.settings.changeSuccess, 1500);
            core.informer.el.css({
              left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
            });
            return;
          }
          core.informer.show(msg.lng.settings.changeError, 1500);
          core.informer.el.css({
            left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
          });
        },
        error: (r) => {
          form.removeClass("load");
          if (r.status === 413) {
            core.form.check(form, "botEditor", {
              photo: "Размер файла превышает установленный лимит"
            });
            core.informer.show(msg.lng.settings.changeError, 1500);
            return;
          }
          core.informer.show(msg.lng.common.defaultRequest, 1500);
          core.informer.el.css({
            left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
          });
        }
      });
    },

    showModal: function(el, project_id, bot_id) {
      if (el.hasClass("load")) {
        return false;
      }
      el.addClass("load");

      $.ajax({
        url: "/msg?sec=settings",
        data: {
          project_id: project_id,
          bot_id: bot_id,
          tpls: {
            settings_bots_users_edit: "window"
          }
        },
        dataType: "JSON",
        type: "GET",
        success: function(r) {
          core.draw.window({
            id: "msg_botEditor",
            body: r.tpls.window
          });
          el.removeClass("load");
        }
      });
    }
  }
};

$(document).ready(() => {
  $(".body")
    .parent()
    .css("overflow", "visible");
});

$(window).on({
  scroll: function() {
    var scroll = $(window).scrollTop();
    var menuHeght = $(".menu").outerHeight();
    var menu = $(".settings_menu");

    if (scroll > menuHeght) {
      menu.addClass("fixed");
      $(".editorWrap").addClass("fixed");
    } else if (scroll < menuHeght) {
      menu.removeClass("fixed");
      $(".editorWrap").removeClass("fixed");
    }
  }
});
