import produce from "immer";
import initialState from "../../ReactCore/store/initialState";
import {
  SET_PHOTO,
  USERS_PROFILE_CREATED,
  USERS_PROFILE_DATA_UPDATED,
  GET_USERS_PROFILE_CATALOGS,
  GET_USERS_PROFILE_DATA,
  REMOVE_USERS_PROFILE,
  RESET_USERS_PROFILE_DATA,
  SEND_USERS_PROFILE_DATA,
  SET_AREAS_LIST,
  SET_ORGANIZATIONS_LIST,
  SET_SKILL_GROUPS_LIST,
  SET_TERRITORIES_LIST,
  SET_ROLES_LIST,
  SET_USERS_EMAIL_ERROR,
  SET_USERS_PROFILE_DATA,
  SET_USERS_OWN_PROFILE_DATA,
  SHOW_USERS_PROFILE_DATA_ERROR,
  SET_USERS_PROFILE_RESPONSE_ERROR,
} from "./consts";

export default function usersProfileModalReducer(
  state = initialState.UsersProfile,
  { type, payload }
) {
  switch (type) {
    case SET_PHOTO:
      return produce(state, (draft) => {
        draft.photo = payload.data
          ? `/fileStorage/files/crop/${payload.data}`
          : undefined;
      });
    case SET_AREAS_LIST:
      return produce(state, (draft) => {
        draft.status.areasLoaded = true;
        draft.areas = payload.data;
      });
    case SET_ORGANIZATIONS_LIST:
      return produce(state, (draft) => {
        draft.status.organizationsLoaded = true;
        draft.organizations = payload.data;
      });
    case SET_SKILL_GROUPS_LIST:
      return produce(state, (draft) => {
        draft.status.skillGroupsLoaded = true;
        draft.skillGroups = payload.data;
      });
    case SET_TERRITORIES_LIST:
      return produce(state, (draft) => {
        draft.status.territoriesLoaded = true;
        draft.territories = payload.data;
      });
    case SET_ROLES_LIST:
      return produce(state, (draft) => {
        draft.status.rolesLoaded = true;
        draft.roles = payload.data;
      });

    case USERS_PROFILE_DATA_UPDATED:
      return produce(state, (draft) => {
        draft.formData = payload.data;
      });
    case SHOW_USERS_PROFILE_DATA_ERROR:
      return produce(state, (draft) => {
        draft.formData = { ...draft.formData, ...payload.data };
      });
    case SET_USERS_EMAIL_ERROR:
      return produce(state, (draft) => {
        draft.formData = {
          ...draft.formData,
          email: {
            ...draft.formData.email,
            error: true,
            actionText: payload.data,
          },
        };
      });
    case REMOVE_USERS_PROFILE:
      return produce(state, (draft) => {
        // draft.formData.status.value = 0;
      });
    case SEND_USERS_PROFILE_DATA:
      return produce(state, (draft) => {
        draft.status.usersProfileHandling = true;
      });
    case SET_USERS_PROFILE_RESPONSE_ERROR:
      return produce(state, (draft) => {
        draft.status.usersProfileHandling = false;
        draft.errorMessage = payload.data;
      });
    case USERS_PROFILE_CREATED:
      return produce(state, (draft) => {
        draft.status.usersProfileHandling = false;
      });
    case GET_USERS_PROFILE_DATA:
      return produce(state, (draft) => {
        draft.status.usersProfileDataLoading = false;
      });
    case RESET_USERS_PROFILE_DATA:
      return produce(state, (draft) => {
        draft.usersProfileData = null;
        draft.photo = null;
        draft.status = initialState.UsersProfile.status;
        draft.errorMessage = initialState.UsersProfile.errorMessage;
        draft.formData = initialState.UsersProfile.formData;
      });
    case SET_USERS_PROFILE_DATA:
      return produce(state, (draft) => {
        draft.usersProfileData = payload.data;
        draft.status.usersProfileDataLoading = false;
      });
    case SET_USERS_OWN_PROFILE_DATA:
      return produce(state, (draft) => {
        draft.userOwnData = payload.data;
      });
    default:
      return state;
  }
}
