import {
  SET_INCIDENT_APPEAL_ID,
  SEND_INCIDENT_TO_SKUF,
  SEND_GET_SKUF_CATALOGS,
  SKUF_INCIDENT_CREATED,
  SKUF_INCIDENT_FAILED,
  SKUF_INCIDENT_DATA_UPDATED,
  LOADED_SKUF_LEVELS,
  LOADED_SKUF_REGIONS,
  SHOW_INCIDENT_DATA_ERRORS,
  GET_INCIDENT_SKUF_STATUS,
  SET_INCIDENT_SKUF_ID,
  SKUF_INCIDENT_SEND_ERROR,
  RESET_INCIDENT_DATA
} from "./consts";

export const setIncidentAppealId = data => ({
  type: SET_INCIDENT_APPEAL_ID,
  payload: { data }
});

export const skufIncidentDataUpdated = data => ({
  type: SKUF_INCIDENT_DATA_UPDATED,
  payload: { data }
});

export const showIncidentDataErrors = data => ({
  type: SHOW_INCIDENT_DATA_ERRORS,
  payload: { data }
});

export const resetIncidentData = () => ({
  type: RESET_INCIDENT_DATA
});

export const sendIncidentToSkuf = data => ({
  type: SEND_INCIDENT_TO_SKUF,
  payload: { data }
});
export const setSkufIncidentCreated = () => ({
  type: SKUF_INCIDENT_CREATED
});
export const setSkufIncidentFailed = () => ({
  type: SKUF_INCIDENT_FAILED
});
export const setSkufIncidentSendError = () => ({
  type: SKUF_INCIDENT_SEND_ERROR
});
export const setSkufIncidentId = data => ({
  type: SET_INCIDENT_SKUF_ID,
  payload: { data }
});
export const getSkufIncidentStatus = () => ({
  type: GET_INCIDENT_SKUF_STATUS
});

export const getSkufLevels = () => ({ type: SEND_GET_SKUF_CATALOGS });
export const loadedSkufLevels = data => ({
  type: LOADED_SKUF_LEVELS,
  payload: { data }
});
export const loadedSkufRegions = data => ({
  type: LOADED_SKUF_REGIONS,
  payload: { data }
});
