import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../ReactCore/shared/httpRequest";
import { FETCH_SKILLGROUP_CAPACITY } from "./consts";
import { setSkillGroupCapacityDataToForm } from "./skillGroupCapacityModal.actions";

export function* getSkillGroupCapacitySaga(action) {
  try {
    const res = yield httpRequest(
      "GET",
      `bff/settings/skill-groups/${action.payload.skillId}/queue`
    );
    if (res?.success) {
      yield put(
        setSkillGroupCapacityDataToForm({
          skillId: action.payload.skillId,
          queue: res.data?.queue,
          queueLimit: res.data?.queueLimit || 0,
          appealLimit: res.data?.appealLimit || null
        })
      );
    }
  } catch ($error) {
    console.error($error);
  }
}

export default function* () {
  yield takeEvery(FETCH_SKILLGROUP_CAPACITY, getSkillGroupCapacitySaga);
}
