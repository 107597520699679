import { takeEvery, put, select } from "redux-saga/effects";
import { SEND_INCIDENT_TO_SKUF } from "./consts";

import {
  showIncidentDataErrors,
  setSkufIncidentCreated,
  setSkufIncidentFailed,
  setSkufIncidentId,
  setSkufIncidentSendError,
  getSkufIncidentStatus
} from "./incidentSkuf.actions";
import { httpRequest } from "../../ReactCore/shared/httpRequest";
import { IncidentPreparedData, IncidentData } from "./incidentSkuf";

import Informer from "../../arm/Wrappers/Informer";
import { ISelect } from "../Common/Interfaces/Store.interfaces";

function* sendIncidentToSkuf(action) {
  const appealId = yield select<ISelect>(
    ({ IncidentSkuf }) => IncidentSkuf.appealId
  );
  const data = yield select<ISelect>(
    ({ IncidentSkuf }) => IncidentSkuf.incidentData
  );
  const closeModal = action.payload.data;

  const validateIncidentData = ((data: IncidentData) => {
    const result = Object.keys(data).reduce((acc, key) => {
      if (data[key].required && !data[key].value) {
        acc[key] = { ...data[key], error: true };
      }
      return acc;
    }, {});

    if (result) return result;
  })(data);

  if (Object.keys(validateIncidentData).length) {
    yield put(showIncidentDataErrors(validateIncidentData));
    return;
  }

  const preparedIncidentData = ((
    appealId,
    data: IncidentData
  ): IncidentPreparedData => ({
    appealId: appealId,
    eventType: Number(data.eventType.value),
    zoneCode: Number(data.zoneCode.value),
    firstServiceId: Number(data.firstServiceId.value),
    secondServiceId: data.secondServiceId.value
      ? Number(data.secondServiceId.value)
      : null,
    thirdServiceId: data.thirdServiceId.value
      ? Number(data.thirdServiceId.value)
      : null,
    description: data.description.value
  }))(appealId, data);

  try {
    const res = yield httpRequest(
      "POST",
      `bff/chat/appeals/${preparedIncidentData.appealId}/send-incident-to-skuf`,
      {
        body: preparedIncidentData
      }
    );

    if (res?.success) {
      yield put(setSkufIncidentCreated());
      yield put(setSkufIncidentId(res.data?.incId));

      const info = new Informer(`Инцидент № ${res.data?.incId} создан`, 4500);
      info.show();

      closeModal();
      return;
    }

    if (res?.success) {
      yield put(setSkufIncidentSendError());
      yield put(getSkufIncidentStatus());

      const info = new Informer("Инцидент не был создан", 4500);
      info.show();

      return;
    }
  } catch ($error) {
    console.log($error);
  }
}

export default function* () {
  yield takeEvery(SEND_INCIDENT_TO_SKUF, sendIncidentToSkuf);
}
