import { eventChannel, END } from "redux-saga";
import {
  call,
  take,
  fork,
  cancelled,
  putResolve,
  race
} from "redux-saga/effects";

import { WS_SEND } from "./Consts";
import * as WebsocketActions from "./Websocket.actions";

interface IWsOptions {
  userId: number;
  userHash: string;
  url: string;
}

const regexWsSend = new RegExp(`^${WS_SEND}`);

// Используется для выбрасывания исключений, позволяет проще дебажить.
const dispatch = putResolve;

let instance = undefined;

export const websocket = function() {
  function createInstance(options: IWsOptions, handle: () => {}) {
    if ((instance && instance.options) || !options) {
      console.log("Create WebsocketSingleton failed");
      return;
    }
    instance = { options, handle, open };
    return instance;
  }

  function createWebSocketConnection() {
    return new Promise((resolve, reject) => {
      const socket = new WebSocket(instance.options.url);
      socket.onopen = () => resolve(socket);
      socket.onerror = evt => reject(evt);
    });
  }

  function createSocketChannel() {
    return eventChannel(emit => {
      instance.socket.onmessage = event => emit(event.data);
      instance.socket.onclose = () => emit(END);
      const unsubscribe = () => {
        instance.socket.onmessage = null;
      };
      return unsubscribe;
    });
  }

  function* sendMessage() {
    while (true) {
      const { type, payload } = yield take(action =>
        regexWsSend.test(action.type)
      );
      instance.socket.send(JSON.stringify(payload));
    }
  }

  function sendMessageRead(guid) {
    instance.socket.send(
      JSON.stringify({
        callback: "message_read",
        guid: guid
      })
    );
  }

  function* receivedMessage() {
    while (true) {
      const data = yield take(instance.socketChannel);
      const wsEvent = JSON.parse(data);
      instance.handle(wsEvent, () => {
        sendMessageRead(wsEvent.guid);
      });
    }
  }

  function* sendHelloMessage() {
    const mess = {
      callback: "connect",
      user_id: instance.options.userId,
      user_hash: instance.options.userHash,
      project_id: 1
    };
    instance.socket.send(JSON.stringify(mess));
  }

  function* listenForSocketMessages() {
    try {
      // Запись в экземпляр созданных канала и соединения
      // для последующих обращений к ним извне
      instance.socket = yield call(createWebSocketConnection);
      instance.socketChannel = yield call(createSocketChannel);

      // Отправляем на сервер первый пакет "connect"
      yield sendHelloMessage();

      // Говорим приложению, что появилось WebSocket соединение
      yield dispatch(WebsocketActions.connectionSuccess({}));
      console.log("Websocket: Connected");

      // Запуск гонки эффектов:
      //  * прослушивание входящих ws-пакетов
      //  * внутренних событий отправки пакетов
      while (true) {
        yield race([call(receivedMessage), call(sendMessage)]);
      }
    } catch (error) {
      console.log("WebSocket: Error while connecting to the WebSocket");
      yield dispatch(
        WebsocketActions.connectionError({
          text: "Error while connecting to the WebSocket",
          desc: error
        })
      );
    } finally {
      if (yield cancelled()) {
        console.log("Websocket: Disconnecting...");
        yield close();
      } else {
        yield dispatch(
          WebsocketActions.connectionError("WebSocket disconnected incorrectly")
        );
      }
    }
  }

  function* open() {
    instance.secretId = yield fork(listenForSocketMessages);
  }

  function* close() {
    // Закрываем канал
    yield instance.socketChannel.close();
    // Закрываем WebSocket соединение
    yield instance.socket.close();
    console.log("Websocket: Connection is closed");
    instance = undefined;
  }

  return {
    getInstance: (options?, handle?) =>
      instance || (instance = createInstance(options, handle))
  };
};
