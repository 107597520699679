import { createSelector } from "reselect";

import { isEmpty } from "@omnichat/arm_ui_kit";

export const getAllCheckboxValue = state => state.RepliesList.all;

export const getQuery = state => state.RepliesList.query;

export const getSelected = state => state.RepliesList.selected;

export const getPreparedReplies = state => {
  const selected = getSelected(state);

  return state.RepliesList.repliesList.map(item => ({
    id: item.id,
    text: item.text,
    title: item.title,
    checked: !!selected.find(id => item.id === id),
    category: item.category.name,
    attachmentsCount: item.attachmentsUrl.length
  }));
};
