import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { LOADED_RFS, FETCH_RFS } from "../Consts";

export function* getRfsSaga() {
  try {
    const response = yield httpRequest("GET", "bff/rfs");

    if (response) {
      yield put({
        type: LOADED_RFS,
        payload: response.data.regions.map(el => ({
          id: el.id,
          value: el.id,
          label: el.region
        }))
      });
    }
  } catch (e) {
    console.error("Saga: getRfsSaga error", e);
  }
}

export default function*() {
  yield takeEvery(FETCH_RFS, getRfsSaga);
}
