import { useCallback, useEffect, useState } from "react";
import { httpRequest } from "../../../../ReactCore";

export type AutoLogoutSetting = {
  id?: string;
  substatusId: string;
  isDefault: boolean;
  timeout: number;
};

type NewAutoLogoutSetting = {
  substatusId: string | null;
  isDefault: boolean | null;
  timeout: number | null;
};

export type SubStatus = { label: string; value: string; selectable: boolean };

export const OFFLINE_ID = "9";

export const useAutoLogoutSettings = () => {
  const [newSetting, setNewSetting] = useState<NewAutoLogoutSetting | null>();
  const [settingsList, setSettingsList] = useState<AutoLogoutSetting[]>([]);
  const [substatusesList, setSubstatusesList] = useState<SubStatus[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const substatuses = substatusesList.map((s) => ({
    ...s,
    selectable: !settingsList.find((l) => `${l.substatusId}` === `${s.value}`)
  }));

  useEffect(() => {
    setLoading(true);
    httpRequest("GET", `bff/user/offline-substatuses`)
      .then((res) => {
        if (res.success && res.data) {
          setSubstatusesList(
            res.data.map((s) => ({
              label: s.name,
              value: s.id
            }))
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
    return () => setSubstatusesList([]);
  }, []);

  useEffect(() => {
    getSettings();
    return () => setSettingsList([]);
  }, []);

  const handleNewSetting = () => {
    newSetting
      ? setNewSetting(null)
      : setNewSetting({
          isDefault: false,
          substatusId: null,
          timeout: null
        });
  };

  const getSettings = () => {
    setLoading(true);
    httpRequest("GET", `bff/system-settings/auto-logout-timeout`)
      .then((res) => {
        if (res.success && res.data) {
          setSettingsList(
            res.data?.map((d) => ({
              ...d,
              timeout: Math.floor(d.timeout / (60 * 1000))
            }))
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = useCallback(({ id, ...body }: AutoLogoutSetting) => {
    setLoading(true);
    httpRequest(
      id ? "PATCH" : "POST",
      `bff/system-settings/auto-logout-timeout${id ? `/${id}` : ""}`,
      {
        body: {
          substatusId: body.substatusId,
          isDefault: body.isDefault,
          timeout: body.timeout * (60 * 1000)
        }
      }
    )
      .then((res) => {
        if (res.success && res.data) {
          getSettings();
        }
      })
      .finally(() => {
        setLoading(false);
        setNewSetting(null);
      });
  }, []);
  const handleDel = useCallback((id: string) => {
    if (id) {
      setLoading(true);
      httpRequest("DELETE", `bff/system-settings/auto-logout-timeout/${id}`)
        .then((res) => {
          if (res.success && res.data) {
            getSettings();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setNewSetting(null);
  }, []);
  return {
    loading,
    substatuses,
    settingsList,
    newSetting,
    handleNewSetting,
    handleSave,
    handleDel
  };
};
