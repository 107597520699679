import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { LOADED_MRFS, FETCH_MRFS } from "../Consts";

export function* getMrfsSaga() {
  try {
    const response = yield httpRequest("GET", "bff/mrfs");

    if (response) {
      yield put({
        type: LOADED_MRFS,
        payload: response.data.regions.map(el => ({
          id: el.id,
          value: el.id,
          label: el.region
        }))
      });
    }
  } catch (e) {
    console.error("Saga: getMrfsSaga error", e);
  }
}

export default function*() {
  yield takeEvery(FETCH_MRFS, getMrfsSaga);
}
