import React from "react";

import c from "classnames";
import { Icon } from "@omnichat/arm_ui_kit";

import * as s from "./LocationMessage.module.scss";

interface IImageMessageProps {
  /** Широта */
  latitude: string;
  /** Долгота */
  longitude: string;
  /** Ссылка на изображение с превью местоположения */
  locationTumbSrc?: string;
}

const LocationMessage: React.FunctionComponent<IImageMessageProps> = ({
  latitude,
  longitude,
  locationTumbSrc,
}): JSX.Element => {
  const getPreviewImg = (imgPath: any): any => {
    return (
      imgPath && <img className={s["containerImagePreview"]} src={imgPath} />
    );
  };

  const getDefaultPreviewImg = (): JSX.Element => {
    return (
      <div className={s["iconContainer"]}>
        <Icon color="light-gray" name="map" width="19rem" height="19rem" />
      </div>
    );
  };

  const getUrl = (longitude: string, latitude: string): string => {
    return `https://maps.yandex.ru/?ll=${longitude}%2C${latitude}&z=17&whatshere[point]=${longitude}%2C${latitude}&whatshere[zoom]=15`;
  };

  return (
    <a
      href={getUrl(longitude, latitude)}
      target="_blank"
      rel="noopener noreferrer"
      className={s["container"]}
    >
      {getPreviewImg(locationTumbSrc) || getDefaultPreviewImg()}

      <div className={c(s["iconContainer"], s["iconMarker"])}>
        <Icon color="red" name="place" width="6rem" height="6rem" />
      </div>
    </a>
  );
};

export default LocationMessage;
