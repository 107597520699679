import {
  GET_USERS_MULTI_PROFILE_CATALOGS,
  SET_AREAS_LIST,
  SET_ORGANIZATIONS_LIST,
  SET_SKILL_GROUPS_LIST,
  SET_TERRITORIES_LIST,
  USERS_MULTI_PROFILE_DATA_UPDATED,
  SHOW_MULTI_USERS_PROFILE_DATA_ERROR,
  SEND_USERS_MULTI_PROFILE_DATA,
  SET_MULTI_USERS_PROFILE_TEXT_ERROR,
  SET_PROFILE_IDS,
  RESET_USERS_MULTI_PROFILE_DATA,
  DEACTIVATE_USERS
} from "./consts";

export const getUsersMultiProfileCatalogs = () => ({
  type: GET_USERS_MULTI_PROFILE_CATALOGS
});

export const sendUserProfileData = data => ({
  type: SEND_USERS_MULTI_PROFILE_DATA,
  payload: { data }
});

export const setProfileIds = data => ({
  type: SET_PROFILE_IDS,
  payload: { data }
});
export const setAreasList = data => ({
  type: SET_AREAS_LIST,
  payload: { data }
});
export const setSkillGroupList = data => ({
  type: SET_SKILL_GROUPS_LIST,
  payload: { data }
});
export const setTerritoriesList = data => ({
  type: SET_TERRITORIES_LIST,
  payload: { data }
});
export const setOrganizationsList = data => ({
  type: SET_ORGANIZATIONS_LIST,
  payload: { data }
});

export const usersMultiProfileDataUpdated = data => ({
  type: USERS_MULTI_PROFILE_DATA_UPDATED,
  payload: { data }
});

export const showUsersMultiProfileError = data => ({
  type: SHOW_MULTI_USERS_PROFILE_DATA_ERROR,
  payload: { data }
});

export const setUsersMultiProfileTextError = data => ({
  type: SET_MULTI_USERS_PROFILE_TEXT_ERROR,
  payload: { data }
});

export const resetUsersMultiProfileData = () => ({
  type: RESET_USERS_MULTI_PROFILE_DATA
});

export const deactivateUsers = data => ({
  type: DEACTIVATE_USERS,
  payload: { data }
});
