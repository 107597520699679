import { takeEvery, put, select } from "redux-saga/effects";

import { httpRequest } from "../../ReactCore/shared/httpRequest";
import { GET_USERS_OWN_PROFILE_DATA } from "./consts";
import { setUsersOwnProfileData } from "./usersProfile.action";

export function* getUsersOwnProfileData(action) {
  const profileId = action.payload.data;

  try {
    const res = yield httpRequest("GET", `bff/settings/profiles/${profileId}/`);

    if (res?.success) {
      yield put(setUsersOwnProfileData(res.data));
    }
  } catch ($error) {
    console.error($error);
  }
}

export default function* () {
  yield takeEvery(GET_USERS_OWN_PROFILE_DATA, getUsersOwnProfileData);
}
