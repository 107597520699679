import { appealInfoModalInitialState } from "../Consts";
import { createSlice } from "@reduxjs/toolkit";

/**
 * Ветка redux-state компонента AppealInfoModal.
 */
const appealInfoSlice = createSlice({
  name: "appealInfo",
  initialState: appealInfoModalInitialState,
  reducers: {
    setAppealData(state, action) {
      state.data = action.payload;
    },
    cleanAppealData(state) {
      state.data = appealInfoModalInitialState.data;
    }
  }
});

export const { setAppealData, cleanAppealData } = appealInfoSlice.actions;

export default appealInfoSlice.reducer;
