import React from "react";

import { Tooltip as MuiTooltip, TooltipProps } from "@mui/material";

export interface ITooltipProps
  extends Omit<TooltipProps, "placement" | "arrow" | "title"> {
  content: string;
  position?: TooltipProps["placement"];
}

const Tooltip: React.FunctionComponent<ITooltipProps> = ({
  content,
  children,
  position = "top",
  ...props
}): JSX.Element => {
  return (
    <MuiTooltip
      title={content}
      placement={position}
      arrow
      {...props}
    >
      <div>{children}</div>
    </MuiTooltip>
  );
};

export default Tooltip;
