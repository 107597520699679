import * as React from "react";
import { connect } from "react-redux";

import { StoreState } from "../../../ReactCore/store/types";
import { sendReopenData } from "../Actions/ReopenAppealModal.actionCreators";
import MoveAppealModalProvider from "./MoveAppealModal.provider";

/**
 * Модель свойств, полученных из store.
 *
 * @prop {IAsyncData} asyncAvailableHotKeysData Контейнер с данными МО трансфера,
 * которые получены асинхронно.
 */
interface IStateProps {}

/**
 * Экшены.
 */
interface IDispatchProps {
  sendReopenData: any;
}

/**
 * Интерфейс собственных свойств компонента.
 *
 * @prop {number} appealId Идентификатор обращения.
 * @prop {Function} close Метод, закрывающий текущее модальное окно
 */
interface IOwnProps {
  appealId;
  close;
}

type TProps = IOwnProps & IDispatchProps & IStateProps;

class ReopenAppealModalProvider extends React.PureComponent<TProps> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { appealId, close, sendReopenData } = this.props;

    return (
      <MoveAppealModalProvider
        close={close}
        appealId={appealId}
        header="Открыть обращение?"
        onConfirm={sendReopenData}
      />
    );
  }
}

const mapStateToProps = (state: StoreState.State): IStateProps => ({});

const mapDispatchToProps = {
  sendReopenData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReopenAppealModalProvider);
