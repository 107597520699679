import { takeEvery } from "redux-saga/effects";

import { httpRequest } from "../../ReactCore/shared/httpRequest";
import { RESTORE_USERS_PASS } from "./consts";

import Informer from "../../arm/Wrappers/Informer";

export function* restoreUsersPass(action) {
  const userIds = action.payload.data.users;
  try {
    const res = yield httpRequest(
      "PUT",
      `bff/settings/profiles/reset-password`,
      { body: { usersIds: userIds } }
    );
    if (res?.success) {
      const info = new Informer(
        `Новый пароль был отправлен на почту пользователя(ей)`,
        4500
      );
      info.show();
      action.payload.data.close();
    } else {
      const info = new Informer(`Не удалось восстановить пароль`, 2500);
      info.show();
    }
  } catch ($error) {
    console.error($error);
  }
}

export default function* () {
  yield takeEvery(RESTORE_USERS_PASS, restoreUsersPass);
}
