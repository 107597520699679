import React, { forwardRef, useState, ChangeEvent, FocusEvent, FC } from "react";
import { NumericFormat, NumericFormatProps, PatternFormat, PatternFormatProps } from 'react-number-format';
import Input from '../Input';
import { IInputProps } from "../Input/Input";

interface INumberInputProps extends Omit<IInputProps, "onChange" | "onBlur"> {
  onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e?: FocusEvent<HTMLInputElement>) => void;
  maskInput?: {
    filler: string;
    format: string;
  };
};

const InputWithSwitchedProps = (
  Child: FC<IInputProps>
): FC<INumberInputProps> => {

  const Switcher: FC<INumberInputProps> = React.memo(
    (props): JSX.Element => {
      const {
        onChange,
        onBlur,
        ...inputProps
      } = props;

      const onChangeEventOnly = (
        value: string,
        e: ChangeEvent<HTMLInputElement>
      ) => {
        onChange?.(e)
      };

      const onBlurEventOnly = (
        value: string,
        e: FocusEvent<HTMLInputElement>
      ) => {
        onBlur?.(e)
      };

      return (
        <Child
          onChange={onChangeEventOnly}
          onBlur={onBlurEventOnly}
          {...inputProps} />
      );
    }
  );

  return Switcher;
};

const InputTransferEventDirect = InputWithSwitchedProps(Input);

const NumberInput = forwardRef<NumericFormatProps | PatternFormatProps, INumberInputProps>(
  function (props, ref) {

    const { type, defaultValue, maskInput, ...other } = props;

    const [, setValue] = useState("");

    return maskInput?.format
      ? (
        <PatternFormat
          getInputRef={ref}
          format={maskInput.format}
          mask={maskInput.filler}
          customInput={InputTransferEventDirect}
          onValueChange={({ value }) => {
            setValue(value)
          }}
          {...other}
        />
        )
      : (
        <NumericFormat
          getInputRef={ref}
          allowNegative={false}
          allowLeadingZeros
          valueIsNumericString={true}
          decimalScale={0}
          customInput={InputTransferEventDirect}
          onValueChange={({ value }) => {
            setValue(value)
          }}
          {...other}
        />
      )
  }
);

export default NumberInput;
