import React, { ReactNode, useState } from "react";

import { Icon, Button, isEmpty } from "@omnichat/arm_ui_kit";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DragUpdate
} from "react-beautiful-dnd";

import * as s from "./style.module.scss";
import c from "classnames";

const Item: React.FC<{
  id: string;
  isActive: boolean;
  index: number;
  name: string;
  canCollapse?: boolean;
  isCallapsed?: boolean;
  onCollapse?: () => void;
  onClick: () => void;
  children?: ReactNode;
}> = ({
  id,
  index,
  name,
  onClick,
  isActive,
  onCollapse,
  canCollapse,
  isCallapsed,
  children
}) => {
  return (
    <Draggable
      key={`${id}-${index}`}
      draggableId={id}
      index={index}
      isDragDisabled={true}
    >
      {draggableFieldProvider => (
        <div
          ref={draggableFieldProvider.innerRef}
          {...draggableFieldProvider.draggableProps}
        >
          <div
            className={c(s.item, {
              [s.active]: isActive
            })}
            onClick={() => canCollapse && onCollapse?.()}
          >
            {/* <Icon
              name="apps"
              color="blue"
              width="24px"
              height="28px"
              {...draggableFieldProvider.dragHandleProps}
            /> */}

            {canCollapse && !isCallapsed && (
              <div
                className={c(s.collapseIcon, {
                  [s.openCollapse]: isCallapsed,
                  [s.closeCollapse]: !isCallapsed
                })}
              >
                <Icon
                  name="action_back"
                  color={isActive ? "white" : "blue"}
                  width="18px"
                  height="18px"
                />
              </div>
            )}
            <div className={c(s.itemName, { [s.itemNameActive]: isActive })}>
              {name}
            </div>
            <div className={s.itemIcon}>
              <Icon
                extraClass={[{ [s.hover]: true }]}
                color={isActive ? "white" : "blue"}
                onClick={onClick}
                name="edit"
                width="18px"
                height="18px"
              />
            </div>
          </div>
          {children || <></>}
        </div>
      )}
    </Draggable>
  );
};

interface Item {
  id: string;
  name: string;
  type: string;
}

interface Props {
  fields?: (Item & {
    values: Item[] | null;
  })[];
  classifierId?: string;
  activeField?: string;
  activeValue?: string;
  onCreateField: () => void;
  onEditField: (fieldId: string) => void;
  onCreateValue: (fieldId: string) => void;
  onEditValue: (fieldId: string, valueId: string) => void;
  onCollapseValues: (fieldId: string) => void;
  onDrop: (newIndex: number, oldIndex: number) => void;
}

const ClassifierFieldTree: React.FC<Props> = ({
  classifierId,
  activeField,
  activeValue,
  fields,
  onCreateField,
  onEditField,
  onCreateValue,
  onEditValue,
  onCollapseValues,
  onDrop
}): JSX.Element => {
  const [sourceBlock, setSourceBlock] = useState<string | null>(null);
  const handleDrop = ({ destination, source }: DropResult) => {
    setSourceBlock(null);

    const newIndex = destination?.index;
    const oldIndex = source?.index;

    if (!newIndex || newIndex === oldIndex) return;

    onDrop?.(newIndex, oldIndex);
  };

  const handleDragStart = ({ source }: DragUpdate) => {
    setSourceBlock(source.droppableId);
  };

  const withCollapse = ["DROPDOWN", "RADIO"];

  return (
    <div className={s.classifierFieldTree}>
      <DragDropContext onDragEnd={handleDrop} onDragStart={handleDragStart}>
        <Droppable
          droppableId="fields"
          isDropDisabled={sourceBlock !== "fields"}
        >
          {droppableFieldProvider => (
            <div className={s.fields} ref={droppableFieldProvider.innerRef}>
              {fields?.map((f, j) => (
                <Item
                  key={`${f.id}${j}`}
                  id={`field-${f.id}${j}`}
                  isActive={!activeValue && `${f.id}` === activeField}
                  index={j}
                  name={f.name}
                  onClick={() => {
                    onEditField(f.id);
                  }}
                  onCollapse={() => onCollapseValues(f.id)}
                  canCollapse={withCollapse.includes(f.type)}
                  isCallapsed={f.values !== null}
                >
                  <Droppable
                    droppableId={`values-${f.id}${j}`}
                    isDropDisabled={sourceBlock !== `values-${f.id}${j}`}
                  >
                    {droppableValueProvider => (
                      <div
                        className={s.values}
                        ref={droppableValueProvider.innerRef}
                      >
                        {withCollapse.includes(f.type) &&
                          f.values?.map((v, i) => (
                            <Item
                              key={`${i}${v.id}${f.id}${j}`}
                              id={`id-value-${i}${v}${f}${j}`}
                              isActive={
                                `${f.id}` === activeField &&
                                `${v.id}` === activeValue
                              }
                              index={i}
                              name={v.name}
                              onClick={() => {
                                onEditValue(f.id, v.id);
                              }}
                            />
                          ))}
                        {withCollapse.includes(f.type) && f.values !== null && (
                          <Button
                            type="underline"
                            text="Добавить значение"
                            onClick={() => onCreateValue(f.id)}
                          />
                        )}
                        {droppableValueProvider.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Item>
              ))}
              {droppableFieldProvider.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {classifierId && (
        <Button type="underline" text="Добавить поле" onClick={onCreateField} />
      )}
    </div>
  );
};

export default ClassifierFieldTree;
