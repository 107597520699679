import * as React from "react";

import {
  getMessageSignature,
  getMessageTo,
  getMessageFrom,
  getInfoTransferFrom,
  getInfoUserField,
  getMediaUrl,
  getMessageSender,
  getMessageSide,
  getMessageTextContainerStyle,
  getOperatorsName,
  getTimeStampInMilliseconds,
  getUniqueId,
  getUserAvatar,
  isImageMessageConstructorShown,
  isLocationMessageShown,
  isMessageTextShown,
  isUploadFileLinkShown,
  resolveMessage,
} from "../Utils";
import { EMessagesType } from "../../Common/Enums/Message.enums";
import { IMessage } from "../../Common/Interfaces/Message.interfaces";
import ImageMessageConstructor from "./Image.constructor";
import SystemMessage from "./SystemMessage/SystemMessage";
import DialogMessage from "./DialogMessage/DialogMessage";
import LocationMessage from "./LocationMessage/LocationMessage";
import UploadFileLink from "./UploadFileLink/UploadFileLink";

/**
 * Свойства компонента по отображению блока с сообщениями.
 *
 * @prop {IMessage[]} messages Массив сообщений для отображения.
 * @prop {string} filesUrl Ссылка на расположение файлов для конкатенации.
 * @prop {string} avatarUrl Ссылка на расположение аватара.
 * @prop {Function} onShowImageViewer Колбэк на отображения компонента для просмотра изображения.
 * @prop {boolean} [isExpanded] Флаг, который показывает, развернуты ли системные сообщения по умолчанию.
 */
export interface IMessageBox {
  messages: IMessage[];
  filesUrl: string;
  avatarUrl: string;
  onShowImageViewer: (imageSrc: string) => void;
  isExpanded?: boolean;
  isMobile?: boolean;
}

/**
 * Блок с сообщениями.
 */
const MessageBox: React.FunctionComponent<IMessageBox> = ({
  messages,
  filesUrl,
  avatarUrl,
  onShowImageViewer,
  isExpanded = false,
  isMobile = false,
}): JSX.Element => {
  /**
   * Отрисует компонент UploadFileLink.
   *
   * @param {IMessage} messageData Данные о сообщении.
   */
  const renderUploadFileLink = function (messageData: IMessage) {
    return (
      isUploadFileLinkShown(messageData) && (
        <UploadFileLink
          fileUrl={filesUrl + getMediaUrl(messageData.mediaUrl)}
        />
      )
    );
  };

  /**
   * Отрисует компонент LocationMessage.
   *
   * @param {IMessage} messageData Данные о сообщении.
   */
  const renderLocationMessage = function (messageData: IMessage) {
    return (
      isLocationMessageShown(messageData) && (
        <LocationMessage
          latitude={messageData.latitude}
          longitude={messageData.longitude}
          locationTumbSrc={
            messageData.mediaUrl && filesUrl + messageData.mediaUrl
          }
        />
      )
    );
  };

  /**
   * Отрисует компонент ImageMessageConstructor.
   *
   * @param {IMessage} messageData Данные о сообщении.
   */
  const renderImageMessageConstructor = function (messageData: IMessage) {
    return (
      isImageMessageConstructorShown(messageData) && (
        <ImageMessageConstructor
          filesUrl={filesUrl}
          messageData={messageData}
          onShowImageViewer={onShowImageViewer}
        />
      )
    );
  };

  /**
   * Отрисует текст сообщения.
   *
   * @param {IMessage} messageData Данные о сообщении.
   */
  const renderMessageText = function (messageData: IMessage) {
    return (
      isMessageTextShown(messageData) && (
        <div
          style={getMessageTextContainerStyle(messageData?.mediaType)}
          dangerouslySetInnerHTML={{
            __html: resolveMessage(messageData.text),
          }}
        />
      )
    );
  };

  const switchMessages = (messageData: IMessage): JSX.Element | void => {
    switch (messageData.type) {
      case EMessagesType.Default:
        return (
          <DialogMessage
            key={getUniqueId(messageData)}
            infoText={getMessageSignature({
              type: getMessageSender(messageData),
              time: getTimeStampInMilliseconds(messageData.date),
              name: getOperatorsName(messageData.user),
              channel: messageData.channel ? messageData.channel.name : "",
            })}
            side={getMessageSide(messageData)}
            avatar={
              isMobile
                ? null
                : messageData.user && getUserAvatar(messageData.user, avatarUrl)
            }
          >
            <>
              {renderUploadFileLink(messageData)}

              {renderLocationMessage(messageData)}

              {renderImageMessageConstructor(messageData)}

              {renderMessageText(messageData)}
            </>
          </DialogMessage>
        );
      case EMessagesType.Greeting:
      case EMessagesType.AnswerDelay:
        return (
          <DialogMessage
            key={getUniqueId(messageData)}
            infoText={getMessageSignature({
              type: getMessageSender(messageData),
              time: getTimeStampInMilliseconds(messageData.date),
            })}
            side="right"
          >
            <>{messageData.text}</>
          </DialogMessage>
        );
      case EMessagesType.Waiting:
        return (
          <SystemMessage
            key={getUniqueId(messageData)}
            number={messageData.appealId}
            action={messageData.type}
            info={getMessageSignature({
              type: getMessageSender(messageData),
              time: getTimeStampInMilliseconds(messageData.date),
            })}
            isExpanded={!!isExpanded}
            uniqueId={getUniqueId(messageData)}
          />
        );
      case EMessagesType.TransferBySystem:
        if (!messageData.transfer) {
          return;
        }
        return (
          <SystemMessage
            key={getUniqueId(messageData)}
            number={messageData.appealId}
            action={messageData.type}
            from={getMessageFrom(messageData.transfer)}
            to={getMessageTo(messageData.transfer)}
            info={getMessageSignature({
              type: getMessageSender(messageData),
              time: getTimeStampInMilliseconds(messageData.date),
            })}
            isExpanded={!!isExpanded}
            uniqueId={getUniqueId(messageData)}
          />
        );
      case EMessagesType.TransferByUser:
        if (!messageData.transfer) {
          return;
        }
        return (
          <SystemMessage
            key={getUniqueId(messageData)}
            reason={messageData.text}
            number={messageData.appealId}
            action={messageData.type}
            from={getMessageFrom(messageData.transfer)}
            to={getMessageTo(messageData.transfer)}
            info={getMessageSignature({
              type: getMessageSender(messageData),
              time: getTimeStampInMilliseconds(messageData.date),
              name: getInfoUserField(messageData),
            })}
            isExpanded={!!isExpanded}
            uniqueId={getUniqueId(messageData)}
          />
        );
      case EMessagesType.CreateAppeal:
        return (
          <SystemMessage
            key={getUniqueId(messageData)}
            reason={messageData.text}
            number={messageData.appealId}
            action={messageData.type}
            info={getMessageSignature({
              type: getMessageSender(messageData),
              time: getTimeStampInMilliseconds(messageData.date),
            })}
            isExpanded={!!isExpanded}
            uniqueId={getUniqueId(messageData)}
          />
        );
      case EMessagesType.ReopenAppeal:
        return (
          <SystemMessage
            key={getUniqueId(messageData)}
            reason={messageData.text}
            number={messageData.appealId}
            action={messageData.type}
            info={getMessageSignature({
              type: getMessageSender(messageData),
              time: getTimeStampInMilliseconds(messageData.date),
              name: getInfoTransferFrom(messageData.transfer),
            })}
            isExpanded={!!isExpanded}
            uniqueId={getUniqueId(messageData)}
          />
        );
      case EMessagesType.CloseAppeal:
        return (
          <SystemMessage
            key={getUniqueId(messageData)}
            reason={messageData.text}
            number={messageData.appealId}
            action={messageData.type}
            info={getMessageSignature({
              type: getMessageSender(messageData),
              time: getTimeStampInMilliseconds(messageData.date),
              name: getInfoUserField(messageData),
            })}
            csi={messageData.csi}
            isExpanded={!!isExpanded}
            uniqueId={getUniqueId(messageData)}
          />
        );
    }
  };

  return <>{messages.map(switchMessages)}</>;
};

export default MessageBox;
