import {
  SL_OPTIONS,
  CSI_OPTIONS,
  STATUS_OPTIONS,
  WHO_PROCESSED_OPTIONS,
  COMMON_OPTIONS,
  INITIAL_FORM_DATA,
  CHANNEL_DEFAULT_OPTIONS
} from "./consts";

const dashboardFilters = {
  status: {
    initialDataLoaded: false,
    openedFiltersPanel: false
  },
  whoProcessedList: WHO_PROCESSED_OPTIONS,
  slList: SL_OPTIONS,
  csiList: CSI_OPTIONS,
  channelList: CHANNEL_DEFAULT_OPTIONS,
  statusList: STATUS_OPTIONS,
  regionList: COMMON_OPTIONS,
  mrfList: COMMON_OPTIONS,
  rfList: COMMON_OPTIONS,
  formData: INITIAL_FORM_DATA,
  confirmInProgress: false
};

export default dashboardFilters;
