// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PI8rzVJlHJ2i8NlTXjSl{font-size:16px;color:var(--color-text-hints);font-weight:700;line-height:34px}.dANghtH4aQlLJ3WpUmmg{flex:1}", "",{"version":3,"sources":["webpack://./frontend/src/Settings/SystemSettings/SystemSettings.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,6BAAA,CACA,eAAA,CACA,gBAAA,CAGF,sBACE,MAAA","sourcesContent":[".label {\n  font-size: 16px;\n  color: var(--color-text-hints);\n  font-weight: 700;\n  line-height: 34px;\n}\n\n.reason {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export var label = "PI8rzVJlHJ2i8NlTXjSl";
export var reason = "dANghtH4aQlLJ3WpUmmg";
export default ___CSS_LOADER_EXPORT___;
