import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../ReactCore/shared/httpRequest";
import { DEACTIVATE_USERS } from "./consts";
import Informer from "../../arm/Wrappers/Informer";

export function* deactivateUsersSaga(action) {
  const { skillGroupId, users, isAll, accessCb, errorCb } = action.payload.data;

  try {
    const result = yield httpRequest("DELETE", `bff/user`, {
      body: {
        skillGroupId,
        users,
        isAll: isAll ?? false
      }
    });

    if (result?.success) {
      accessCb();
    } else {
      errorCb();
    }
  } catch ($error) {
    errorCb();
    console.error($error);
  }
}

export default function* () {
  yield takeEvery(DEACTIVATE_USERS, deactivateUsersSaga);
}
