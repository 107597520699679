// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OyGr7YZjrdv6UHfzhXI0{font-size:1.6em;position:absolute;top:0;left:20px;z-index:1;background:rgba(255,255,255,.9);width:calc(100% - 40px)}._Hn7ityEggRRQsrvSwDa{display:flex;justify-content:space-between;margin:8px 0 5px 0}.KjKfKc6XIoe_ayJt9vGg{text-transform:uppercase;font-weight:bolder;color:#829199}.RNul2PxbGhyLw0_ql2Xu{display:flex;flex-direction:row;justify-content:flex-start;padding:10px 0 10px 10px;color:#88969e;border-bottom:1px solid #cbd7da}.gKmi30DqkdWGed8ZCoLT{width:30px;padding-right:20px}.gpHLY5UwGKLi7CwXihP_{flex-grow:1}", "",{"version":3,"sources":["webpack://./frontend/src/Settings/Channels/styles.module.scss"],"names":[],"mappings":"AACE,sBACE,eAAA,CACA,iBAAA,CACA,KAAA,CACA,SAAA,CACA,SAAA,CACA,+BAAA,CACA,uBAAA,CAEA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAIJ,sBACE,wBAAA,CACA,kBAAA,CACA,aAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,wBAAA,CACA,aAAA,CACA,+BAAA,CAGF,sBACE,UAAA,CACA,kBAAA,CAEF,sBACE,WAAA","sourcesContent":[".list {\n  &Header {\n    font-size: 1.6em;\n    position: absolute;\n    top: 0;\n    left: 20px;\n    z-index: 1;\n    background: rgba(255, 255, 255, 0.9);\n    width: calc(100% - 40px);\n\n    &BtnWrap {\n      display: flex;\n      justify-content: space-between;\n      margin: 8px 0 5px 0;\n    }\n  }\n\n  &Title {\n    text-transform: uppercase;\n    font-weight: bolder;\n    color: #829199;\n  }\n\n  &Control {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    padding: 10px 0 10px 10px;\n    color: #88969e;\n    border-bottom: 1px solid #cbd7da;\n  }\n\n  &Col1 {\n    width: 30px;\n    padding-right: 20px;\n  }\n  &Col2 {\n    flex-grow: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var listHeader = "OyGr7YZjrdv6UHfzhXI0";
export var listHeaderBtnWrap = "_Hn7ityEggRRQsrvSwDa";
export var listTitle = "KjKfKc6XIoe_ayJt9vGg";
export var listControl = "RNul2PxbGhyLw0_ql2Xu";
export var listCol1 = "gKmi30DqkdWGed8ZCoLT";
export var listCol2 = "gpHLY5UwGKLi7CwXihP_";
export default ___CSS_LOADER_EXPORT___;
