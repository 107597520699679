import React, { useState } from "react";

import { Button, FormField } from "@omnichat/arm_ui_kit";

import TextArea from "../../../Dialog/Components/TextArea/TextArea";

import * as cs from "../../../../styles/common.module.scss";

interface ICloseAppealByAgentWithoutClassifierModalProps {
  comment: boolean;
  onCancelAction?: () => void;
  onConfirmAction?: () => string | void;
}

const dashboardCloseMessage = `Запрос оценки качества обслуживания не будет отправлен посетителю.`;
const chatCloseMessage = `После закрытия обращения Вы не сможете написать в него сообщение, а
                          клиенту будет отправлен запрос оценки качества обслуживания.`;

const CloseAppealByAgentWithoutClassifierModal: React.FunctionComponent<ICloseAppealByAgentWithoutClassifierModalProps> = ({
  comment = false,
  onCancelAction = () => {},
  onConfirmAction = () => {},
}) => {
  const [newComment, setComment] = useState("");
  const [error, setError] = useState("");

  const onConfirmActionWithComment = (e) => {
    if (!newComment) {
      setError("Комментарий обязателен");
      return false;
    }
    onConfirmAction(newComment);
  };

  return (
    <div className={cs["defaultModal"]}>
      <div className={cs["defaultModalHeader"]}>Закрыть обращение?</div>
      <div className={cs["defaultModalContent"]}>
        {comment ? dashboardCloseMessage : chatCloseMessage}
      </div>

      {comment && (
        <FormField label={"Причина"} required={true}>
          <TextArea
            focus="Введите комментарий к закрытию обращения"
            placeholder="Введите комментарий к закрытию обращения"
            onChange={(e) => {
              setComment(e.text);
              setError("");
            }}
            isError={!!error}
            actionText={error}
            initialValue=""
          />
        </FormField>
      )}

      <div className={cs["defaultModalControl"]}>
        <Button
          id="cancelButton"
          type="default"
          theme="red"
          text="Отменить"
          onClick={onCancelAction}
        />
        <Button
          id="confirmButton"
          type="default"
          theme="green"
          text="Продолжить"
          onClick={comment ? onConfirmActionWithComment : onConfirmAction}
        />
      </div>
    </div>
  );
};

export default CloseAppealByAgentWithoutClassifierModal;
