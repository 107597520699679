import { USERS_PROFILE_RATING } from "./consts";

const usersProfileModal = {
  userOwnData: null,

  photo: undefined,

  status: {
    usersProfileHandling: false,
    usersProfileDataLoading: false,
    areasLoaded: false,
    organizationsLoaded: false,
    rolesLoaded: false,
    skillGroupsLoaded: false,
    territoriesLoaded: false
  },

  usersProfileData: null,

  formData: {
    id: {
      required: false,
      error: false,
      value: null
    },
    file: {
      required: false,
      error: false,
      value: null
    },
    firstname: {
      required: true,
      error: false,
      value: "",
      actionText: null
    },
    lastname: {
      required: true,
      error: false,
      value: "",
      actionText: null
    },
    nickname: {
      required: false,
      error: false,
      value: ""
    },
    email: {
      required: false,
      error: false,
      value: "",
      actionText: null
    },
    yearOfBirth: {
      required: false,
      error: false,
      value: null
    },
    role: {
      required: true,
      error: false,
      value: [],
      actionText: null
    },
    areaId: {
      required: false,
      error: false,
      value: [],
      actionText: null
    },
    organizationId: {
      required: false,
      error: false,
      value: [],
      actionText: null
    },
    territoryId: {
      required: false,
      error: false,
      value: [],
      actionText: null
    },
    rate: {
      required: false,
      error: false,
      value: []
    },
    skillGroupId: {
      required: true,
      error: false,
      value: [],
      actionText: null
    },
    personnelNumber: {
      required: false,
      error: false,
      value: null
    },
    phoneNumber: {
      required: false,
      error: false,
      value: null
    },
    crmLogin: {
      required: false,
      error: false,
      value: null
    }
  },

  errorMessage: null,

  rating: USERS_PROFILE_RATING,
  organizations: [],
  roles: [],
  areas: [],
  territories: [],
  skillGroups: []
};

export default usersProfileModal;
