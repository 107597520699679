// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Xhv7wqWBvbla8HHAvKom{font-size:16px;font-family:inherit;min-height:124px;width:100%;display:block;border-radius:2px;color:var(--color-text-default);background:var(--color-primary-bg);border:2px solid var(--color-primary-bg);outline:none;resize:vertical;padding:10px 8px;overflow:auto}.Xhv7wqWBvbla8HHAvKom::placeholder{color:var(--color-primary-text)}.YJ2ZasbNxQJe9YXo92Om{position:relative;margin-bottom:20px}.YJ2ZasbNxQJe9YXo92Om .phr4aXhOu9rnroJ1WrER{position:absolute;top:0;right:0;padding:0;font:normal bold 15px \"PT Sans\",Arial,sans-serif,Candara,Segoe,\"Segoe UI\",Optima}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/MoveAppealModal/Components/TransferAppealModal/TransferAppealModal.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,mBAAA,CACA,gBAAA,CACA,UAAA,CACA,aAAA,CACA,iBAAA,CACA,+BAAA,CACA,kCAAA,CACA,wCAAA,CACA,YAAA,CACA,eAAA,CACA,gBAAA,CACA,aAAA,CACA,mCACE,+BAAA,CAIF,sBACE,iBAAA,CACA,kBAAA,CAEA,4CACE,iBAAA,CACA,KAAA,CACA,OAAA,CACA,SAAA,CACA,gFAAA","sourcesContent":[".comment {\n  font-size: 16px;\n  font-family: inherit;\n  min-height: 124px;\n  width: 100%;\n  display: block;\n  border-radius: 2px;\n  color: var(--color-text-default);\n  background: var(--color-primary-bg);\n  border: 2px solid var(--color-primary-bg);\n  outline: none;\n  resize: vertical;\n  padding: 10px 8px;\n  overflow: auto;\n  &::placeholder {\n    color: var(--color-primary-text);\n  }\n}\n.modal {\n  &__target-appeal {\n    position: relative;\n    margin-bottom: 20px;\n\n    .at-header {\n      position: absolute;\n      top: 0;\n      right: 0;\n      padding: 0;\n      font: normal bold 15px \"PT Sans\", Arial, sans-serif, Candara, Segoe,\n        \"Segoe UI\", Optima;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var comment = "Xhv7wqWBvbla8HHAvKom";
export var modalTargetAppeal = "YJ2ZasbNxQJe9YXo92Om";
export var atHeader = "phr4aXhOu9rnroJ1WrER";
export default ___CSS_LOADER_EXPORT___;
