import React, { FC, MouseEventHandler } from "react";
import c from "classnames";
import { SvgIconComponent } from "@mui/icons-material";
import MuiButton from "@mui/material/Button";

import * as s from "./style.module.scss";

/**
 * Свойства компонента Button.
 */
export type IButtonProps = {
  /** Тип Button */
  type: "bare" | "default" | "fill" | "underline" | "closeModal";
  /** Размер Button */
  size?: string;
  /** Текст Button */
  text?: string;
  /** Тема Button */
  theme?: "bare" | "green" | "red" | "blue" | "disabled" | "default";
  /** Блокировка Button */
  disabled?: boolean;
  /** индикатор загрузки */
  loading?: boolean;
  /** Button onClick function */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** спец-иконка из @mui/icons-material */
  IconMUI?: SvgIconComponent;
  /** кастомная svg-иконка без пропсов */
  IconCustom?: FC;
  /** Дополнительный стиль Button */
  extraClass?: (string | Record<string, unknown>)[];

  /** Дополнительный цвет svg */
  iconColor?: string;
  /** Позиция иконки */
  iconPosition?: "left" | "right";
  /** Id элемента */
  id?: string;
};

/**
 * Компонент Button.
 */
const Button: FC<IButtonProps> = ({
  iconColor,
  type,
  size = "default",
  text,
  theme = "default",
  disabled,
  loading,
  onClick,
  IconMUI,
  IconCustom,
  extraClass = [],
  iconPosition = "left",
  id,
  ...props
}) => {
  /** Переменная, отвечающая за компоновку стилей */
  const cRoot = c(
    s["Button"],
    s[`type__${type}`],
    s[`theme__${theme}`],
    s[`size__${size}`],
    {
      [s["disabled"]]: disabled,
      [s["with_icon"]]: IconMUI || IconCustom,
      [s["with_text"]]: text
    },
    ...extraClass
  );

  return (
    <MuiButton
      onClick={loading ? () => {} : onClick}
      className={cRoot}
      disabled={disabled}
      id={id}
      startIcon={IconMUI && iconPosition === "left" && <IconMUI />}
      endIcon={IconMUI && iconPosition === "right" && <IconMUI />}
      {...props}
    >
      {text && <span className={s["content"]}>{text}</span>}
      <IconCustom />
    </MuiButton>
  );
};

export default Button;
