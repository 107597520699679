import {
  SET_PHOTO,
  USERS_PROFILE_CREATED,
  USERS_PROFILE_DATA_UPDATED,
  GET_USERS_PROFILE_CATALOGS,
  GET_USERS_PROFILE_DATA,
  REMOVE_USERS_PROFILE,
  RESET_USERS_PROFILE_DATA,
  SEND_USERS_PROFILE_DATA,
  SET_AREAS_LIST,
  SET_USERS_EMAIL_ERROR,
  SET_USERS_PROFILE_RESPONSE_ERROR,
  SET_USERS_PROFILE_DATA,
  SET_USERS_OWN_PROFILE_DATA,
  GET_USERS_OWN_PROFILE_DATA,
  SET_ORGANIZATIONS_LIST,
  SET_SKILL_GROUPS_LIST,
  SET_TERRITORIES_LIST,
  SET_ROLES_LIST,
  SHOW_USERS_PROFILE_DATA_ERROR,
  RESTORE_USERS_PASS
} from "./consts";

export const restoreUsersPass = data => ({
  type: RESTORE_USERS_PASS,
  payload: { data }
});

export const getUsersProfileCatalogs = data => ({
  type: GET_USERS_PROFILE_CATALOGS,
  payload: { data }
});

export const removeUsersProfile = () => ({
  type: REMOVE_USERS_PROFILE
});

export const setPhoto = data => ({
  type: SET_PHOTO,
  payload: { data }
});
export const setAreasList = data => ({
  type: SET_AREAS_LIST,
  payload: { data }
});
export const setSkillGroupList = data => ({
  type: SET_SKILL_GROUPS_LIST,
  payload: { data }
});
export const setTerritoriesList = data => ({
  type: SET_TERRITORIES_LIST,
  payload: { data }
});
export const setRolesList = data => ({
  type: SET_ROLES_LIST,
  payload: { data }
});
export const setOrganizationsList = data => ({
  type: SET_ORGANIZATIONS_LIST,
  payload: { data }
});

export const usersProfileDataUpdated = data => ({
  type: USERS_PROFILE_DATA_UPDATED,
  payload: { data }
});

export const setUsersOwnProfileData = data => ({
  type: SET_USERS_OWN_PROFILE_DATA,
  payload: { data }
});

export const getUsersOwnProfileData = data => ({
  type: GET_USERS_OWN_PROFILE_DATA,
  payload: { data }
});

export const setUsersProfileData = data => ({
  type: SET_USERS_PROFILE_DATA,
  payload: { data }
});
export const usersProfileCreated = () => ({
  type: USERS_PROFILE_CREATED
});
export const getUsersProfileData = data => ({
  type: GET_USERS_PROFILE_DATA,
  payload: { data }
});
export const resetUsersProfileData = () => ({
  type: RESET_USERS_PROFILE_DATA
});
export const sendUsersProfileData = data => ({
  type: SEND_USERS_PROFILE_DATA,
  payload: { data }
});

export const setUsersProfileEmailError = data => ({
  type: SET_USERS_EMAIL_ERROR,
  payload: { data }
});
export const showUsersProfileError = data => ({
  type: SHOW_USERS_PROFILE_DATA_ERROR,
  payload: { data }
});
export const setUsersProfileResponseError = data => ({
  type: SET_USERS_PROFILE_RESPONSE_ERROR,
  payload: { data }
});
