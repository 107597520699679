import React, { memo, useRef } from "react";

import {
  Select as MuiSelect,
  MenuItem,
  SelectChangeEvent,
  Box
} from "@mui/material";
import { Icon } from "@omnichat/arm_ui_kit";

import Chip from "../Chip";

/**
 * Свойства option`s Select.
 * @prop {string | number} value значение option
 * @prop {string} label подпись option
 * @prop {string | any} key ключевое поле
 */
export interface IOption {
  value: string | number;
  label: string;
  [key: string]: string | any;
}

/**
 * Свойства компонента Select.
 * @prop {IOption[]} options список для отображения в селекте
 * @prop {IOption[]} selected выбранный вариант
 * @prop {Function} onOpen обработчик события открытия списка
 * @prop {(selected: (IOption[]) => void} onSelectOption обработчик события выбора варианта
 * @prop {(option: IOption) => JSX.Element} children
 * @prop {stringt} placeholder
 * @prop {boolean} isMulti признак мультивыбора
 * @prop {boolean} isSearchable Возможность поиска
 * @prop {string} iconName Наименование иконки
 * @prop {boolean} isError признак ошибки ввода
 * @prop {(string | object)[]} extraClassOptions дополнительный стиль вариантов селекта
 * @prop {string} label
 * @prop {boolean} required является ли обязательным для заполнения
 * @prop {string} actionText текст подсказки при обязательном заполнении поля
 * @prop {object as MuiSxProps} [extraSX] Кастомные стили на на Select
 */
interface ISelectProps {
  id?: string;
  name?: string;
  options: IOption[];
  selected: IOption[];
  disabled?: boolean;
  onOpen?: () => void;
  onSelectOption: (selected: IOption[]) => void;
  children?: (option: IOption) => JSX.Element;
  placeholder?: string;
  isMulti?: boolean;
  isSearchable?: boolean;
  iconName?: string;
  isError?: boolean;
  extraClassWrapper?: (string | object)[];
  extraClassOptions?: (string | object)[];
  label?: string;
  required?: boolean;
  actionText?: string;
  onPaginationBottom?: () => void;
  onSearch?: (query: string) => void;
  isPending?: boolean;
  extraSX?: { [key: string]: string };
}

const Select: React.FC<ISelectProps> = ({
  id,
  name,
  options: propOptions = [],
  selected = [],
  onOpen,
  onSelectOption = (): void => {},
  placeholder = "Не указано",
  isMulti = false,
  isError = false,
  disabled = false,
  actionText,
  extraSX
}) => {
  const ref = useRef(null);
  const value2Option = propOptions.reduce<Record<IOption["value"], IOption>>(
    (values, option) => ({ ...values, [option.value]: option }),
    {}
  );

  const selectedValue2SelectedOption = selected.reduce<
    Record<IOption["value"], IOption>
  >((values, selected) => ({ ...values, [selected.value]: selected }), {});

  const options = isMulti
    ? propOptions.filter(
        (option) => !selectedValue2SelectedOption[option.value]
      )
    : propOptions;

  const value = isMulti
    ? selected.map(({ value }) => value)
    : selected[0]?.value;

  const handleChange = (
    e: SelectChangeEvent<string | number | (string | number)[]>
  ) => {
    if (Array.isArray(e.target.value)) {
      const newValue = e.target.value.map(
        (optionValue) => value2Option[optionValue]
      );
      onSelectOption(newValue);
    } else {
      onSelectOption([value2Option[e.target.value]]);
    }
  };

  const handleDeleteChip = (value: IOption["value"]) => {
    onSelectOption(selected.filter((s) => s.value !== value));
  };

  return (
    <Box display="flex" flexDirection="column">
      <MuiSelect
        ref={ref}
        id={id}
        name={name}
        fullWidth
        displayEmpty
        error={isError}
        multiple={isMulti}
        onOpen={onOpen}
        value={value}
        onChange={handleChange}
        variant="standard"
        disabled={disabled}
        sx={[extraSX]}
        renderValue={(value) => {
          if (Array.isArray(value)) {
            return value.map((v) => (
              <Chip
                key={v}
                onMouseDown={(e) => e.stopPropagation()}
                label={value2Option[String(v)]?.label}
                onDelete={() => handleDeleteChip(v)}
              />
            ));
          }

          return <>{value2Option[String(value)]?.label}</>;
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </MuiSelect>

      {actionText && (
        <Box display="flex" alignItems="center">
          {isError && (
            <Icon width="24px" height="28px" color="red" name="error" />
          )}

          <Box sx={{ color: isError ? "error.dark" : "text.primary" }}>
            {actionText}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default memo(Select);
