import { takeEvery, put, select } from "redux-saga/effects";
import { GET_CATALOGS, CHANGE_CATEGORIES_PAGE } from "../Consts";

import {
  getRepliesCategories,
  getRegions
} from "../../Dictionaries/dictionaries.actions";

function* getAllData(action) {
  yield put(getRepliesCategories(0));

  yield put(getRegions());
}

function* nextTemplateModalCategoryPage(action) {
  const currentPage = yield select(
    ({ RepliesTemplateEditModal }) =>
      RepliesTemplateEditModal.templateModalCategoryPage
  );

  yield put(getRepliesCategories(currentPage));
}

export const getAllDataSaga = function*() {
  yield takeEvery([GET_CATALOGS], getAllData);
};

export const nextTemplateModalCategoryPageSaga = function*() {
  yield takeEvery([CHANGE_CATEGORIES_PAGE], nextTemplateModalCategoryPage);
};
