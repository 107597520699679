// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7UicP8ZuIv7hTLT5s5Gp{width:550px}.INRVSTr70YDiZsdXnJTC{padding-bottom:20px;border-bottom:1px solid rgba(0,0,0,.15);color:#2a323f;font-size:18px;font-weight:700}.yiJbZZH49oXThPEMA2jm{padding:20px 0}.yiJbZZH49oXThPEMA2jm>p{font-size:16px;color:#000}.HGEo7KwrrygUOISLbuZM{display:flex;justify-content:center}.UjdmQaXVJgta1bU61nEc{margin:0 20px}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/Common/Components/ConfirmModal/ConfirmModal.module.scss"],"names":[],"mappings":"AAAA,uBACE,WAAA,CAGF,sBACE,mBAAA,CACA,uCAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CAGF,sBACE,cAAA,CAEA,wBACE,cAAA,CACA,UAAA,CAIJ,sBACE,YAAA,CACA,sBAAA,CAGF,sBACE,aAAA","sourcesContent":[".modal {\n  width: 550px;\n}\n\n.header {\n  padding-bottom: 20px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.15);\n  color: #2a323f;\n  font-size: 18px;\n  font-weight: 700;\n}\n\n.description {\n  padding: 20px 0;\n\n  > p {\n    font-size: 16px;\n    color: #000;\n  }\n}\n\n.buttons {\n  display: flex;\n  justify-content: center;\n}\n\n.button {\n  margin: 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export var modal = "_7UicP8ZuIv7hTLT5s5Gp";
export var header = "INRVSTr70YDiZsdXnJTC";
export var description = "yiJbZZH49oXThPEMA2jm";
export var buttons = "HGEo7KwrrygUOISLbuZM";
export var button = "UjdmQaXVJgta1bU61nEc";
export default ___CSS_LOADER_EXPORT___;
