import produce from "immer";

import {
  TOGGLE_FILTER_PANEL,
  CHANGE_FILTER_PANEL_DATA,
  FILL_FILTERS_DICTIONARIES,
  DASHBOARD_SET_CONFIRM_IN_PROGRESS
} from "./consts";

import initialState from "../../ReactCore/store/initialState";

export default function DashboardFiltersReducer(
  state = initialState.DashboardFilters,
  { type, payload }
) {
  switch (type) {
    case TOGGLE_FILTER_PANEL:
      return produce(state, draft => {
        draft.status.openedFiltersPanel = !draft.status.openedFiltersPanel;
      });

    case CHANGE_FILTER_PANEL_DATA:
      return produce(state, draft => {
        draft.formData = { ...draft.formData, ...payload };
      });

    case FILL_FILTERS_DICTIONARIES:
      return produce(state, draft => {
        draft.channelList = [...draft.channelList, ...payload.channel];
        draft.regionList = [...draft.regionList, ...payload.region];
        draft.mrfList = [...draft.mrfList, ...payload.mrf];
        draft.rfList = [...draft.rfList, ...payload.rf];
      });

    case DASHBOARD_SET_CONFIRM_IN_PROGRESS:
      return produce(state, draft => {
        draft.confirmInProgress = payload;
      });

    default:
      return state;
  }
}
