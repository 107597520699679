import React, { FC, memo } from "react";
import c from "classnames";
import { ScrollBox, Icon, isEmpty } from "@omnichat/arm_ui_kit";

import { Button } from "../../../../Components";
import * as s from "./styles.module.scss";
import Bot from "./Bot";

import { ChatbotData } from "../../Chatbots";

const emptyBotList = "/www/img/msg_chatbot_empty.png";

interface ChatBotProps {
  bots?: ChatbotData[];
  onCreateBot?: () => void;
  onBotClick?: (id: string) => void;
}

const ChatbotList: FC<ChatBotProps> = ({ bots, onCreateBot, onBotClick }) => {
  const handleCreateBot = () => onCreateBot();

  const PlusBold: FC = () => (
    <Icon color="blue" name="plus_bold" width="1.2em" height="1.2em" />
  );

  return (
    <>
      <div className={s["settingsHeaderWrap"]}>
        <div className={c(s["settingsHeader"])}>Управление чат-ботами</div>
      </div>
      <div className={s["listContainer"]}>
        <div className={s["listSettingsBots"]}>
          <div className={s["listSettingsBorder"]} />
          <div className={s["listOuterWrap"]}>
            <div className={s["listInnerWrap"]}>
              {isEmpty(bots) && (
                <div className={s["listBotEmpty"]}>
                  <img src={emptyBotList} />
                  <div>Здесь появятся добавленные чат-боты</div>
                  <Button
                    id="createFirstBot"
                    onClick={handleCreateBot}
                    type="bare"
                    theme="blue"
                    iconPosition="right"
                    IconCustom={PlusBold}
                    text="Добавить чат-бота"
                    extraClass={[c(s["btnAddBot"], s["btnEmptyList"])]}
                  />
                </div>
              )}

              {!isEmpty(bots) && (
                <>
                  <div className={s["btnWrap"]}>
                    <div className={s["btnManage"]}>
                      <Button
                        id="createNewBot"
                        onClick={handleCreateBot}
                        type="bare"
                        theme="blue"
                        iconPosition="right"
                        IconCustom={PlusBold}
                        text="Добавить чат-бота"
                        extraClass={[s["btnAddBot"]]}
                      />
                    </div>
                  </div>

                  <div className={s["listBots"]}>
                    <ScrollBox autoHeight autoHeightMax={600}>
                      <div className={s["scrollContentPadding"]}>
                        {bots.map((bot, index) => (
                          <Bot
                            key={`${index}_${bot.id}`}
                            bot={bot}
                            onBotClick={onBotClick}
                          />
                        ))}
                      </div>
                    </ScrollBox>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ChatbotList);
