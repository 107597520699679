import * as React from "react";
import * as ReactDom from "react-dom";
import { Provider } from "react-redux";
import { store } from "../../ReactCore";
import { changeFilterPanelData } from "../../ReactFeatures/DashboardFilters/dashboardFilters.action";
import { dateToFormatString, stringToDate } from "./Utils";
import {
  DEFAULT_LOWER_TIME_LIMIT,
  DEFAULT_UPPER_TIME_LIMIT
} from "@omnichat/arm_ui_kit";
import { decodeAndReplaceURI } from "../../ReactFeatures/Common/Utils/Text.utils";
import Modal from "../Wrappers/Modal";
import { AppealInfoModalProvider } from "../../ReactFeatures/AppealInfoModal";

import {
  convertToMoscowTimestamp,
  createDateFromUTCTimestamp,
  toUnixTimeStamp
} from "../../ReactFeatures/Common/Utils/DateAndTime.utils";

const REACT_ROOT_FILTER_SELECTOR = "#reactRoot_stats_filters_form";

const DashboardFilters_init = () => {
  const selector = document.querySelector(REACT_ROOT_FILTER_SELECTOR);
  import("../../ReactFeatures/DashboardFilters").then((Module) => {
    window.imageViewer.init();
    const DashboardFiltersProvider = Module.DashboardFiltersProvider;

    ReactDom.render(
      <Provider store={store}>
        <DashboardFiltersProvider
          preSelectedData={getFiltersSelectedData()}
          onAppealChange={(appealId) => {
            const modalId = "appealInfoModal";
            const modal = new Modal(modalId, 980, (close) => (
              <AppealInfoModalProvider
                filesUrl={core.fileStorage}
                appealId={appealId}
                close={close}
              />
            ));
            modal.open();
          }}
          onChange={(formData) => {
            if (msg.tab.locked) {
              return;
            }
            let queryString = mapFormDataWithUrlParams(
              formData,
              core.nav.url.params
            );

            // TODO Почему-то при попадании этого парметра в url обращения не отображаются
            delete queryString.page;

            let state = $("input[name=state]").val();
            let url = `/msg?sec=stats&state=${state}${prepareQueryString(
              queryString
            )}`;
            core.nav.do({
              url: url
            });
          }}
        />
      </Provider>,
      selector
    );
  });
};

const DashboardFilters_changeData = function () {
  store.dispatch(changeFilterPanelData(getFiltersSelectedData()));
};

const DashboardFilters_destroy = function () {
  const selector = document.querySelector(REACT_ROOT_FILTER_SELECTOR);
  ReactDom.unmountComponentAtNode(selector);
};

const mapFormDataWithUrlParams = (form, params) => {
  return {
    ...params,
    date_start: form.date_start,
    date_end: form.date_end,
    client: form.client,
    appeal: form.appeal,
    channel: form.channel,
    who_processed: form.who_processed,
    status: form.status,
    region_id: form.region,
    mrf_code: form.mrf,
    rf_id: form.rf,
    sl: form.sl,
    csi: form.csi,
    remark_fio: form.remark_fio,
    remark_phone: form.remark_phone,
    remark_birthday: form.remark_birthday,
    without_themes: form.without_themes
  };
};

const prepareQueryString = (data) => {
  let queryString = "";

  function prepareDate(date: Date, defaultLimit: string) {
    let result: string | number = "";

    if (date) {
      const timestamp = convertToMoscowTimestamp(date);

      result = toUnixTimeStamp(timestamp, defaultLimit);
    }

    return result;
  }

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (key === "remark_birthday") {
        queryString +=
          "&" + key + "=" + (data[key] ? dateToFormatString(data[key]) : "");
      } else if (key === "date_start") {
        const date = prepareDate(data[key], DEFAULT_UPPER_TIME_LIMIT);

        queryString += "&" + key + "=" + date;
      } else if (key === "date_end") {
        const date = prepareDate(data[key], DEFAULT_LOWER_TIME_LIMIT);

        queryString += "&" + key + "=" + date;
      } else if (key === "appeal") {
      } else if (key === "tpls") {
      } else if (key === "remark_phone" && data[key]) {
        const result = data[key].length > 1 ? data[key] : "";

        queryString += "&" + key + "=" + result;
      } else if (key === "without_themes" && data[key]) {
        queryString += "&" + key + "=" + 1;
      } else if (data[key]) {
        queryString += "&" + key + "=" + data[key];
      }
    }
  }

  return queryString;
};

interface ISelectedData {
  date_start: Date;
  date_end: Date;
  channel: string;
  status: string;
  csi: string;
  sl: string;
  region: string;
  mrf: string;
  rf: string;
  who_processed: string;
  client: string;
  without_themes: boolean;
  remark_fio: string;
  remark_phone: string;
  remark_birthday: Date | false | "";
}

const getFiltersSelectedData = (): ISelectedData => {
  let filtersSelectedData = {} as ISelectedData;

  function prepareDate(date: string, defaultLimit: string) {
    return createDateFromUTCTimestamp(+date, defaultLimit);
  }

  if (core.nav.url.params.date_start) {
    filtersSelectedData.date_start = prepareDate(
      core.nav.url.params.date_start,
      DEFAULT_UPPER_TIME_LIMIT
    );
  }

  if (core.nav.url.params.date_end) {
    filtersSelectedData.date_end = prepareDate(
      core.nav.url.params.date_end,
      DEFAULT_LOWER_TIME_LIMIT
    );
  }

  if (core.nav.url.params.channel) {
    filtersSelectedData.channel = core.nav.url.params.channel;
  }

  if (core.nav.url.params.status) {
    filtersSelectedData.status = core.nav.url.params.status;
  }

  if (core.nav.url.params.csi) {
    filtersSelectedData.csi = core.nav.url.params.csi;
  }

  if (core.nav.url.params.sl) {
    filtersSelectedData.sl = core.nav.url.params.sl;
  }

  if (core.nav.url.params.region_id) {
    filtersSelectedData.region = core.nav.url.params.region_id;
  }

  if (core.nav.url.params.mrf_code) {
    filtersSelectedData.mrf = core.nav.url.params.mrf_code;
  }

  if (core.nav.url.params.rf) {
    filtersSelectedData.rf = core.nav.url.params.rf;
  }

  if (core.nav.url.params.who_processed) {
    filtersSelectedData.who_processed = core.nav.url.params.who_processed;
  }

  if (core.nav.url.params.client) {
    const {
      params: { client }
    } = core.nav.getUrl(null, "object", true);
    let convertedClient = decodeAndReplaceURI(client, /\+/g, " ");

    filtersSelectedData.client = convertedClient;
  }

  if (Number(WAITING_CONTROL_FILTER)) {
    filtersSelectedData.without_themes =
      core.nav.url.params.without_themes || false;
  }

  if (ADDITIONAL_CLIENTS_PROPS_FOR_CHANNEL) {
    const {
      params: { remark_fio }
    } = core.nav.getUrl(null, "object", true);
    let convertedFIO = decodeAndReplaceURI(remark_fio, /\+/g, " ");

    filtersSelectedData.remark_fio = convertedFIO || "";
    filtersSelectedData.remark_phone = core.nav.url.params.remark_phone || "";
    filtersSelectedData.remark_birthday = core.nav.url.params.remark_birthday
      ? stringToDate(core.nav.url.params.remark_birthday)
      : "";
  }

  return filtersSelectedData;
};

export {
  DashboardFilters_init,
  DashboardFilters_changeData,
  DashboardFilters_destroy
};
