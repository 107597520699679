import produce from "immer";

import initialState from "../../ReactCore/store/initialState";
import {
  SET_REPLIES_CATEGORIES,
  ADD_REPLIES_CATEGORIES,
  LOADED_REGIONS,
  LOADED_MRFS,
  LOADED_RFS,
  GET_REGIONS,
  LOADED_TRANSFER_DICTIONARY,
  CLEAN_TRANSFER_DICTIONARY
} from "./Consts";

export default function dataReducer(
  state = initialState.Dictionaries,
  { type, payload }
) {
  switch (type) {
    case SET_REPLIES_CATEGORIES:
      return produce(state, draft => {
        draft.categories = payload;
      });

    case ADD_REPLIES_CATEGORIES:
      return produce(state, draft => {
        draft.categories = [...draft.categories, ...payload];
      });

    case LOADED_REGIONS:
      return produce(state, draft => {
        draft.regions = payload;
      });

    case LOADED_RFS:
      return produce(state, draft => {
        draft.rfs = payload;
      });

    case LOADED_MRFS:
      return produce(state, draft => {
        draft.mrfs = payload;
      });

    case GET_REGIONS:
      return produce(state, draft => {
        draft.mrfs = payload;
      });

    case LOADED_TRANSFER_DICTIONARY:
      return produce(state, draft => {
        draft.moveAppealList = [...draft.moveAppealList, ...payload];
      });

    case CLEAN_TRANSFER_DICTIONARY:
      return produce(state, draft => {
        draft.moveAppealList = [];
      });

    default:
      return state;
  }
}
