// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g8VaPoRdXoLsF3m0JHcZ{text-transform:uppercase;font-size:16px;color:var(--color-text-hints);padding:0;margin:0 0 2px}.q_ZeCIVK2ecMt3EftYBO{padding:0;margin:0 0 8px;font-size:16px;font-weight:bolder;color:var(--color-text-default)}", "",{"version":3,"sources":["webpack://./frontend/src/Settings/VipClients/Components/VipClient.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,cAAA,CACA,6BAAA,CACA,SAAA,CACA,cAAA,CAGF,sBACE,SAAA,CACA,cAAA,CACA,cAAA,CACA,kBAAA,CACA,+BAAA","sourcesContent":[".title {\n  text-transform: uppercase;\n  font-size: 16px;\n  color: var(--color-text-hints);\n  padding: 0;\n  margin: 0 0 2px;\n}\n\n.label {\n  padding: 0;\n  margin: 0 0 8px;\n  font-size: 16px;\n  font-weight: bolder;\n  color: var(--color-text-default);\n}\n"],"sourceRoot":""}]);
// Exports
export var title = "g8VaPoRdXoLsF3m0JHcZ";
export var label = "q_ZeCIVK2ecMt3EftYBO";
export default ___CSS_LOADER_EXPORT___;
