import {
  Template,
  Validation
} from "../../../ClassifierFieldForm/ClassifierFieldForm";

export enum FieldTypes {
  DROPDOWN = "DROPDOWN",
  INPUT = "INPUT",
  TEXTAREA = "TEXTAREA",
  RADIO = "RADIO",
  CHECKBOX = "CHECKBOX"
}

/**
 * Модель данных
 */
export interface IThematicsModalProps {
  serviceType: boolean;
  errorMessage: string;
  activeService: number;
  regions: Region[];
  selectedRegions?: ISelectedRegion;
  thematics: ITheme[];
  themesFields: ThemesFields[];
  onFieldsChange: (selected: ISelectedFieldValue[]) => void;
  onServiceChange: (id: number) => void;
  onRegionChange: (id: number) => void;
  onSaveFields: () => void;
  cancelEdit: () => void;
  editItem: number;
  onEditThematic: (id: number) => void;
  onDeleteThematic: (id: number) => void;
  onSend: () => void;
}

/**
 * Модель данных
 */
export interface IServiceTypeProps {
  activeService: number;
  onServiceChange: (id: number) => void;
  onRegionChange: (id: number) => void;
  regions: Region[];
  selected?: ISelectedRegion;
}

interface Region {
  value: number;
  label: string;
}
interface ISelectedRegion {
  value: number;
  error: boolean;
}

/**
 * Модель данных
 */
export interface IThematicPickerProps {
  themesFields: ThemesFields[];
  onChange: (val: any) => void;
  onSave?: () => void;
}

export interface ISelectedFieldValue {
  id: number;
  value: number;
}

export interface ThemesFields {
  id: number;
  name: string;
  description?: string;
  value: boolean | string | number | null;
  error: boolean;
  options?: Field[];
  fieldType: FieldTypes;
  templates?: Template[];
  validations?: Validation[];
}

interface Field {
  label: string;
  value: number | string;
}

/**
 * Модель данных
 */
export interface IThematicPickedListProps {
  errorMessage: string;
  thematics: ITheme[];
  editItem: number;
  cancelEdit: () => void;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

interface ITheme {
  id: number;
  text: string;
  saved: boolean;
  canEdit: boolean;
}
