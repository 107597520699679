import { createAction } from "@reduxjs/toolkit";

import {
  CONNECT,
  CONNECTION_SUCCESS,
  CONNECTION_ERROR,
  DISCONNECT,
  DISCONNECT_SUCCESS,
  WS_RECEIVED,
  WS_SEND
} from "./Consts";

export const connectWs = createAction(CONNECT, (data) => ({
  payload: data
}));

export const connectionSuccess = createAction(CONNECTION_SUCCESS, (data) => ({
  payload: data
}));

export const connectionError = createAction(CONNECTION_ERROR, (data) => ({
  payload: data
}));

export const disconnectWs = createAction(DISCONNECT, (data) => ({
  payload: data
}));

export const disconnectSuccess = createAction(DISCONNECT_SUCCESS);

export const incomingEvent = createAction(WS_RECEIVED, (data) => ({
  payload: data
}));

export const sendWs = createAction(WS_SEND, (data) => ({
  payload: data
}));
