import * as React from "react";
import * as ReactDom from "react-dom";
import { Provider } from "react-redux";

import { store, httpRequest } from "../../ReactCore";
import Informer from "../Wrappers/Informer.js";

import { deactivateUsers } from "../../ReactFeatures/UsersMultiProfile/usersMultiProfile.action";
import ThemeProvider from "../../Components/ThemeProvider";

export function usersSettings() {
  const checkedIds = Array.from(msg.settings.groups.users).filter(
    (el) => el !== undefined
  );
  const modalId = `UsersMultiProfileModal`;

  if (checkedIds.length > 0) {
    import("../../ReactFeatures/UsersMultiProfile").then((Module) => {
      const UsersMultiProfileModalProvider = Module.default;

      msg.renderModalForReact(modalId, 590, (root) => {
        const close = () => {
          $(`#reactRoot_${modalId}`)
            .parent()
            // @ts-ignore
            .modal("close");

          const informer = new Informer("Данные успешно обновлены", 3000);

          informer.show();
        };

        ReactDom.render(
          <Provider store={store}>
            <UsersMultiProfileModalProvider
              checkedIds={checkedIds}
              close={close}
            />
          </Provider>,
          root
        );
      });
    });
  }
}

export function userAdd(profileId) {
  event.stopPropagation();

  import("../../ReactFeatures/UsersProfile").then((Module) => {
    const UsersProfileModalProvider = Module.default;
    const modalId = `UsersProfileModal`;

    msg.renderModalForReact(modalId, 592, (root) => {
      const close = () => {
        $(`#reactRoot_${modalId}`)
          .parent()
          // @ts-ignore
          .modal("close");
        location.reload();
      };

      ReactDom.render(
        <ThemeProvider>
          <Provider store={store}>
            <UsersProfileModalProvider
              administratorId={core.user.id}
              profileId={profileId}
              close={close}
              restorePassword={() => restorePassword([+profileId])}
            />
          </Provider>
        </ThemeProvider>,
        root
      );
    });
  });
}

export function restorePassword(users) {
  import("../../ReactFeatures/RestorePassword").then((Module) => {
    const RestorePasswordProvider = Module.default;
    const modalId = `restorePasswordModal`;

    msg.renderModalForReact(modalId, 592, (root) => {
      const close = () => {
        $(`#reactRoot_${modalId}`)
          .parent()
          // @ts-ignore
          .modal("close");
      };

      ReactDom.render(
        <Provider store={store}>
          <RestorePasswordProvider users={users} close={() => close()} />
        </Provider>,
        root
      );
    });
  });
}

export const showInformerAfterReloadKey = "showInformerAfterReload";

export function skillAdd(event, el, project_id, skill_id) {
  event.stopPropagation();

  import("../../ReactFeatures/SkillGroupModal").then((Module) => {
    const SkillGroupModalProvider = Module.default;
    const modalId = "SkillGroupModal";

    msg.renderModalForReact(modalId, 590, (root) => {
      const handleSave = (data) => {
        msg.closeCurrentModal();
        window.sessionStorage.setItem(showInformerAfterReloadKey, "true");
        location.reload();
      };

      const handleDelete = () => {
        msg.settings.confirm_modal(el, skill_id, 0, "groups", "skill");
      };

      ReactDom.render(
        <Provider store={store}>
          <SkillGroupModalProvider
            onSave={handleSave}
            editMode={skill_id > 0}
            onDelete={handleDelete}
            skillId={skill_id}
          />
        </Provider>,
        root
      );
    });
  });
}

export function groupGo(el) {
  $(".groups_skills_list .sel").removeClass("sel");
  el.addClass("sel");
}

export function groupsCount() {
  var list = msg.settings.groups.userEditor_modal.find(
    ".statsAgentSettings_list"
  );
  if (list.find(".statsAgentSettings_itemWrap").length == 1) {
    list.find(".statsAgentSettings_itemWrap").addClass("singleGroup");
  } else {
    list.find(".statsAgentSettings_itemWrap").removeClass("singleGroup");
  }
}

export function groupsShow() {
  if (
    msg.settings.groups.userEditor_modal.skill_check.is(":checked") === true
  ) {
    msg.settings.groups.userEditor_modal.skill_list
      .find(".statsAgentSettings_itemWrap")
      .remove();
  } else {
    msg.agent_set.skill_add();
  }
}

const skillGroupsErrorMessages = {
  vip: "В справочнике vip клиентов есть записи. Нельзя удалить группу.",
  users: "Необходимо удалить всех участников группы."
};

export function skillDelete(el, skill_id) {
  httpRequest("DELETE", `bff/settings/skill-groups/${skill_id}/delete`).then(
    (responseData) => {
      msg.closeCurrentModal();
      if (responseData.success) {
        // core.informer.show(msg.lng.settings.changeSuccess, 1500);
        location.reload();
      } else {
        if (responseData.status === 400) {
          const message = skillGroupsErrorMessages[responseData.error];

          if (message) {
            core.informer.show(message, 1500);

            core.informer.el.css({
              left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
            });
          }
        } else {
          core.informer.show(msg.lng.settings.changeError, 1500);
          core.informer.el.css({
            left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
          });
        }
      }
    }
  );
}

export function userCheckAll(el) {
  if (msg.settings.groups.users_marked == false) {
    var elems = $(".groups_usersList").find("input[type=checkbox]"),
      state = el.prop("checked");

    elems.each(function (i, checkbox) {
      $(checkbox).prop("checked", state).trigger("change");
    });
  }
}

export function userCheck(el, user_id) {
  msg.settings.groups.users[user_id] = user_id;
  var actions = $(".groups_usersList_action");

  if (el.prop("checked") == false) {
    delete msg.settings.groups.users[user_id];
    actions.find("input[type=checkbox]").prop("checked", false);
    actions.removeClass("marked");
    actions.find("i").hide();
  }

  // DELAY !!!
  var users = $(".groups_usersList"),
    marked = users.hasClass("marked"),
    count = Object.keys(msg.settings.groups.users).length;

  if (marked && !count) {
    users.removeClass("marked");
    actions.removeClass("marked");
    actions.find("i").hide();
  } else if (!marked && count) {
    actions.addClass("marked");
    actions.find("i").show();
  }
  $(".groups_usersList_action")
    .find("i")
    .html(": " + count);
}

export function userClick(el) {
  var checkbox = el.find("input[type=checkbox]");

  if (checkbox.prop("checked")) checkbox.prop("checked", false);
  else checkbox.prop("checked", true);

  checkbox.trigger("change");
}

export function userShow(el, project_id, skill_id) {
  var count = Object.keys(msg.settings.groups.users).length;
  if (count == 0) {
    return false;
  } else {
    if (el.hasClass("sel")) {
      el.removeClass("sel");

      $(".groups_userWrap").each(function () {
        $(this).show();
      });

      msg.settings.groups.users_marked = false;
    } else {
      el.addClass("sel");

      $(".groups_userWrap").each(function () {
        const userId = $(this).data("user-id");
        if (!msg.settings.groups.users[userId]) {
          $(this).hide();
        }
      });

      msg.settings.groups.users_marked = true;
    }
  }
}

export function usersDelete(el, skillGroupId) {
  var count = Object.keys(msg.settings.groups.users).length;

  if (count == 0 || el.hasClass("load")) {
    return false;
  }

  var isAll = $("[name=all_users]").prop("checked");

  var secHeader = $(".groups_usersList_action");

  if (secHeader.hasClass("marked")) {
    const refreshPage = () => {
      var url =
        "/msg?sec=settings&project_id=" +
        core.nav.url.params.project_id +
        "&section=groups&skill_id=" +
        skillGroupId;

      core.nav.do({
        el: undefined,
        url: url
      });
    };

    const accessCb = () => {
      refreshPage();
      core.informer.show(msg.lng.settings.changeSuccess, 1500);
      core.informer.el.css({
        left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
      });
    };
    const errorCb = () => {
      refreshPage();
      core.informer.show(msg.lng.defaultRequest, 1500);
      core.informer.el.css({
        left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
      });
    };

    let newUsersArray;

    if (isAll) {
      newUsersArray = [0];
    } else {
      newUsersArray = msg.settings.groups.users.reduce(function (acc, val) {
        acc.push(+val);
        return acc;
      }, []);
    }

    store.dispatch(
      deactivateUsers({
        skillGroupId,
        users: newUsersArray,
        isAll: isAll,
        accessCb: accessCb.bind(this),
        errorCb: errorCb.bind(this)
      })
    );
  }
}

export function dropDownShow(el) {
  var dropdown = $(".dropdown_wrap"),
    actions = $(".groups_usersList_action");

  if (actions.hasClass("marked") && !el.hasClass("sel")) {
    msg.settings.groups.dropdown_show_el = el;
    el.addClass("sel");
    dropdown.show();

    $(window).on({
      click: msg.settings.groups.dropdown_hide,
      resize: msg.settings.groups.dropdown_hide,
      scroll: msg.settings.groups.dropdown_hide
    });
  } else {
    $(window).click();
  }
}

export function dropDownHide(e) {
  var target = $(e.target),
    isDropdown = target.closest(".dropdown_wrap").length,
    isModal = target.closest(".modal-container").length;

  if (isDropdown || isModal) {
    return;
  }

  if (msg.settings.groups.dropdown_showed === true) {
    $(window).off("click", msg.settings.groups.dropdown_hide);
    $(window).off("resize", msg.settings.groups.dropdown_hide);
    $(window).off("scroll", msg.settings.groups.dropdown_hide);

    $(".dropdown_wrap").hide();
    msg.settings.groups.dropdown_show_el.removeClass("sel");

    if (e !== undefined) {
      core.tools.cancelBubble(e);
    }
    msg.settings.groups.dropdown_showed = false;
    return false;
  } else {
    msg.settings.groups.dropdown_showed = true;
  }
}

export function userMove(el, project_id, skill_id) {
  if (el.hasClass("load")) {
    return false;
  }
  el.addClass("load");

  var select = $("#msg_settingsGroupsList select"),
    skill_transfer_id = +select.val();

  if (!skill_transfer_id) {
    select.closest(".wsForm_field").addClass("fail");
    core.informer.show(msg.lng.settings.changeError, 1500);
    core.informer.el.css({
      left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
    });
    el.removeClass("load");
    return;
  }

  $.ajax({
    url: "/msg?sec=transfer_skill_users",
    data: {
      project_id: project_id,
      skill_transfer_id: skill_transfer_id,
      skill_id: skill_id,
      user_id: Object.keys(msg.settings.groups.users),
      tpls: {
        settings_groups_user_list: "window"
      }
    },
    dataType: "JSON",
    type: "POST",
    success: function (r) {
      // @ts-ignore
      $("#msg_settingsGroupsList").modal("close");

      var url =
        "/msg?sec=settings&project_id=" +
        core.nav.url.params.project_id +
        "&section=groups&skill_id=" +
        skill_id;

      core.nav.do({
        el: undefined,
        url: url
      });
      core.informer.show(msg.lng.settings.changeSuccess, 1500);
      core.informer.el.css({
        left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
      });
    }
  });
}

export function userMoveShowGroups(el, project_id, skill_id) {
  if (el.hasClass("load")) {
    return false;
  }
  el.addClass("load");

  $.ajax({
    url: "/msg?sec=settings",
    data: {
      project_id: project_id,
      skill_id: skill_id,
      tpls: {
        settings_groups_list: "window"
      }
    },
    dataType: "JSON",
    type: "GET",
    success: function (r) {
      core.draw.window({
        id: "settings_groups_list",
        body: r.tpls.window,
        close: {
          esc: false,
          overlay: false
        }
      });
      el.removeClass("load");
    }
  });
}

export function userSearch(project_id) {
  let list = $(".groups_users_list");
  let search = $("input[name=skill_user_search]").val();
  let skill;
  if (!core.nav.url.params.skill_id) skill = "all";
  else skill = core.nav.url.params.skill_id;

  if (msg.settings.groups.user_search_delay != undefined) {
    clearTimeout(msg.settings.groups.user_search_delay);
  }
  msg.settings.groups.user_search_delay = setTimeout(function () {
    $(".settings_groups_user_list").addClass("load");
    $.ajax({
      url: "/msg?sec=settings",
      data: {
        project_id: project_id || 1,
        skill_id: skill || "",
        search: search || "",
        tpls: {
          settings_groups_user_list: "list"
        }
      },
      dataType: "JSON",
      type: "GET",
      success: function (r) {
        if (/empty/.test(r.tpls.list)) {
          $(".groups_usersList_action .sync").removeClass("active");
        } else {
          $(".groups_usersList_action .sync").addClass("active");
        }

        $(".settings_groups_user_list").removeClass("load");

        list.html(r.tpls.list);
        // @ts-ignore
        $(".settings_groups_user_list_scroll").nanoScroller();

        setTimeout(function () {
          msg.pagination.reset($(".groups_users"));
        }, 100);
      }
    });
  }, 500);
}
