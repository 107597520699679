import * as React from "react";
import { connect } from "react-redux";
import { Preloader, isEmpty } from "@omnichat/arm_ui_kit";
import { AdditionalFields } from "../../AdditionalFields";
import Modal from "../../../arm/Wrappers/Modal";
import { StoreState } from "../../../ReactCore/store/types";
import * as appealInfoSelectors from "../AppealInfo.selectors";
import AppealInfoModal from "../Components/AppealInfoModal/AppealInfoModal";
import { cleanAppealData } from "../Reducers/AppealInfoModal.reducer";
import { getAppealData } from "../Actions/AppealInfoModal.actionCreators";
import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload
} from "@reduxjs/toolkit";
import { IDialogContext } from "../../DialogHeader/Components/DialogContextModal/DialogContextModal";
import { TransferAppealModal, ReopenAppealModal } from "../../MoveAppealModal/";
import ThematicInfoPanel from "../../ThematicModal/Providers/ThematicInfoPanel.provider";
import { CloseDialogModal } from "../../CloseDialogModal";
import MessagesBox from "../../MessageBox/Components/MessagesBox";
import openDialogContext from "../../../arm/Constructors/openDialogContext";

/**
 * Интерфейс собственных свойств компонента.
 *
 * @prop {string} filesUrl
 * @prop {number} appealId Идентификатор обращения.
 */
interface IOwnProps {
  filesUrl: string;
  appealId: number;
  close: Function;
}

export const UserRights = {
  closeAppeal: "appeal_close",
  transferAppeal: "appeal_transfer",
  reopenAppeal: "appeal_reopen"
};

enum ApealOpenStates {
  Closed,
  Opened,
  Waiting
}

enum BotStates {
  NotActive,
  Active,
  Queued,
  QueuedAndGreeted,
  TransferedToAgent,
  NotProcessed,
  Prompted
}

/**
 * Интерфейс свойств полученных из store.
 */
interface IStateProps {
  dialogId: number;
  appealData: any;
  clientId: number;
  channelId: number;
  channelType: number;
  remarkFio: string;
  remarkPhone: string;
  birthDay: any;
  contextData: IDialogContext;
}

/**
 * Интерфейс экшенов.
 */
interface IDispatchProps {
  cleanAppealData: ActionCreatorWithoutPayload;
  getAppealData: ActionCreatorWithPayload<
    { appealId: number; onError?: () => void },
    string
  >;
}

type TProps = IOwnProps & IStateProps & IDispatchProps;

class AppealInfoModalProvider extends React.PureComponent<TProps, {}> {
  componentDidMount() {
    const { appealData, appealId, getAppealData, close } = this.props;

    if (appealId && !appealData) {
      getAppealData({ appealId, onError: close });
    }
  }

  componentWillUnmount() {
    this.props.cleanAppealData();
  }

  /**
   * Обработчик отображения компонента для просмотра изображения.
   *
   * @param {string} imageSrc Ссылка на изображение.
   */
  handleShowImageViewer = (imageSrc: string) => {
    window.imageViewer?.handleShowImage(imageSrc, 115);
  };

  isClosedLastAppeal = (appealData) => {
    return appealData.isLast && appealData.isOpen == ApealOpenStates.Closed;
  };

  isOpenedAppealAndInActiveBot = (appealData) => {
    return (
      appealData.isOpen !== ApealOpenStates.Closed &&
      appealData.botState !== BotStates.Active
    );
  };
  reopenAppealHandler = (appealId) => {
    const closeAppealModal = this.props.close;
    const modalId = "TransferAppealModal";
    const modal = new Modal(modalId, 500, (close) => (
      <ReopenAppealModal
        close={() => {
          close();
          closeAppealModal();
        }}
        appealId={appealId}
      />
    ));
    modal.open();
  };
  transferAppealHandler = (appealId) => {
    const closeAppealModal = this.props.close;
    const modalId = "TransferAppealModal";
    const modal = new Modal(modalId, 500, (close) => (
      <TransferAppealModal
        close={() => {
          close();
          closeAppealModal();
        }}
        appealId={appealId}
      />
    ));
    modal.open();
  };
  closeAppealHandler = () => {
    const { appealId, dialogId } = this.props;
    const closeAppealModal = this.props.close;

    const modalId = "CloseDialogModal";
    const modal = new Modal(modalId, 650, (close) => (
      <CloseDialogModal
        isChat={false}
        activeDialog={dialogId}
        appealId={appealId}
        onClose={() => {
          close();
          closeAppealModal();
          location.reload();
        }}
        onCancel={() => close()}
      />
    ));
    modal.open();
  };

  dialogContextHandle = () => {
    openDialogContext(
      this.props.filesUrl,
      this.props.contextData,
      this.handleShowImageViewer
    );
  };

  render() {
    const { appealData, appealId, contextData } = this.props;

    const skufIncidentOption = SEND_INCIDENT_TO_SKUF === "1" ? true : false;

    return (
      <>
        <Preloader size="mini" show={!appealData}>
          {appealData && (
            <AppealInfoModal
              additionalClientFields={() => (
                <AdditionalFields
                  channelId={this.props.channelId}
                  channelType={this.props.channelType}
                  clientId={this.props.clientId}
                  remarkFio={this.props.remarkFio}
                  remarkPhone={this.props.remarkPhone}
                  birthDay={this.props.birthDay}
                />
              )}
              skufIncident={skufIncidentOption}
              open={true}
              appealData={appealData}
              filesUrl={this.props.filesUrl}
              avatarUrl={CROP_IMG_URL}
              thematicsConstructor={() => (
                <ThematicInfoPanel appealId={appealId} />
              )}
              onShowImageViewer={this.handleShowImageViewer}
              reopenAppealHandler={
                this.isClosedLastAppeal(appealData) &&
                core.userRights.includes(UserRights.reopenAppeal) &&
                this.reopenAppealHandler.bind(this, appealId)
              }
              transferAppealHandler={
                this.isOpenedAppealAndInActiveBot(appealData) &&
                core.userRights.includes(UserRights.transferAppeal) &&
                this.transferAppealHandler.bind(this, appealId)
              }
              closeAppealHandler={
                this.isOpenedAppealAndInActiveBot(appealData) &&
                core.userRights.includes(UserRights.closeAppeal) &&
                this.closeAppealHandler.bind(this)
              }
              dialogContextHandle={
                !isEmpty(contextData) && this.dialogContextHandle
              }
              messagesBox={
                <MessagesBox
                  messages={appealData.messages}
                  filesUrl={this.props.filesUrl}
                  avatarUrl={CROP_IMG_URL}
                  onShowImageViewer={this.handleShowImageViewer}
                  isExpanded
                />
              }
            />
          )}
        </Preloader>
      </>
    );
  }
}

const mapStateToProps = (state: StoreState.State): IStateProps => ({
  appealData: appealInfoSelectors.getAppealData(state),
  dialogId: appealInfoSelectors.getDialogId(state),
  clientId: appealInfoSelectors.getClientId(state),
  channelId: appealInfoSelectors.getChannelId(state),
  channelType: appealInfoSelectors.getChannelTypeId(state),
  remarkFio: appealInfoSelectors.getClientRemarkFio(state),
  remarkPhone: appealInfoSelectors.getClientRemarkPhone(state),
  birthDay: appealInfoSelectors.getClientBirthDay(state),
  contextData: appealInfoSelectors.getContextData(state)
});

const mapDispatchToProps: IDispatchProps = {
  cleanAppealData,
  getAppealData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppealInfoModalProvider);
