import React from "react";

import {
  ThemeProvider as MUIThemeProvider,
  createTheme
} from "@mui/material/styles";

export enum Colors {
  white = "#fff",

  primaryBg = "#cee6f2",
  primaryText = "#73aecc",
  primaryAction = "#0090db",

  successAction = "#9fe49f",

  textHints = "#829199",
  textDefault = "#2b2b2b",

  tooltipBg = "#000000b3",
  tooltipArrow = "#fbfafab3",

  errorBg = "#f9ebea",
  errorText = "#d5afaa",
  errorAction = "#f6b5ac",
  errorActionText = "#fc2b32"
}

export enum Fonts {
  sizeDefault = "16px"
}

const theme = createTheme({
  palette: {
    primary: {
      extraLight: Colors.primaryBg,
      main: Colors.primaryAction,
      dark: Colors.primaryAction,
      light: Colors.primaryBg,
      contrastText: "#ffffff"
    },
    error: {
      extraLight: Colors.errorBg,
      light: Colors.errorText,
      main: Colors.errorAction,
      dark: Colors.errorActionText
    },
    success: {
      main: Colors.successAction
    },
    textColor: {
      light: Colors.textHints,
      main: Colors.textDefault
    },
    tooltip: {
      light: Colors.tooltipBg,
      main: Colors.tooltipArrow
    }
  },
  typography: {
    fontFamily: [
      "PT Sans",
      "Arial",
      "sans-serif",
      "Candara",
      "Segoe",
      "Segoe UI",
      "Optima"
    ].join(","),
    fontSize: 16
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: { marginLeft: 0 },
        label: {
          marginLeft: "5px",
          color: Colors.primaryAction,
          fontSize: Fonts.sizeDefault
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          display: "flex",
          flexFlow: "column nowrap",
          marginTop: "2px"
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          height: "22px",
          width: "36px",
          opacity: 1
        },
        switchBase: ({ ownerState }) => ({
          padding: 0,
          top: "2px",
          left: "3px",
          ...(ownerState.checked && {
            transform: "translateX(14px) !important"
          })
        }),
        track: ({ ownerState }) => ({
          width: "36px",
          height: "20px",
          background: "#f6b5ac",
          borderRadius: "10px",
          ...(ownerState.checked && {
            background: "#9fe49f !important",
            opacity: "1 !important"
          })
        }),
        thumb: {
          boxShadow: "none",
          height: "16px",
          width: "16px",
          background: "#fff"
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          width: "14px",
          height: "14px",
          color: "#bec2c4"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          lineHeight: 1,
          fontSize: Fonts.sizeDefault,

          ...(ownerState.selected ? { color: Colors.primaryAction } : {})
        })
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          background: Colors.primaryAction,
          color: Colors.white,
          height: "23px",
          fontSize: "16px",
          margin: "0px 2px",
          maxWidth: "200px"
        },
        deleteIcon: {
          color: "inherit",
          opacity: 0.8,

          "&:hover": {
            color: "inherit",
            opacity: 1
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: ({ ownerState }) => ({
          borderColor: Colors.primaryAction,
          fontSize: Fonts.sizeDefault,
          display: "flex",
          alignItems: "center",
          minHeight: 46,
          boxSizing: "border-box",

          ...(ownerState.multiple
            ? { padding: "8px" }
            : { padding: "8px 24px" }),

          ...(ownerState.error
            ? { backgroundColor: Colors.errorBg }
            : { backgroundColor: Colors.primaryBg })
        }),
        icon: ({ ownerState }) => ({
          left: 0,
          width: 24,
          height: 20,
          top: "calc(50% - 10px)",

          ...(ownerState.multiple ? { display: "none" } : {}),

          ...(ownerState.error
            ? { color: Colors.errorAction }
            : { color: Colors.primaryAction })
        })
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: ({ ownerState }) => ({
          borderColor: Colors.primaryAction,
          padding: "8px 24px",

          ...(ownerState.error
            ? { backgroundColor: Colors.errorBg }
            : { backgroundColor: Colors.primaryBg })
        }),
        endAdornment: {
          width: 28,
          left: 0
        },
        tag: {
          margin: "0px 2px"
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: Colors.tooltipBg,
          color: Colors.white,
          fontSize: Fonts.sizeDefault
        },
        arrow: {
          color: Colors.tooltipArrow
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          outline: "none",
          display: "block",
          padding: "10px 0 0",
          fontSize: Fonts.sizeDefault,
          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0
          }
        },
        input: ({ ownerState }) => ({
          boxSizing: "border-box",
          height: "100%",
          width: "100%",
          padding: "10px 8px",
          border: `2px solid ${Colors.primaryBg}`,
          background: Colors.primaryBg,

          "::placeholder": {
            color: Colors.primaryText,
            opacity: 1
          },

          ":focus, :active": {
            border: `2px solid ${Colors.primaryAction}`,
            background: Colors.white,
            color: Colors.textDefault
          },

          ...(ownerState.error && {
            border: `2px solid ${Colors.errorBg}`,
            background: Colors.errorBg,
            color: Colors.textDefault,

            "::placeholder": {
              color: Colors.errorText
            },

            ":focus, :active": {
              border: `2px solid ${Colors.errorAction}`,
              background: Colors.errorBg,
              color: Colors.textDefault
            }
          }),

          ...(ownerState.size === "small" && {
            padding: "6px"
          })
        })
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: "uppercase",
          fontWeight: "bolder",
          fontSize: "100%",
          marginBottom: "3.5px",
          lineHeight: "1.2em",
          color: Colors.textHints,
          transform: "none",

          ...(ownerState.required && {
            ".MuiFormLabel-asterisk": {
              color: Colors.errorActionText
            }
          })
        })
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: "18px",
          color: Colors.textHints,
          marginTop: "3px",
          fontSize: Fonts.sizeDefault,
          display: "flex",
          alignItems: "flex-end",
          span: {
            marginLeft: "5px"
          },
          svg: {
            width: "24px",
            height: "28px",
            color: Colors.errorActionText
          }
        }
      }
    }
  }
});

const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};

export default ThemeProvider;
