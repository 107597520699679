import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../ReactCore/shared/httpRequest";
import { GET_USERS_MULTI_PROFILE_CATALOGS } from "./consts";
import {
  setOrganizationsList,
  setSkillGroupList,
  setTerritoriesList,
  setAreasList
} from "./usersMultiProfile.action";

export function* getUsersMultiProfileCatalogsSaga() {
  try {
    const catalogs = yield httpRequest(
      "GET",
      "bff/settings/profiles/multi-profile-init"
    );

    if (catalogs?.areas?.success) {
      yield put(setAreasList(catalogs.areas.data));
    }

    if (catalogs?.organizations?.success) {
      yield put(setOrganizationsList(catalogs.organizations.data));
    }
    if (catalogs?.territories?.success) {
      yield put(setTerritoriesList(catalogs.territories.data));
    }
    if (catalogs?.skillGroups?.success) {
      yield put(setSkillGroupList(catalogs.skillGroups.data));
    }
  } catch ($error) {
    console.error($error);
  }
}

export default function* () {
  yield takeEvery(
    GET_USERS_MULTI_PROFILE_CATALOGS,
    getUsersMultiProfileCatalogsSaga
  );
}
