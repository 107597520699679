import { takeEvery, put, fork } from "redux-saga/effects";
import { httpRequest } from "../../ReactCore/shared/httpRequest";
import {
  channelsDataSetFullfilled,
  channelsDataSetRejected,
  channelsDataSetPending,
  getChannelsData
} from "./Channels.actions";

function* getAvailableData() {
  yield put(channelsDataSetPending());

  const response = yield httpRequest("GET", `bff/settings/channels`);

  if (response?.success) {
    let newData = response?.data ? [...response.data] : [];

    yield put(channelsDataSetFullfilled(newData));
  } else {
    yield put(channelsDataSetRejected(null));
  }
}

function* watchData() {
  yield takeEvery([getChannelsData], getAvailableData);
}

export function* ChannelsSettingsSaga() {
  yield fork(watchData);
}
