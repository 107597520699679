import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

import SettingsSection from "../../ReactFeatures/Common/Components/SettingsSection/SettingsSection";

import { StoreState } from "../../ReactCore/store/types";
import {
  getAllClassifiers,
  getThemesFields,
  getClassifierFields,
  getClassifierFieldValues,
  setSelectedThemesFieldsValues,
  resetFieldTree
} from "./Store/Classifiers.reducer";
import * as selectors from "./Store/Classifiers.selectors";

import { setActiveClassifierToUrl } from "./utils";

import ClassifierForm from "../../ReactFeatures/ClassifierForm";
import ClassifierFieldForm from "../../ReactFeatures/ClassifierFieldForm";
import ClassifierFieldValueForm from "../../ReactFeatures/ClassifierFieldValueForm";
import ClassifiersList from "./Components/ClassifiersList";
import ThematicsPickerDemo from "./Components/ThematicsPickerDemo";
import FieldsList from "./Components/FieldsList";

type IOwnProps = {};

type IProps = IOwnProps & ConnectedProps<typeof connector>;

const ClassifiersProvider: React.FC<IProps> = ({
  getAllClassifiers,
  getThemesFields,
  classifiers,
  themesFields,
  isThemeLoading,
  setSelectedThemesFieldsValues,
  getClassifierFields,
  classifierFields,
  resetFieldTree,
  getClassifierFieldValues
}) => {
  let [classifierId, setClassifierId] = useState<number>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    getAllClassifiers();
  }, []);

  const changeClassifier = classifierId => {
    if (!classifierId) {
      return;
    }
    getThemesFields(classifierId);
    setClassifierId(classifierId);
    setActiveClassifierToUrl(classifierId);
  };

  const preparedClassifiers = classifiers?.map(
    item =>
      ({
        ...item,
        current: +item.id === +classifierId,
        editable: true
      } || [])
  );

  const onThematicsPickerChange = data => setSelectedThemesFieldsValues(data);

  return (
    <SettingsSection
      title="Конструктор формы"
      menu={
        <Routes>
          <Route
            key="classifier-settings-1"
            path="/"
            element={
              <ClassifiersList
                loading={isThemeLoading}
                classifiersList={preparedClassifiers}
                onSelect={changeClassifier}
                onCreate={() => navigate("/new")}
                onEdit={id => navigate(`/${id}`)}
              />
            }
          />
          <Route
            key="classifier-settings-2"
            path="/:classifierId/*"
            element={
              <FieldsList
                fieldsList={classifierFields}
                onCollapseValues={getClassifierFieldValues}
                onCreateValue={(id, fieldId) =>
                  navigate(`${id}/field/${fieldId}/value`)
                }
                onCreateField={id => navigate(`${id}/field`)}
                onEditValue={(id, fieldId, valueId) =>
                  navigate(`${id}/field/${fieldId}/value/${valueId}`)
                }
                onEditField={(id, fieldId) =>
                  navigate(`${id}/field/${fieldId}`)
                }
                onClose={() => navigate("/")}
                updateFieldList={id => getClassifierFields(id)}
                resetFieldList={resetFieldTree}
              />
            }
          />
        </Routes>
      }
      content={
        <Routes>
          <Route
            key="classifier-settings-3"
            path="/"
            element={
              <ThematicsPickerDemo
                onChange={onThematicsPickerChange}
                loading={isThemeLoading}
                themesFields={themesFields}
              />
            }
          />
          <Route
            key="classifier-settings-4"
            path="/new"
            element={
              <ClassifierForm
                close={() => navigate("/")}
                updateClassifierList={getAllClassifiers}
              />
            }
          />
          <Route
            key="classifier-settings-4"
            path="/:classifierId"
            element={
              <ClassifierForm
                close={() => navigate("/")}
                updateClassifierList={getAllClassifiers}
              />
            }
          />
          <Route
            key="classifier-settings-6"
            path="/:classifierId/field/:fieldId?"
            element={
              <ClassifierFieldForm
                close={() => navigate("/")}
                updateFieldList={id => getClassifierFields(id)}
              />
            }
          />
          <Route
            key="classifier-settings-8"
            path="/:classifierId/field/:fieldId/value/:valueId?"
            element={
              <ClassifierFieldValueForm
                close={() => navigate("/")}
                updateValuesList={id => getClassifierFieldValues(id)}
              />
            }
          />
        </Routes>
      }
    />
  );
};

const mapStateToProps = (state: StoreState.State) => ({
  classifiers: selectors.getClassifiersList(state),
  classifierFields: selectors.getClassifierFields(state),
  themesFields: selectors.getComputedFields(state),
  isThemeLoading: selectors.getIsFieldLoading(state)
});

const mapDispatchToProps = dispatch => ({
  getAllClassifiers: () => dispatch(getAllClassifiers()),
  getThemesFields: classifierId => dispatch(getThemesFields(classifierId)),
  getClassifierFields: classifierId =>
    dispatch(getClassifierFields(classifierId)),
  resetFieldTree: () => dispatch(resetFieldTree()),
  getClassifierFieldValues: field => dispatch(getClassifierFieldValues(field)),
  setSelectedThemesFieldsValues: selectedValues =>
    dispatch(setSelectedThemesFieldsValues(selectedValues))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ClassifiersProvider);
