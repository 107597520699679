import { takeEvery, put } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { isoIntervalToTimestamp } from "../../../ReactCore/shared/dataFormats";
import Informer from "../../../arm/Wrappers/Informer";
import { getAppealData } from "../Actions/AppealInfoModal.actionCreators";
import { setAppealData } from "../Reducers/AppealInfoModal.reducer";
import { getMappedContextMessages } from "../../../ReactCore/shared/mapContextMessages";

function* getAppealDataSaga(action) {
  const { appealId, onError } = action.payload;

  try {
    const response = yield httpRequest(
      "GET",
      `bff/chat/appeals/${appealId}/info`,
      {
        query: {
          messages: true
        }
      }
    );

    if (response?.success) {
      if (response.data.name && /error/i.test(response.data.name)) {
        let textMessage = "Неизвестная ошибка";

        if (response.data.message && /404/.test(response.data.message)) {
          textMessage = "Обращение не найдено";
        }

        const informer = new Informer(textMessage, 3000);
        informer.show();
        onError && onError();
        return;
      }

      response.data.operationTime = isoIntervalToTimestamp(
        response.data.operationTime
      );

      if (typeof response.data?.client?.phone === "string") {
        response.data.client.phone = Number(
          response.data.client.phone.replace(/[^0-9]*/g, "")
        );
      }

      if (response.data?.context?.dialog) {
        response.data.context.clientRole =
          response.data.context.dialog?.client?.userData?.role;
        response.data.context.messages = getMappedContextMessages(
          response.data.context.dialog,
          appealId
        );
      }

      yield put(setAppealData(response.data));
    }
  } catch (error) {
    onError && onError();
    console.error("*** GetAppealDataError ***", error);
  }
}

export default function* () {
  yield takeEvery([getAppealData], getAppealDataSaga);
}
