import React, { FC, useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { Preloader, ScrollBox, Button } from "@omnichat/arm_ui_kit";
import SubTitle from "../SkillGroupModal/Components/SubTitle/SubTitle";
import { StoreState } from "../../ReactCore/store/types";
import {
  getFieldData,
  getTemplateData,
  getValidateData,
  removeField,
  sendFieldData,
  resetFieldData,
  setLinkData,
  sendTemplateData,
  sendValidateData,
  removeTemplate,
  removeValidate,
} from "./Store/ClassifierFieldForm.reducer";

import * as selectors from "./Store/ClassifierFieldForm.selector";

import ModalWindow from "../../Components/ModalWindow";
import { Data } from "./ClassifierFieldForm.d";
import ClassifierLinkForm from "../ClassifierLinkForm";
import ClassifierFieldForm from "./Components/ClassifierFieldForm";

import * as s from "./style.module.scss";

type IOwnProps = {
  close;
  updateFieldList: (id: string) => null;
};

type IProps = IOwnProps & ConnectedProps<typeof connector>;

const ClassifierFieldFormProvider: FC<IProps> = ({
  close,
  responseError,
  loading,
  fieldData,
  templateData,
  validateData,
  linksData,
  readableLinks,
  setLinkData,
  getFieldData,
  getTemplateData,
  getValidateData,
  removeField,
  sendFieldData,
  updateFieldList,
  resetFieldData,
  sendTemplateData,
  sendValidateData,
  removeTemplate,
  removeValidate,
}) => {
  const navigate = useNavigate();

  const [openLinkModal, setOpenLinkModal] = useState<boolean>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);

  const { fieldId, classifierId } = useParams<{
    fieldId: string;
    classifierId: string;
  }>();
  useEffect(() => {
    return () => resetFieldData();
  }, []);

  useEffect(() => {
    resetFieldData();

    if (fieldId) {
      getFieldData(fieldId);
      getTemplateData(fieldId);
      getValidateData(fieldId);
    }
  }, [fieldId]);

  const handleSaveField = (formData: Data) => {
    sendFieldData({
      classifierId,
      formData,
      cb: (id) => {
        updateFieldList(classifierId);
        navigate(`/${classifierId}/field/${id}`);
      },
    });
  };

  const handleRemoveField = () => {
    removeField({
      fieldId,
      cb: () => {
        updateFieldList(classifierId);
        navigate(`/${classifierId}`);
      },
    });
  };

  const handleRemoveLink = (index) => {
    const newLinks = [...linksData];
    newLinks.splice(index, 1);
    setLinkData(newLinks);
  };

  const handleSaveLink = (data) => {
    const newLinks = [...linksData];

    newLinks.push(data);

    setLinkData(newLinks);
  };

  const handleSaveTemplate = (formData) => {
    sendTemplateData({
      fieldId,
      formData,
      cb: () => getTemplateData(fieldId),
    });
  };

  const handleSaveValidate = (formData) => {
    sendValidateData({
      fieldId,
      formData,
      cb: () => getValidateData(fieldId),
    });
  };

  const handleRemoveTemplate = (formData) => {
    removeTemplate({
      fieldId,
      templateId: formData.id,
      cb: () => getTemplateData(fieldId),
    });
  };

  const handleRemoveValidate = (formData) => {
    removeValidate({
      fieldId,
      validateId: formData.id,
      cb: () => getValidateData(fieldId),
    });
  };

  return (
    <>
      <ScrollBox autoHeight hasScrollControlBackground>
        <Preloader show={loading}>
          <ClassifierFieldForm
            loading={loading}
            data={fieldData}
            templateData={templateData}
            validateData={validateData}
            links={readableLinks}
            errorMessage={responseError}
            onRemoveField={() => setOpenRemoveModal(true)}
            onRemoveLinks={(index) => handleRemoveLink(index)}
            onCreateLink={() => setOpenLinkModal(true)}
            onSave={handleSaveField}
            onSaveTemplate={handleSaveTemplate}
            onDeleteTemplate={handleRemoveTemplate}
            onSaveValidate={handleSaveValidate}
            onDeleteValidate={handleRemoveValidate}
          />
        </Preloader>
      </ScrollBox>
      <ModalWindow
        open={openRemoveModal}
        onCloseModal={() => {
          setOpenRemoveModal(false);
        }}
        isButtonClose
      >
        <div className={s["confirmModal"]}>
          <SubTitle
            caption={`Подтвердите удалениe поля «${fieldData?.name}»`}
          />
          <div className={s["buttons"]}>
            <Button
              type="default"
              theme="green"
              onClick={handleRemoveField}
              text="Подтвердить"
            />
            <Button
              type="default"
              theme="red"
              onClick={() => setOpenRemoveModal(false)}
              text="Отменить"
            />
          </div>
        </div>
      </ModalWindow>
      <ModalWindow
        open={openLinkModal}
        onCloseModal={() => {
          setOpenLinkModal(false);
        }}
        isButtonClose
      >
        <ClassifierLinkForm
          fieldId={fieldId}
          classifierId={classifierId}
          onSave={(data) => {
            handleSaveLink(data);
            setOpenLinkModal(false);
          }}
          name={fieldData?.name || ""}
        />
      </ModalWindow>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getFieldData: (data) => dispatch(getFieldData(data)),
  getTemplateData: (data) => dispatch(getTemplateData(data)),
  getValidateData: (data) => dispatch(getValidateData(data)),
  removeField: (data) => dispatch(removeField(data)),
  setLinkData: (data) => dispatch(setLinkData(data)),
  sendFieldData: (data) => dispatch(sendFieldData(data)),
  sendTemplateData: (data) => dispatch(sendTemplateData(data)),
  sendValidateData: (data) => dispatch(sendValidateData(data)),
  removeTemplate: (data) => dispatch(removeTemplate(data)),
  removeValidate: (data) => dispatch(removeValidate(data)),
  resetFieldData: () => dispatch(resetFieldData()),
});

const mapStateToProps = (state: StoreState.State) => ({
  loading: selectors.getLoadingStatus(state),
  fieldData: selectors.getFieldData(state),
  responseError: selectors.getResponseError(state),
  linksData: selectors.getLinksList(state),
  templateData: selectors.getTemplateData(state),
  validateData: selectors.getValidateData(state),
  readableLinks: selectors.getReadableLinks(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ClassifierFieldFormProvider);
