import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { StoreState } from "../../../ReactCore/store/types";

import Modal from "../../../arm/Wrappers/Modal";

import { Preloader } from "@omnichat/arm_ui_kit";

import ThematicModal from "./ThematicModal.provider";
import * as ThematicSelectors from "../ThematicModal.selectors";
import {
  getThemes,
  getThemesFields,
  setThemesFields
} from "../Actions/ThematicModal.action";
import {
  setOriginAppealThemes,
  setOriginAppealService,
  sendThemesData
} from "../Actions/ThematicInfoPanel.action";
import ThematicsInfo from "../Components/ThematicsInfo/ThematicsInfo";

const Wrap = styled.div`
  display: block;
  min-height: 50px;
  position: relative;
`;

/**
 * Модель собственных свойств компонента.
 *
 */
interface IOwnProps {
  appealId: number;
}

/**
 * Модель свойств, полученных из store.
 *
 */
interface IStateProps {
  appealThemes: string[];
  appealServices: string[];
  canEdit: boolean;
  isLoading: boolean;
}

/**
 * Модель экшенов.
 *
 */
interface IDispatchProps {
  getThemes: (appealId: number) => any;
  setOriginAppealThemes: (data: []) => any;
  setOriginAppealService: (data: []) => any;
  sendThemesData: (data: {}) => any;
  getThemesFields: (appealId: number) => any;
  resetThemesFields: () => void;
}

type ThematicInfoPanelProviderProps = IOwnProps & IStateProps & IDispatchProps;

/**
 * Провайдер фичи ThematicInfoPanelProvider.
 */
class ThematicInfoPanelProvider extends React.PureComponent<
  ThematicInfoPanelProviderProps
> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { appealId, getThemes, getThemesFields } = this.props;
    getThemes(appealId);
    getThemesFields(appealId);
  }

  componentWillUnmount() {
    const {
      setOriginAppealThemes,
      setOriginAppealService,
      resetThemesFields
    } = this.props;
    setOriginAppealThemes([]);
    setOriginAppealService(null);
    resetThemesFields();
  }

  openThematicsModal() {
    const { appealId, sendThemesData, getThemes } = this.props;
    const modalId = "ThematicModal";
    const modal = new Modal(modalId, 650, close => (
      <ThematicModal
        onConfirm={() =>
          sendThemesData({
            appealId,
            onClose: () => {
              getThemes(appealId);
              close();
            }
          })
        }
        appealId={appealId}
      />
    ));
    modal.open();
  }

  render() {
    const { canEdit, appealThemes, appealServices, isLoading } = this.props;
    return (
      <Wrap>
        <Preloader size="mini" show={isLoading}>
          <ThematicsInfo
            canEdit={canEdit}
            thematics={appealThemes}
            services={appealServices}
            onEditHandler={this.openThematicsModal.bind(this)}
          />
        </Preloader>
      </Wrap>
    );
  }
}

const mapStateToProps = (state: StoreState.State): IStateProps => ({
  appealThemes: ThematicSelectors.getThemesForInfoPanel(state),
  appealServices: ThematicSelectors.getServisesForInfoPanel(state),
  canEdit: ThematicSelectors.getCanEditThemes(state),
  isLoading: ThematicSelectors.getIsLoading(state)
});

const mapDispatchToProps = dispatch => ({
  sendThemesData: appealId => dispatch(sendThemesData(appealId)),
  getThemes: appealId => dispatch(getThemes(appealId)),
  setOriginAppealThemes: data => dispatch(setOriginAppealThemes(data)),
  setOriginAppealService: data => dispatch(setOriginAppealService(data)),
  getThemesFields: appealId => dispatch(getThemesFields(appealId)),
  resetThemesFields: () => dispatch(setThemesFields([]))
});

const Connect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ThematicInfoPanelProvider);

export default Connect;
