import { INCIDENT_TYPES } from "./consts";

const skuf = {
  skufId: null,

  appealId: null,

  status: {
    incidentHandling: false,
    incidentCreated: false,
    incidentFailed: false,
    regionsLoaded: false,
    levelsLoaded: false
  },

  types: INCIDENT_TYPES,

  levels: [],

  regions: [
    {
      id: "0",
      external_id: 0,
      region: "Федеральный уровень"
    }
  ],

  incidentData: {
    eventType: {
      required: true,
      value: null,
      error: false
    },
    zoneCode: {
      required: true,
      value: null,
      error: false
    },
    firstServiceId: {
      required: true,
      value: null,
      error: false
    },
    secondServiceId: {
      required: false,
      value: null,
      error: false
    },
    thirdServiceId: {
      required: false,
      value: null,
      error: false
    },
    description: {
      required: false,
      value: "",
      error: false
    }
  },

  incidentError: false
};

export default skuf;
