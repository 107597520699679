import React, { FC, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { isEmpty, Button } from "@omnichat/arm_ui_kit";

import NumberInput from "../../../../Components/NumberInput";
import Tooltip from "../../../../Components/Tooltip";
import Select from "../../../../Components/Select";
import FormFieldConnector from "../../../../Components/FormFieldConnector";
import { ReactHookFormErrorHandler } from "../../../../Utils/HookForm.helper";

import * as s from "../../SystemSettings.module.scss";

import {
  AutoLogoutSetting as AutoLogoutSettingType,
  OFFLINE_ID,
  SubStatus
} from "./AutoLogoutSetting.hooks";

const schema = Yup.object({
  timeout: Yup.number().min(1).max(99999).required(),
  substatusId: Yup.string().required(),
  isDefault: Yup.boolean()
});
const defaultValues = {
  timeout: null,
  substatusId: null,
  isDefault: false
};

export type ModeVariants = "view" | "edit" | "create";

export interface IForm extends Yup.InferType<typeof schema> {}

/**
 * Модель собственных свойств компонента.
 *
 */
interface IOwnProps {
  loading: boolean;
  setting: AutoLogoutSettingType;
  substatuses: SubStatus[];
  handleSave: (form: AutoLogoutSettingType) => void;
  handleDel: (id: string) => void;
}

type Props = IOwnProps;

const AutoLogoutSetting: FC<Props> = ({
  loading,
  substatuses,
  handleSave,
  handleDel,
  setting
}) => {
  const [mode, setMode] = useState<ModeVariants>(
    setting?.id ? "view" : "create"
  );

  const methods = useForm<IForm>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    setting &&
      reset({
        ...setting,
        isDefault: setting.isDefault,
        substatusId: setting.substatusId,
        timeout: setting.timeout
      });
  }, [setting]);

  const isDefaultOfflineSetting = `${setting.substatusId}` === `${OFFLINE_ID}`;

  return (
    <>
      <FormProvider {...methods}>
        <Grid container>
          <Grid
            container
            item
            xs={3}
            p={1}
            alignItems="center"
            borderBottom="1px solid #cbd7da"
          >
            {mode === "view" && <>{setting.timeout}</>}
            {(mode === "edit" || mode === "create") && (
              <FormFieldConnector
                name={`timeout`}
                showErrorText={false}
                required
                Component={(p, s) => (
                  <NumberInput
                    {...p}
                    maskInput={{
                      format: "#####"
                    }}
                    placeholder="Не указано"
                    isError={!isEmpty(s.error)}
                    value={p.value}
                    onChange={(v) => p.onChange(v.target.value)}
                  />
                )}
              />
            )}
          </Grid>
          <Grid container item xs={6} p={1} borderBottom="1px solid #cbd7da">
            {(mode === "view" ||
              mode === "edit" ||
              isDefaultOfflineSetting) && (
              <>
                {substatuses.find((s) => s.value === setting.substatusId)
                  ?.label || ""}
              </>
            )}
            {mode === "create" && (
              <FormFieldConnector
                name={`substatusId`}
                showErrorText={false}
                required
                extraClass={[s.reason]}
                Component={(p, s) => (
                  <Select
                    {...p}
                    options={substatuses.filter(
                      (s) => s.selectable || s.value === setting.substatusId
                    )}
                    selected={p.value ? [p.value] : []}
                    onSelectOption={(v) => p.onChange(v[0]?.value)}
                    isError={!isEmpty(s.error)}
                    extraSX={{ pt: "0" }}
                  />
                )}
              />
            )}
          </Grid>

          <Grid
            container
            alignItems="center"
            item
            xs={3}
            p={1}
            borderBottom="1px solid #cbd7da"
          >
            {mode === "view" && (
              <Tooltip content="Редактировать" position="top">
                <Button
                  disabled={loading}
                  type="bare"
                  width="18px"
                  height="18px"
                  iconColor="blue"
                  iconName="edit"
                  onClick={() => setMode("edit")}
                />
              </Tooltip>
            )}
            {(mode === "edit" || mode === "create") && (
              <Tooltip content="Сохранить" position="top">
                <Button
                  disabled={loading}
                  type="bare"
                  width="18px"
                  height="18px"
                  iconColor="green"
                  iconName="check"
                  onClick={handleSubmit((form) => {
                    handleSave(form);
                    setMode("view");
                  }, ReactHookFormErrorHandler)}
                />
              </Tooltip>
            )}

            {!isDefaultOfflineSetting && (
              <Tooltip content="Удалить" position="top">
                <Button
                  disabled={loading}
                  type="bare"
                  width="18px"
                  height="18px"
                  iconColor="red"
                  iconName="cross"
                  onClick={() => handleDel(setting.id)}
                />
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
};

export default AutoLogoutSetting;
