export const SET_PROFILE_IDS = "SET_PROFILE_IDS";
export const SET_ORGANIZATIONS_LIST = "SET_ORGANIZATIONS_LIST";
export const SET_TERRITORIES_LIST = "SET_TERRITORIES_LIST";
export const SET_AREAS_LIST = "SET_AREAS_LIST";
export const SET_SKILL_GROUPS_LIST = "SET_SKILL_GROUPS_LIST";

export const GET_USERS_MULTI_PROFILE_CATALOGS =
  "GET_USERS_MULTI_PROFILE_CATALOGS";
export const SEND_USERS_MULTI_PROFILE_DATA = "SEND_USERS_MULTI_PROFILE_DATA";

export const USERS_MULTI_PROFILE_DATA_UPDATED =
  "USERS_MULTI_PROFILE_DATA_UPDATED";
export const SHOW_MULTI_USERS_PROFILE_DATA_ERROR =
  "SHOW_MULTI_USERS_PROFILE_DATA_ERROR";
export const SET_MULTI_USERS_PROFILE_TEXT_ERROR =
  "SET_MULTI_USERS_PROFILE_TEXT_ERROR";
export const RESET_USERS_MULTI_PROFILE_DATA = "RESET_USERS_MULTI_PROFILE_DATA";

export const DEACTIVATE_USERS = "DEACTIVATE_USERS";
