export const SEND_INCIDENT_TO_SKUF = "SEND_INCIDENT_TO_SKUF";
export const SEND_GET_SKUF_CATALOGS = "SEND_GET_SKUF_CATALOGS";

export const GET_INCIDENT_SKUF_STATUS = "GET_INCIDENT_SKUF_STATUS";
export const SET_INCIDENT_SKUF_STATUS = "SET_INCIDENT_SKUF_STATUS";
export const SET_INCIDENT_SKUF_ID = "SET_INCIDENT_SKUF_ID";

export const SET_INCIDENT_APPEAL_ID = "SET_INCIDENT_APPEAL_ID";
export const SKUF_INCIDENT_DATA_UPDATED = "SKUF_INCIDENT_DATA_UPDATED";
export const SHOW_INCIDENT_DATA_ERRORS = "SHOW_INCIDENT_DATA_ERRORS";
export const RESET_INCIDENT_DATA = "RESET_INCIDENT_DATA";

export const LOADED_SKUF_LEVELS = "LOADED_SKUF_LEVELS";
export const LOADED_SKUF_REGIONS = "LOADED_SKUF_REGIONS";

export const SKUF_INCIDENT_CREATED = "SKUF_INCIDENT_CREATED";
export const SKUF_INCIDENT_FAILED = "SKUF_INCIDENT_FAILED";
export const SKUF_INCIDENT_SEND_ERROR = "SKUF_INCIDENT_SEND_ERROR";

export const INCIDENT_TYPES = [
  { id: 1, name: "Инцидент" },
  { id: 2, name: "Запрос информации" },
  { id: 3, name: "Обращение" }
];

/** Инцидент может быть отправлен */
export const INCIDENT_STATUS_ACTIVE = null;
/** Инцидент был успешно создан и отправлен в СКУФ */
export const INCIDENT_STATUS_CREATED = true;
/** Инцидент не был создан. Создать можно только вручную */
export const INCIDENT_STATUS_FAILED = false;
