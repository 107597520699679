export const GET_USERS_PROFILE_CATALOGS = "GET_USERS_PROFILE_CATALOGS";
export const GET_USERS_PROFILE_DATA = "GET_USERS_PROFILE_DATA";

export const SET_PHOTO = "SET_PHOTO";
export const SET_ROLES_LIST = "SET_ROLES_LIST";
export const SET_ORGANIZATIONS_LIST = "SET_ORGANIZATIONS_LIST";
export const SET_TERRITORIES_LIST = "SET_TERRITORIES_LIST";
export const SET_AREAS_LIST = "SET_AREAS_LIST";
export const SET_SKILL_GROUPS_LIST = "SET_SKILL_GROUPS_LIST";
export const SET_USERS_PROFILE_DATA = "SET_USERS_PROFILE_DATA";
export const SET_USERS_EMAIL_ERROR = "SET_USERS_EMAIL_ERROR";

export const USERS_PROFILE_RATING = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" }
];
export const USERS_PROFILE_DATA_UPDATED = "USERS_PROFILE_DATA_UPDATED";

export const SHOW_USERS_PROFILE_DATA_ERROR = "SHOW_USERS_PROFILE_DATA_ERROR";
export const SET_USERS_PROFILE_RESPONSE_ERROR =
  "SET_USERS_PROFILE_RESPONSE_ERROR";

export const RESET_USERS_PROFILE_DATA = "RESET_USERS_PROFILE_DATA";
export const SEND_USERS_PROFILE_DATA = "SEND_USERS_PROFILE_DATA";
export const USERS_PROFILE_CREATED = "USERS_PROFILE_CREATED";
export const REMOVE_USERS_PROFILE = "REMOVE_USERS_PROFILE";

export const RESTORE_USERS_PASS = "RESTORE_USERS_PASS";

export const GET_USERS_OWN_PROFILE_DATA = "GET_USERS_OWN_PROFILE_DATA";
export const SET_USERS_OWN_PROFILE_DATA = "SET_USERS_OWN_PROFILE_DATA";
