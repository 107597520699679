import React, { FC } from "react";

import {
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
  InputLabel as MuiInputLabel,
  FormControlProps as MuiFormControlProps,
} from "@mui/material";
import Info from "@mui/icons-material/InfoOutlined";

export type labelCaseType = "none" | "capitalize" | "uppercase" | "lowercase";
type labelPositionType = "static" | "relative" | "absolute" | "sticky" | "fixed";

/**
 * Модель свойств компонента обертки.
 *
 * @prop {JSX.Element | string} [label] Label компонента FormField.
 * @prop {boolean} [labelCaseStyle] Переключатель регистра текста label.
 * @prop {boolean} [labelPosition] Переключатель позиционирования текста label.
 * @prop {boolean} [required] Признак необходимости заполнения.
 * @prop {boolean} [disabled] Признак доступности компонента для редактирования.
 * @prop {string} [actionText] Подсказка.
 * @prop {boolean} [isError] Признак наличия ошибки.
 * @prop {string} [inputId] id привязки к компоненту-потомку.
 * @prop {JSX.Element[]} children Дочерние компоненты.
 * @prop {object} [extraSX] Кастомные стили на обертку.
 */
interface IFormFieldProps extends MuiFormControlProps {
  label?: JSX.Element | string;
  labelCaseStyle?: labelCaseType;
  labelPosition?: labelPositionType;
  required?: boolean;
  disabled?: boolean;
  actionText?: string;
  isError?: boolean;
  inputId?: string;
  children: JSX.Element | JSX.Element[];
  extraSX?: { [key:string]: string };
}

/**
 * Обертка для поля с наименованием.
 */
const FormField: FC<IFormFieldProps> = ({
  label,
  labelCaseStyle = "none",
  labelPosition = "absolute",
  required,
  disabled,
  actionText,
  isError = false,
  inputId,
  children,
  extraSX = {},
  ...props
}): JSX.Element => {
  return (
    <MuiFormControl
      variant="standard"
      required={required}
      disabled={disabled}
      sx={extraSX}
    >
      {label && (
        // todo: параметр shrink={true} - костыль для обхода директивы отключения placeholder у Input, являющегося потомком FormControl ["label[data-shrink=false] + .MuiInputBase-formControl &"]
        <MuiInputLabel
          htmlFor={inputId ? `input-${inputId}` : null}
          disableAnimation
          shrink={true}
          sx={[
            { position: labelPosition },
            labelCaseStyle !== "none" && { textTransform: labelCaseStyle }
          ]}
        >
          {`${label}:`}
        </MuiInputLabel>
      )}

      <>{children}</>

      {actionText && (
        <MuiFormHelperText
          id={inputId ? `action-text-${inputId}` : null}
          error={isError}
        >
          {isError && <Info />}
          <span>{actionText}</span>
        </MuiFormHelperText>
      )}
    </MuiFormControl>
  );
};

export default FormField;
