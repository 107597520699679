import React from "react";

import { ScrollBox, Button } from "@omnichat/arm_ui_kit";

import AppealTimer from "../../../Common/Components/AppealTimer/AppealTimer";
import ClientInfo from "../../../Common/Components/ClientInfo/ClientInfo";
import InfoField from "../../../Common/Components/InfoField/InfoField";
import { ISkill } from "../../../Common/Interfaces/SkillGroup.interfaces";

import CsiInfo from "../../../Common/Components/CsiInfo/CsiInfo";
import { IAppealData } from "../../../Common/Interfaces/IAppealData";

import * as cs from "../../../../styles/common.module.scss";
import * as s from "./AppealInfoModal.module.scss";
import c from "classnames";

/**
 * Интерфейс собственных свойств компонента.
 *
 * @prop {JSX.Element} messagesBox Блок с сообщениями.
 */
interface IAppealInfoModalProps {
  appealData: IAppealData;
  skufIncident: boolean;
  additionalClientFields: () => JSX.Element;
  filesUrl: string;
  reopenAppealHandler?: () => {};
  closeAppealHandler?: () => {};
  transferAppealHandler?: () => {};
  onConfirmAction?: () => void;
  thematicsConstructor?: () => JSX.Element;
  messagesBox?: JSX.Element;
  dialogContextHandle: () => {};
}

const IncidentInfo = ({ status, incId }: any): JSX.Element => {
  if (status === false) {
    return (
      <div className={s["modalContentAppealInfoSkuf"]}>
        Инцидент не был создан. Создайте инцидент вручную
      </div>
    );
  }
  if (status === true) {
    return (
      <div className={s["modalContentAppealInfoSkuf"]}>
        Инцидент № {incId} создан
      </div>
    );
  }

  return (
    <div className={s["modalContentAppealInfoSkuf"]}>
      Инцидент не создавался
    </div>
  );
};

const AppealInfoModal: React.FunctionComponent<IAppealInfoModalProps> = ({
  appealData,
  skufIncident,
  filesUrl,
  reopenAppealHandler,
  closeAppealHandler,
  transferAppealHandler,
  thematicsConstructor,
  additionalClientFields,
  messagesBox,
  dialogContextHandle = null,
}): JSX.Element => {
  const groupsMapper = (skill: ISkill): JSX.Element | void => {
    if (skill && skill.id && skill.name) {
      return (
        <React.Fragment key={`skill-${skill.id}`}>
          <div>{skill.name}</div>
        </React.Fragment>
      );
    }
  };

  return (
    <div className={s["modal"]}>
      <div className={c(s["modalHeader"], cs["defaultModalHeader"])}>
        <div>
          <div className={c(s["modalHeaderAppealMain"])}>
            <div
              className={s["modalHeaderAppealMainTitle"]}
              style={{ marginRight: "1em" }}
            >
              Обращение #{appealData.id}
            </div>
            <AppealTimer
              time={appealData.operationTime}
              tooltipText="Общее время работы с обращением"
            />
          </div>

          <div className={s["modalHeaderControlBtns"]}>
            {closeAppealHandler && (
              <Button
                key="closeAppeal"
                type="bare"
                theme="default"
                iconName="cross"
                iconColor="blue"
                text="Закрыть"
                onClick={closeAppealHandler}
              />
            )}
            {transferAppealHandler && (
              <Button
                key="transferAppeal"
                type="bare"
                theme="default"
                iconName="repeat"
                iconColor="blue"
                text="Передать"
                onClick={transferAppealHandler}
              />
            )}
            {reopenAppealHandler && (
              <Button
                key="reopenAppeal"
                type="bare"
                theme="default"
                iconName="replay"
                iconColor="blue"
                text="Открыть"
                onClick={reopenAppealHandler}
              />
            )}
          </div>
        </div>

        <div>Итог обращения</div>
      </div>

      <div className={c(s["modalContent"], cs["defaultModalContent"])}>
        <div>
          <ScrollBox autoHeight hasScrollControlBackground autoHeightMax={530}>
            <div className={c(s["marginScrollBoxContent"])}>
              <ClientInfo
                showPersonalData
                clientData={appealData.client}
                region={appealData.region}
                filesUrl={filesUrl}
                skillGroup={appealData.skillGroup.name}
                dialogContextHandle={dialogContextHandle}
              />
              {additionalClientFields && additionalClientFields()}
            </div>
          </ScrollBox>
        </div>

        <div>
          <ScrollBox autoHeight hasScrollControlBackground autoHeightMax={530}>
            <div className={c(s["marginScrollBoxContent"])}>{messagesBox}</div>
          </ScrollBox>
        </div>

        <div>
          <ScrollBox autoHeight hasScrollControlBackground autoHeightMax={530}>
            <div className={c(s["marginScrollBoxContent"])}>
              <div className={s["modalContentAppealInfoMain"]}>
                <InfoField key="sl" label="SL">
                  <span>{appealData.sl ? "Выполнен" : "Не выполнен"}</span>
                </InfoField>

                {!!appealData.skillsOfAppeal && (
                  <InfoField key="skills" label="Группы">
                    {appealData.skillsOfAppeal.map(groupsMapper)}
                  </InfoField>
                )}
              </div>

              {skufIncident && (
                <IncidentInfo
                  status={appealData.incidentCreated}
                  incId={appealData.incId}
                />
              )}

              {thematicsConstructor && (
                <div className={s["modalContentAppealInfoThematics"]}>
                  {thematicsConstructor()}
                </div>
              )}

              {!!appealData.comment && (
                <>
                  <hr className={c(s["modalContentHr"], cs["defaultHr"])}></hr>

                  <InfoField key="comment" label="Комментарий">
                    <span>{appealData.comment}</span>
                  </InfoField>
                </>
              )}

              {!!appealData.csi && (
                <div className={s["modalContentAppealInfoCsi"]}>
                  <hr className={s["modalContentHr"]}></hr>

                  <CsiInfo
                    rate={appealData.csi.rate}
                    comment={appealData.csi.comment}
                    categories={appealData.csi.categories}
                  />
                </div>
              )}
            </div>
          </ScrollBox>
        </div>
      </div>
    </div>
  );
};

export default AppealInfoModal;
