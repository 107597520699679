// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zTBazb8tGb4vemy1sn_k{display:flex;position:relative;align-items:center;justify-content:center;position:relative;width:100%;height:100%;min-width:200px;min-height:200px;border-radius:10px;overflow:hidden}.t05aFfSzvocCb6pvMNKP{position:absolute;width:100%;height:100%;border-radius:10px;object-fit:cover;cursor:pointer}.zTBazb8tGb4vemy1sn_k .OPMUtNFKyTiVpRCz_exW{position:absolute;display:flex;align-items:center;justify-content:center;top:0;bottom:0;left:0;right:0}.rKyj6UUx1G3pooa43LXu{margin-bottom:30%}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/MessageBox/Components/LocationMessage/LocationMessage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iBAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CACA,eAAA,CAEA,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CACA,gBAAA,CACA,cAAA,CAGF,4CACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,KAAA,CACA,QAAA,CACA,MAAA,CACA,OAAA,CAGJ,sBACE,iBAAA","sourcesContent":[".container {\n  display: flex;\n  position: relative;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  width: 100%;\n  height: 100%;\n  min-width: 200px;\n  min-height: 200px;\n  border-radius: 10px;\n  overflow: hidden;\n\n  &_image-preview {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    border-radius: 10px;\n    object-fit: cover;\n    cursor: pointer;\n  }\n\n  .icon-container {\n    position: absolute;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n  }\n}\n.icon-marker {\n  margin-bottom: calc(30%);\n}\n"],"sourceRoot":""}]);
// Exports
export var container = "zTBazb8tGb4vemy1sn_k";
export var containerImagePreview = "t05aFfSzvocCb6pvMNKP";
export var iconContainer = "OPMUtNFKyTiVpRCz_exW";
export var iconMarker = "rKyj6UUx1G3pooa43LXu";
export default ___CSS_LOADER_EXPORT___;
