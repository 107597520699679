import { fork } from "redux-saga/effects";

import getMrfs from "./getMrfs.saga";
import getRfs from "./getRfs.saga";
import getRegions from "./getRegions.saga";
import getCategoriesSaga from "./getRepliesCategories.saga";
import getTransferList from "./getMoveAppealList.saga";

export default function*() {
  yield fork(getMrfs);
  yield fork(getRfs);
  yield fork(getRegions);
  yield fork(getCategoriesSaga);
  yield fork(getTransferList);
}
