// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VgDyxUQ7vS7Lyk8xpUzz{display:flex;flex-flow:column nowrap;margin-bottom:10px}.g3iivhkfo12lkO6Gt8Ns{line-height:18px;color:#829199;margin-top:3px;font-size:16px;display:flex}.h3Z3WFfv2DbGpp7Ra8GA{display:flex;align-items:center;color:#fc2b32}.Cf52_zChJToT1FCvoMDm{margin-left:5px}", "",{"version":3,"sources":["webpack://./frontend/src/Components/FormFieldConnector/style.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,uBAAA,CACA,kBAAA,CAGF,sBACE,gBAAA,CACA,aAAA,CACA,cAAA,CACA,cAAA,CACA,YAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,aAAA,CAGF,sBACE,eAAA","sourcesContent":[".fieldWrapper {\n  display: flex;\n  flex-flow: column nowrap;\n  margin-bottom: 10px;\n}\n\n.fieldActionText {\n  line-height: 18px;\n  color: #829199;\n  margin-top: 3px;\n  font-size: 16px;\n  display: flex;\n}\n\n.fieldError {\n  display: flex;\n  align-items: center;\n  color: #fc2b32;\n}\n\n.text {\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export var fieldWrapper = "VgDyxUQ7vS7Lyk8xpUzz";
export var fieldActionText = "g3iivhkfo12lkO6Gt8Ns";
export var fieldError = "h3Z3WFfv2DbGpp7Ra8GA";
export var text = "Cf52_zChJToT1FCvoMDm";
export default ___CSS_LOADER_EXPORT___;
