import { takeEvery, put } from "redux-saga/effects";
import { httpRequest } from "../../ReactCore/shared/httpRequest";
import {
  DASHBOARD_FILTER_DICTIONARIES_LOADING,
  CHANGE_FILTER_PANEL_DATA,
  FILL_FILTERS_DICTIONARIES
} from "./consts";

function* getDashboardFiltersDictionariesSaga(preSelectedFormData) {
  try {
    const response = yield httpRequest("GET", "bff/filters/init");
    if (response) {
      const channel = response.channels?.data?.map((el) => ({
        value: String(el.id),
        label: el.name
      }));

      const region = response.regions?.data?.map((el) => ({
        value: String(el.id),
        label: el.region,
        mrfId: String(el.mrfId),
        rfId: String(el.rfId)
      }));

      const mrf = response.mrfs?.data?.map((el) => ({
        id: String(el.id),
        value: String(el.code),
        label: el.name
      }));

      const rf = response.rfs?.data?.map((el) => ({
        value: String(el.id),
        label: el.name,
        mrfId: String(el.mrfId)
      }));

      yield put({
        type: FILL_FILTERS_DICTIONARIES,
        payload: {
          channel,
          region,
          mrf,
          rf
        }
      });
    }

    yield put({
      type: CHANGE_FILTER_PANEL_DATA,
      payload: preSelectedFormData.payload
    });
  } catch (e) {
    console.error("Saga: getDashboardFiltersDictionariesSaga error", e);
  }
}

export default function* () {
  yield takeEvery(
    DASHBOARD_FILTER_DICTIONARIES_LOADING,
    getDashboardFiltersDictionariesSaga
  );
}
