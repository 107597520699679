import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { Preloader, ScrollBox, Button } from "@omnichat/arm_ui_kit";

import SubTitle from "../SkillGroupModal/Components/SubTitle/SubTitle";

import ModalWindow from "../../Components/ModalWindow";
import { StoreState } from "../../ReactCore/store/types";
import {
  getValueData,
  removeValue,
  sendValueData,
  resetValueData,
  setLinkData,
} from "./Store/ClassifierFieldValueForm.reducer";

import * as selectors from "./Store/ClassifierFieldValueForm.selector";

import { Data } from "./ClassifierFieldValueForm";
import ClassifierLinkForm from "../ClassifierLinkForm";

import ClassifierFieldValueForm from "./Components/ClassifierFieldValueForm";
import * as s from "./style.module.scss";

type IOwnProps = {
  close;
  updateValuesList: (id: string) => null;
};

type IProps = IOwnProps & ConnectedProps<typeof connector>;

const ClassifierFieldValueFormProvider: FC<IProps> = ({
  close,
  responseError,
  loading,
  fields,
  fieldData,
  linksData,
  setLinkData,
  readableLinks,
  getValueData,
  removeValue,
  sendValueData,
  updateValuesList,
  resetValueData,
}) => {
  const navigate = useNavigate();

  const [openLinkModal, setOpenLinkModal] = useState<boolean>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);

  const { valueId, fieldId, classifierId } = useParams<{
    valueId: string;
    fieldId: string;
    classifierId: string;
  }>();
  useEffect(() => {
    return () => resetValueData();
  }, []);

  useEffect(() => {
    resetValueData();

    if (valueId && fieldId) {
      getValueData(valueId);
    }
  }, [valueId, fieldId]);

  const handleSaveField = (formData: Data) => {
    sendValueData({
      fieldId,
      formData,
      cb: (id) => {
        updateValuesList(fieldId);
        navigate(`/${classifierId}/field/${fieldId}/value/${id}`);
      },
    });
  };

  const handleRemoveField = () => {
    removeValue({
      valueId,
      cb: () => {
        updateValuesList(fieldId);
        navigate(`/${classifierId}/field/${fieldId}`);
      },
    });
  };

  const handleRemoveLink = (index) => {
    const newLinks = [...linksData];
    newLinks.splice(index, 1);
    setLinkData(newLinks);
  };

  const handleSaveLink = (data) => {
    const newLinks = [...linksData];

    newLinks.push(data);

    setLinkData(newLinks);
  };

  return (
    <>
      <ScrollBox autoHeight hasScrollControlBackground>
        <Preloader show={loading}>
          <ClassifierFieldValueForm
            loading={loading}
            fieldId={fieldId}
            data={fieldData}
            links={readableLinks}
            errorMessage={responseError}
            onRemoveField={() => setOpenRemoveModal(true)}
            onRemoveLinks={(index) => handleRemoveLink(index)}
            onCreateLink={() => setOpenLinkModal(true)}
            onSave={handleSaveField}
          />
        </Preloader>
      </ScrollBox>
      <ModalWindow
        open={openRemoveModal}
        onCloseModal={() => {
          setOpenRemoveModal(false);
        }}
        isButtonClose
      >
        <div className={s["confirmModal"]}>
          <SubTitle
            caption={`Подтвердите удаление значения «${fieldData?.name}»`}
          />
          <div className={s["buttons"]}>
            <Button
              type="default"
              theme="green"
              onClick={handleRemoveField}
              text="Подтвердить"
            />
            <Button
              type="default"
              theme="red"
              onClick={() => setOpenRemoveModal(false)}
              text="Отменить"
            />
          </div>
        </div>
      </ModalWindow>
      <ModalWindow
        open={openLinkModal}
        onCloseModal={() => {
          setOpenLinkModal(false);
        }}
        isButtonClose
      >
        <ClassifierLinkForm
          fieldId={fieldId}
          classifierId={classifierId}
          onSave={(data) => {
            handleSaveLink(data);
            setOpenLinkModal(false);
          }}
          name={fieldData?.name || ""}
        />
      </ModalWindow>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getValueData: (data) => dispatch(getValueData(data)),
  removeValue: (data) => dispatch(removeValue(data)),
  sendValueData: (data) => dispatch(sendValueData(data)),
  setLinkData: (data) => dispatch(setLinkData(data)),
  resetValueData: () => dispatch(resetValueData()),
});

const mapStateToProps = (state: StoreState.State) => ({
  loading: selectors.getLoadingStatus(state),
  fields: selectors.getValueList(state),
  fieldData: selectors.getValueData(state),
  responseError: selectors.getResponseError(state),
  linksData: selectors.getLinksList(state),
  readableLinks: selectors.getReadableLinks(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ClassifierFieldValueFormProvider);
