import { SET_GROUP_DICTIONARY, SKILL_GROUP_DICTIONARY_INIT } from "./consts";

export const skillGroupDictionaryInit = () => ({
  type: SKILL_GROUP_DICTIONARY_INIT
});

export const setSkillGroupDictionary = (data) => ({
  type: SET_GROUP_DICTIONARY,
  payload: { data }
});
