import {
  TOGGLE_FILTER_PANEL,
  CHANGE_FILTER_PANEL_DATA,
  DASHBOARD_FILTER_DICTIONARIES_LOADING,
  DASHBOARD_FILTER_DICTIONARIES_LOADED,
  DASHBOARD_SET_CONFIRM_IN_PROGRESS
} from "./consts";

export const toggleFilterPanel = () => ({
  type: TOGGLE_FILTER_PANEL
});

export const changeFilterPanelData = data => ({
  type: CHANGE_FILTER_PANEL_DATA,
  payload: { ...data }
});

export const dashboardFilterDictionariesLoading = data => ({
  type: DASHBOARD_FILTER_DICTIONARIES_LOADING,
  payload: { ...data }
});

export const dashboardFilterDictionariesLoaded = data => ({
  type: DASHBOARD_FILTER_DICTIONARIES_LOADED,
  payload: { data }
});

export const setConfirmInProgress = (value: boolean) => ({
  type: DASHBOARD_SET_CONFIRM_IN_PROGRESS,
  payload: value
});
