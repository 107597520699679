import React from "react";

import * as s from "./SubTitle.module.scss";

interface ISubTitleProps {
  /** Текст заголовка */
  caption?: string;
}

/**
 * Подзаголовок
 * часто используется в МО для выделения групп настроек
 * @param param0
 */
const SubTitle: React.FunctionComponent<ISubTitleProps> = ({
  caption,
}): JSX.Element => {
  return (
    <div className={s["subTitle"]}>{caption && <span>{caption}</span>}</div>
  );
};

export default SubTitle;
