import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../ReactCore/shared/httpRequest";
import { SEND_GET_SKUF_CATALOGS } from "./consts";
import { loadedSkufLevels, loadedSkufRegions } from "./incidentSkuf.actions";

export function* getSkufLevelsSaga() {
  try {
    const response = yield httpRequest("GET", "bff/chat/appeals/skuf-levels");
    if (response?.success) {
      yield put(loadedSkufLevels(response.data?.levels));
      yield put(loadedSkufRegions(response.data?.regions));
    }
  } catch ($error) {
    console.error($error);
  }
}

export default function* () {
  yield takeEvery(SEND_GET_SKUF_CATALOGS, getSkufLevelsSaga);
}
