import { createSelector } from "reselect";

import { isEmpty } from "@omnichat/arm_ui_kit";

import { getRegions } from "../Dictionaries/Selectors";

import {
  computedFields,
  computedThemes
} from "./Services/ThematicModal.service";
import {
  getPreparedThemesList,
  getPreparedServicesList
} from "./Services/ThematicInfoPanel.service";

export const getActiveService = state => {
  return state.ThematicModal.activeService;
};

export const getErrorMessage = state => {
  return state.ThematicModal.errorMessage;
};

export const getSelectedRegion = state => {
  return state.ThematicModal.selectedRegion;
};

export const getAppealThemes = state => {
  return state.ThematicModal.appealThemes;
};

export const getEditThemeId = state => {
  return state.ThematicModal.editTheme;
};

export const getThemeFields = state => {
  return state.ThematicModal.themeFields;
};

export const getServiceRegion = state => {
  return state.ThematicModal.serviceRegion;
};
export const getServiceEnabled = state => {
  return state.ThematicModal.serviceEnabled;
};

export const getIsLoading = state => {
  return state.ThematicModal.isLoading;
};

export const getComputedFields = createSelector(getThemeFields, themeFields =>
  themeFields ? computedFields(themeFields) : null
);

export const getComputedThemes = createSelector(getAppealThemes, themes =>
  themes ? computedThemes(themes) : null
);

export const selectRegions = createSelector(getRegions, regions =>
  regions.map(region => ({ value: region.value, label: region.label }))
);

export const getThemesForInfoPanel = state => {
  return !isEmpty(state.ThematicModal.originAppealThemes)
    ? getPreparedThemesList(state.ThematicModal.originAppealThemes)
    : [];
};

export const getServisesForInfoPanel = state => {
  return !isEmpty(state.ThematicModal.originAppealService)
    ? getPreparedServicesList(state.ThematicModal.originAppealService)
    : [];
};

export const getCanEditThemes = createSelector(getThemeFields, themeFields => {
  return !isEmpty(themeFields);
});
