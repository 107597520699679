import { createAction } from "@reduxjs/toolkit";

import * as ACTION from "../Consts";

export const getTemplateModalCatalogs = createAction(ACTION.GET_CATALOGS);

export const changeTemplateModalCategoriesPage = createAction(
  ACTION.CHANGE_CATEGORIES_PAGE
);

export const getTemplateData = createAction<any>(ACTION.GET_REPLIES_DATA);

export const setTemplateData = createAction<any>(ACTION.SET_REPLIES_DATA);

export const resetTemplateData = createAction(ACTION.RESET_DATA);

export const sendTemplateModalData = createAction<any>(ACTION.SEND_DATA);
