// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uOB2iGUZgEDO5U8w2ChG{display:flex;flex-flow:column nowrap}.O3pVToosws6yXFI5k31A{font-weight:bolder;color:#525f67}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/Common/Components/InfoField/InfoField.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,uBAAA,CAGF,sBACE,kBAAA,CACA,aAAA","sourcesContent":[".wrapper {\n  display: flex;\n  flex-flow: column nowrap;\n}\n\n.label {\n  font-weight: bolder;\n  color: #525f67;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = "uOB2iGUZgEDO5U8w2ChG";
export var label = "O3pVToosws6yXFI5k31A";
export default ___CSS_LOADER_EXPORT___;
