// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tt0n9HRcLsoXySpivhuK{display:flex;flex-direction:column;align-items:stretch}.e0vBVikf8Ck6stQZczCw{font-size:16px;border-bottom:1px solid #cbd7da;margin-bottom:10px;color:#829199;font-weight:700;line-height:34px;align-items:stretch}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/Common/Components/SectionMenuTitle/SectionMenuTitle.module.scss"],"names":[],"mappings":"AACE,sBACE,YAAA,CACA,qBAAA,CACA,mBAAA,CAEF,sBACE,cAAA,CACA,+BAAA,CACA,kBAAA,CACA,aAAA,CACA,eAAA,CACA,gBAAA,CACA,mBAAA","sourcesContent":[".settingsSection {\n  &__wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n  }\n  &__header {\n    font-size: 16px;\n    border-bottom: 1px solid #cbd7da;\n    margin-bottom: 10px;\n    color: #829199;\n    font-weight: 700;\n    line-height: 34px;\n    align-items: stretch;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var settingsSectionWrapper = "tt0n9HRcLsoXySpivhuK";
export var settingsSectionHeader = "e0vBVikf8Ck6stQZczCw";
export default ___CSS_LOADER_EXPORT___;
