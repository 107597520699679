import { takeEvery, call } from "redux-saga/effects";
import { createWsApiSaga } from "../../../Stomp/Stomp.saga";
import {
  sendUserEvent,
  incomingMessageRoutine
} from "../../../Stomp/Stopm.actions";
import { initSystemStatisticStomp } from "../Store/SystemStatistics.reducer";

const publishDefinitions = [
  {
    path: "/app/event",
    routine: sendUserEvent
  }
];
const subscriptions = [
  {
    path: "/app/event",
    routine: incomingMessageRoutine,
    convertPayload: (body) => JSON.parse(body)
  }
];

function* initSystemStatisticStompSaga() {
  const stompSaga = yield createWsApiSaga(
    {
      sockJsEndpoint: `${location.protocol}//${location.host}/statistics/stomp`,
      verboseLogging: false,
      headers: {
        hash: window.core.user.hash
      }
    },
    {
      subscriptions,
      publishDefinitions
    }
  );

  yield call(stompSaga);
}

export default function* () {
  yield takeEvery(initSystemStatisticStomp, initSystemStatisticStompSaga);
}
