import { take, fork, cancel } from "redux-saga/effects";

import { websocket } from "../../Websocket/Websocket.saga";
import { connectWs, disconnectWs } from "../../Websocket/Websocket.actions";
import { getChatAction } from "../WS/ws.actions";

const onReceivedHandle = (wsEvent, sendMessageRead) => {
  // Игноррируем пакеты с real_time_stats, т.к. пока что в чате они не нужны
  if (!wsEvent.name || wsEvent.name === "real_time_stats") return;
  getChatAction(wsEvent.name, wsEvent);
  sendMessageRead();
};

export function* ChatWsFlow() {
  while (true) {
    const { payload } = yield take(connectWs);
    const instance = websocket().getInstance(payload, onReceivedHandle);
    yield fork(instance.open);
    yield take(disconnectWs);
    yield cancel(instance.secretId);
  }
}
