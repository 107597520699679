import React, { FC, memo } from "react";
import c from "classnames";

import * as s from "./styles.module.scss";
import { ChatbotData } from "../../Chatbots";

const baseBotAvatar = "/www/img/msg_bot_avatar.png";

interface BotProps {
  bot: ChatbotData;
  onBotClick: (id: string) => void;
}

const Bot: FC<BotProps> = ({ bot, onBotClick }) => {
  const handleBotClick = () => onBotClick(bot.id);

  return (
    <div className={s["botWrap"]} onClick={handleBotClick} key={bot.id}>
      <div className={s["bot"]}>
        <div className={c(s["avatar"], s["floatLeft"])}>
          <img
            src={
              bot.botAgentPhoto
                ? `/fileStorage/files/crop/${bot.botAgentPhoto}`
                : baseBotAvatar
            }
          />
        </div>
        <div className={s["info"]}>
          <div className={c(s["name"], s["floatLeft"])}>{bot.name}</div>
          <div className={c(s["role"], s["floatRight"])}>
            {`${bot.heartBeatUrl}`}
          </div>
        </div>
        <div className={s["info"]}>
          <div className={s["role"]}>{bot.botAgentPenName}</div>
        </div>
      </div>
    </div>
  );
};

export default memo(Bot);
