// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zlsAINbn5CwmjRdz5ikE{width:auto;margin:25px;padding:auto}.zlsAINbn5CwmjRdz5ikE .EwNxmjnzghO49poNm9i4{word-wrap:break-word;color:#829199;margin-top:5px;font-size:15px}.zlsAINbn5CwmjRdz5ikE .zATAxDuBjYtbwj5wpemR{margin-top:20px;height:1px;min-height:555px}.zlsAINbn5CwmjRdz5ikE .Rh0L9F2KCJQ3S9kS9ctt{margin-right:20px;height:100%;min-height:100%}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/DialogHeader/Components/DialogContextModal/DialogContextModal.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,4CACE,oBAAA,CACA,aAAA,CACA,cAAA,CACA,cAAA,CAEF,4CACE,eAAA,CACA,UAAA,CACA,gBAAA,CAGF,4CACE,iBAAA,CACA,WAAA,CACA,eAAA","sourcesContent":[".modal {\n  width: auto;\n  margin: 25px;\n  padding: auto;\n  .modal__header_context-info {\n    word-wrap: break-word;\n    color: #829199;\n    margin-top: 5px;\n    font-size: 15px;\n  }\n  .modal__content {\n    margin-top: 20px;\n    height: 1px;\n    min-height: 555px;\n  }\n\n  .margin__ScrollBox_Content {\n    margin-right: 20px;\n    height: 100%;\n    min-height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var modal = "zlsAINbn5CwmjRdz5ikE";
export var modalHeaderContextInfo = "EwNxmjnzghO49poNm9i4";
export var modalContent = "zATAxDuBjYtbwj5wpemR";
export var marginScrollBoxContent = "Rh0L9F2KCJQ3S9kS9ctt";
export default ___CSS_LOADER_EXPORT___;
