export const REQUIRED = "required";
export const required = value => {
  if (value === undefined) {
    return REQUIRED;
  }

  if (value === "") {
    return REQUIRED;
  }

  if (Array.isArray(value) && value.length === 0) {
    return REQUIRED;
  }
};

export const MAX_CONTENT_LENGTH = "maxContentLength";
export const maxContentLength = maxLength => value => {
  if (
    value !== undefined &&
    typeof value === "string" &&
    value.length > maxLength
  ) {
    return "maxContentLength";
  }
};

export const isValidValue = value => {
  if (value === undefined) {
    return false;
  }

  if (value === null) {
    return false;
  }

  if (value === "") {
    return false;
  }

  if (Array.isArray(value) && value.length === 0) {
    return false;
  }

  return true;
};
