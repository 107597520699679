import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ModalWindow } from "../../Components";
import ChatbotList from "./Components/ChatbotList";
import ChatbotModal from "./Components/ChatbotModal";

import {
  getChatbotslist,
  getChatbotData,
  sendChatbotData,
  removeChatbot,
  cleanChatbot,
  cleanList
} from "./Store/Chatbots.reducer";
import {
  loading,
  chatbotData,
  chatbotList,
  error
} from "./Store/Chatbots.selector";

const Chatbots: FC = () => {
  const [modalOpened, toggleModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const isLoading = useSelector(loading);
  const chatbot = useSelector(chatbotData);
  const bots = useSelector(chatbotList);
  const badRequest = useSelector(error);

  useEffect(() => {
    dispatch(getChatbotslist());
    return () => {
      dispatch(cleanChatbot());
      dispatch(cleanList());
    };
  }, []);

  const onSave = (data) => dispatch(sendChatbotData({ data, cb: onClose }));

  const onRemove = (id) => {
    dispatch(removeChatbot({ id, cb: onClose }));
  };

  const onClose = () => {
    toggleModal(false);
    dispatch(cleanChatbot());
  };

  return (
    <>
      <ChatbotList
        bots={bots}
        onCreateBot={() => toggleModal(true)}
        onBotClick={(id) => {
          toggleModal(true);
          dispatch(getChatbotData(id));
        }}
      />
      <ModalWindow open={modalOpened} onCloseModal={onClose} isButtonClose>
        <ChatbotModal
          loading={isLoading}
          data={chatbot}
          onSave={onSave}
          onRemove={onRemove}
          error={badRequest}
        />
      </ModalWindow>
    </>
  );
};

export default Chatbots;
