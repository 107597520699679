const usersMultiProfileModal = {
  checkedIds: null,

  status: {
    usersProfileHandling: false,
    usersProfileDataLoading: false,
    areasLoaded: false,
    organizationsLoaded: false,
    skillGroupsLoaded: false,
    territoriesLoaded: false
  },
  formData: {
    appeals: {
      required: false,
      value: null,
      error: false
    },
    signOfOrganization: {
      required: false,
      value: null,
      error: false
    },
    territory: {
      required: false,
      value: null,
      error: false
    },
    areasData: {
      required: false,
      error: false,
      value: null
    },
    skillGroup: {
      required: false,
      error: false,
      value: []
    }
  },
  errorText: "",
  areas: [],
  organizations: [],
  skillGroups: [],
  territories: []
};

export default usersMultiProfileModal;
