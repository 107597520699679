import React, { FC } from "react";
import { Slider as SliderMui, SliderProps } from "@mui/material";

type Props = SliderProps;

const Slider: FC<Props> = ({ ...props }) => {
  return <SliderMui size="small" {...props} />;
};

export default Slider;
