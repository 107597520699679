import { takeEvery, put } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { isEmpty } from "@omnichat/arm_ui_kit";
import {
  getAvailableHotKeysData,
  setHotKeysDataFulfilled,
  setHotKeysDataPending,
  setHotKeysDataRejected
} from "../Actions/AvailableHotKeysModal.actionCreators";

/**
 * Сага для получения всех данных по горячим клавишам.
 *
 * @param {any} action Экшн.
 */
function* getAvailableKeysData(action) {
  console.log(action);
  yield put(setHotKeysDataPending());

  const response = yield httpRequest("GET", `bff/chat/hotkeys`, {
    query: {
      appealId: action.payload
    }
  });

  if (response?.success) {
    let newData = !isEmpty(response.data) ? [...response.data] : [];

    yield put(setHotKeysDataFulfilled(newData));
  } else {
    yield put(setHotKeysDataRejected(response.error));
  }
}

export function* watchAvailableKeysData() {
  yield takeEvery([getAvailableHotKeysData], getAvailableKeysData);
}
