import React, { FC, ReactNode, useState } from "react";
import { Menu, MenuItem, PopoverOrigin, IconButton } from "@mui/material";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import c from "classnames";
import * as s from "./style.module.scss";

import Button from "../Button";

export type DropdownOption = {
  id?: string;
  content?: ReactNode;
  divider?: boolean;
  className?: string;
  hide?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export type DropdownButtonProps = {
  text?: string;
  loading?: boolean;
  disabled?: boolean;
  anchorOrigin?: PopoverOrigin;
  menuItems?: Array<DropdownOption>;
  menu?: boolean;
  hover?: boolean;
  children?: ({ close }: { close?: () => void }) => ReactNode;
  disableFocus?: boolean;
};

const DropdownButton: FC<DropdownButtonProps> = ({
  text,
  loading,
  disabled,
  menu,
  hover,
  anchorOrigin,
  children,
  menuItems,
  disableFocus
}) => {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  return (
    <div>
      {text && (
        <Button
          aria-haspopup
          aria-controls="drop-down-menu"
          aria-expanded={!!anchorEl}
          disabled={disabled}
          loading={loading}
          text={text}
          onClick={(e) => {
            if (!hover) {
              setAnchorEl(e.currentTarget);
            }
          }}
          onMouseEnter={(e) => {
            if (hover) {
              setAnchorEl(e.currentTarget);
            }
          }}
          IconMUI={anchorEl ? KeyboardArrowUp : KeyboardArrowDownIcon}
        />
      )}
      {!text && (
        <IconButton
          aria-haspopup
          aria-controls="drop-down-menu"
          aria-expanded={!!anchorEl}
          disabled={loading || disabled}
          onClick={(e) => {
            if (!hover) {
              setAnchorEl(e.currentTarget);
            }
          }}
          onMouseEnter={(e) => {
            if (hover) {
              setAnchorEl(e.currentTarget);
            }
          }}
        >
          {anchorEl ? (
            <KeyboardArrowUp sx={{ color: "#0090DB" }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ color: "#0090DB" }} />
          )}
        </IconButton>
      )}
      <Menu
        anchorOrigin={
          anchorOrigin || {
            vertical: "bottom",
            horizontal: "left"
          }
        }
        MenuListProps={{
          "aria-labelledby": "drop-down-button"
        }}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
      >
        {!!children && children?.({ close: () => setAnchorEl(null) })}
        {!children &&
          menuItems
            ?.filter((i) => !!i && !i.hide)
            /* removes double dividers */
            ?.filter((i, indx, arr) => {
              return !(i.divider && arr[indx + 1]?.divider);
            })
            /* removes first and last dividers */
            ?.filter((i, indx, arr) => {
              if (i.divider && indx === 0) {
                return false;
              }
              return !(i.divider && indx === arr.length - 1);
            })
            .map((i, idx) =>
              i.divider ? (
                <Divider key={i.id || idx} className="DropdownMenu__Divider" />
              ) : (
                <MenuItem
                  classes={{
                    root: s["dropDownItem"]
                  }}
                  onClick={() => {
                    i.onClick?.();
                    setAnchorEl(null);
                  }}
                  disabled={i.disabled}
                  key={i.id || idx}
                >
                  {i.content}
                </MenuItem>
              )
            )}
      </Menu>
    </div>
  );
};

export default DropdownButton;
