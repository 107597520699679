// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BOMRX0noU9fLj5maH0MD{margin-top:20px;padding-top:20px;border-top:1px var(--color-dividing-line) solid;white-space:normal;font-size:100%}.BOMRX0noU9fLj5maH0MD h3{text-align:center;color:var(--color-textLabel-default);margin-bottom:10px}.BOMRX0noU9fLj5maH0MD h4{color:var(--color-text-title);text-align:left}.BOMRX0noU9fLj5maH0MD .MP6mXkJeDdnGl_lH1tAb,.BOMRX0noU9fLj5maH0MD .PX5qLxqjcExEAs_QLUqt{margin-bottom:10px}.BOMRX0noU9fLj5maH0MD .pcCVtvdMWQSeumLu9ZcP{display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/ThematicModal/Components/ThematicsInfo/ThematicsInfo.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,gBAAA,CACA,+CAAA,CACA,kBAAA,CACA,cAAA,CAEA,yBACE,iBAAA,CACA,oCAAA,CACA,kBAAA,CAEF,yBACE,6BAAA,CACA,eAAA,CAEF,wFAEE,kBAAA,CAGF,4CACE,YAAA,CACA,sBAAA","sourcesContent":[".appeal_themes {\n  margin-top: 20px;\n  padding-top: 20px;\n  border-top: 1px var(--color-dividing-line) solid;\n  white-space: normal;\n  font-size: 100%;\n\n  h3 {\n    text-align: center;\n    color: var(--color-textLabel-default);\n    margin-bottom: 10px;\n  }\n  h4 {\n    color: var(--color-text-title);\n    text-align: left;\n  }\n  .service,\n  .theme {\n    margin-bottom: 10px;\n  }\n\n  .theme-edit-button {\n    display: flex;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var appealThemes = "BOMRX0noU9fLj5maH0MD";
export var service = "MP6mXkJeDdnGl_lH1tAb";
export var theme = "PX5qLxqjcExEAs_QLUqt";
export var themeEditButton = "pcCVtvdMWQSeumLu9ZcP";
export default ___CSS_LOADER_EXPORT___;
