/**
 * Перевод дат периода из String в Date.
 *
 * @param {String} stringData Дата в формате строки.
 * @returns {Date}
 */
export const stringToDate = function(stringData) {
  if (!stringData) return false;
  stringData = stringData.split(".");
  return new Date(stringData[2], stringData[1] - 1, stringData[0]);
};

/**
 * Перевод дат периода из Date в String.
 *
 * @param {Date} d Дата в формате Date.
 * @returns {String}
 */
export const dateToFormatString = d => {
  let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  let month = d.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let year = d.getFullYear();
  return day + "." + month + "." + year;
};

export const bytesToMegabytes = (bytes: number) => {
  return bytes / 1024 / 1024;
};

export const megabytesToBytes = (megabytes: number) => {
  return megabytes * 1024 * 1024;
};
