import React, { useState } from "react";

import {Button, Select, FormField, isEmpty} from "@omnichat/arm_ui_kit";

import * as cs from '../../../../styles/common.module.scss'
import TextArea from "../../../Dialog/Components/TextArea/TextArea";
import CustomOptionAgent from "../CustomOptionAgent/CustomOptionAgent";
import CustomOptionGroup from "../CustomOptionGroup/CustomOptionGroup";

import * as s from "./TransferAppealModal.module.scss";

interface ITransferList {
  value: string;
  label: string;
  online: boolean;
  count: number;
  avatar?: string;
  group?: string;
  targetValue: {
    userId: number;
    groupId: number;
  };
}

interface IFormData {
  target: string[];
  comment: string;
}
export interface IValidationState {
  target?: string | null;
  comment?: string | null;
}

/**
 * Пропсы компонента
 * @param {string} header Текст заголовка МО
 * @param {IFormData} formData Данные формы для подставления
 * @param {ITransferList} transferList Список для выбора трансфера
 * @param {Function} onSearch Обработчик событие открытие списка
 * @param {Function} onChangeFormData Обработчик на изменение данных формы,
 * @param {Function} onCancelAction Обработчик на отклонение отправки формы
 * @param {Function} onConfirmAction Обработчик на подтверждение отправки формы, после пройденой валидации
 * @param {Function} onNextPage Обработчик на срабатывание пагинации
 * @param {Function} onCloseList Обработчик на закрытие списка
 */
interface ITransferAppealModalProps {
  header: string;
  formData: IFormData;
  transferList: ITransferList[];
  isPendingTargetList?: boolean;
  onSearch: (target: string, query: string) => void;
  onNextPage: (target: string, query: string) => void;
  onChangeFormData: (formData: IFormData) => void;
  onCancelAction?: () => void;
  onConfirmAction?: () => void;
}

const TransferAppealModal: React.FunctionComponent<ITransferAppealModalProps> = ({
  header,
  formData,
  transferList,
  isPendingTargetList,
  onSearch,
  onNextPage = () => {},
  onChangeFormData = () => {},
  onCancelAction = () => {},
  onConfirmAction = () => {}
}) => {
  const targets = ["group", "agent"];
  const [transferTargetIndex, setTransferTargetIndex] = useState(0);
  const [errors, setErrors] = useState<IValidationState>({
    target: null,
    comment: null
  });

  const configurations = {
    group: {
      label: "Группа",
      button: "Агент",
      placeholder: "Введите название группы",
      iconName: "iUser"
    },
    agent: {
      label: "Агент",
      button: "Группа",
      placeholder: "Введите фамилию или имя агента",
      iconName: "iUserGroup"
    }
  };

  const getTargetName = (index: number) => {
    return targets[index];
  };

  const getTransferConfiguration = (targetIndex: number) => {
    return configurations[getTargetName(targetIndex)];
  };

  const [properties, setProperties] = useState(
    getTransferConfiguration(transferTargetIndex)
  );

  const onChangeField = (
    fieldName: string,
    fieldData: string | string[]
  ): void => {
    onChangeFormData({
      ...formData,
      [fieldName]: fieldData
    });
    setErrors(s => ({ ...s, [fieldName]: null }));
  };

  const validation = () => {
    let valid = true;

    if (!formData.comment) {
      valid = false;
      setErrors(s => ({ ...s, comment: "Укажите причину" }));
    } else {
      setErrors(s => ({ ...s, comment: null }));
    }

    if (isEmpty(formData.target)) {
      valid = false;
      setErrors(s => ({
        ...s,
        target: "Укажите агента или группу для перевода"
      }));
    } else {
      setErrors(s => ({ ...s, target: null }));
    }
    return valid;
  };

  const changeConfiguration = () => {
    const newTargetIndex = Number(!transferTargetIndex);
    setTransferTargetIndex(newTargetIndex);
    onSearch && onSearch(getTargetName(newTargetIndex), "", 0);
    setProperties(getTransferConfiguration(newTargetIndex));
    onChangeField("target", []);
  };

  /** Рендер конфиг компонентов CustomOptionGroup и CustomOptionAgent */
  const getOptionComponent = ({ label, ...other }) => {
    if (getTargetName(transferTargetIndex) === "agent") {
      return <CustomOptionAgent label={label} {...other} />;
    }
    return <CustomOptionGroup label={label} {...other} />;
  };

  const onConfirmForm = () => {
    validation() && onConfirmAction();
  };

  const onSelectOption = (selected: string[]) => {
    const selectedOption = transferList.find((element, index, array) => {
      if (element.value === selected[0] && element.targetValue) {
        return element;
      }
    });
    let newData = {
      selectedValue: selected[0],
      ...selectedOption.targetValue
    };
    onChangeField("target", newData);
  };

  return (
    <div className={cs["defaultModal"]}>
      <div className={cs["defaultModalHeader"]}>{header}</div>
      <div className={cs["defaultModalContent"]}>
        <div className={s["modalTargetAppeal"]}>
          <FormField label={properties.label} required>
            <>
              <Select
                options={transferList}
                selected={
                  formData.target["selectedValue"]
                    ? [formData.target["selectedValue"]]
                    : []
                }
                placeholder={properties.placeholder}
                onSelectOption={onSelectOption}
                isSearchable
                onOpen={query => {
                  onSearch(getTargetName(transferTargetIndex), query);
                }}
                onSearch={query => {
                  onSearch(getTargetName(transferTargetIndex), query);
                }}
                isError={errors.target ? true : false}
                actionText={errors.target ? errors.target : undefined}
                onPaginationBottom={() => {
                  onNextPage(getTargetName(transferTargetIndex), "");
                }}
                isPending={isPendingTargetList}
              >
                {getOptionComponent}
              </Select>
              <Button
                type="underline"
                text={properties.button}
                iconName={properties.iconName}
                extraClass={[s["atHeader"]]}
                onClick={changeConfiguration}
              />
            </>
          </FormField>
        </div>
        <FormField label="Причина" required>
          <TextArea
            focus="Не указана"
            placeholder="Не указана"
            onChange={e => {
              onChangeField("comment", e.text);
            }}
            initialValue={""}
            required={true}
            onBlur={() => {}}
            isError={errors.comment ? true : false}
            actionText={errors.comment ? errors.comment : undefined}
          />
        </FormField>
      </div>
      <div className={cs["defaultModalControl"]}>
        <Button
          type="default"
          theme="red"
          text="Отменить"
          onClick={onCancelAction}
        />
        <Button
          type="default"
          theme="green"
          text="Продолжить"
          onClick={onConfirmForm}
        />
      </div>
    </div>
  );
};

export default TransferAppealModal;
