import React, { useState, useCallback, useEffect } from "react";

import debounce from "lodash.debounce";

import {
  Icon,
  Tooltip,
  Dropdown,
  Button,
  ScrollBox
} from "@omnichat/arm_ui_kit";
import { Input, Checkbox } from "../../../../Components";
import RepliesItem from "./RepliesItem";
import { useDidUpdate } from "../../../Common/Hooks/useDidUpdate";

import * as s from "./styles.module.scss";

export interface IRepliesListProps {
  all: boolean;
  repliesList: Reply[];
  onNextPage: () => void;
  onSelect: (id: number) => void;
  onSelectAll: () => void;
  onSearch: (query?: string) => void;
  onRemove: () => void;
  onChangeCategory: () => void;
  onEdit: (id: number) => void;
  onAdd: () => void;
}

export interface Reply {
  id: number;
  text: string;
  title: string;
  category: string;
  checked: boolean;
  disabled: boolean;
  attachmentsCount: number;
}

/**
 * Раздел списка шаблонов
 */
const RepliesList = ({
  all,
  onNextPage,
  onSelect,
  onSelectAll,
  onChangeCategory,
  onEdit,
  onSearch,
  onRemove,
  onAdd,
  repliesList = []
}: IRepliesListProps): JSX.Element => {
  const [query, setQuery] = useState("");

  const delayedSendQuery = useCallback(
    debounce(() => onSearch(query), 500),
    [query]
  );

  const repliesListCount = repliesList.filter(item => item.checked).length;

  useDidUpdate(() => {
    delayedSendQuery();
    return delayedSendQuery.cancel;
  }, [query, delayedSendQuery]);

  return (
    <div className={s["replies"]}>
      <div className={s["repliesHeader"]}>
        <div className={s["repliesHeaderBtnWrap"]}>
          <h4 className={s["repliesTitle"]}>Шаблоны ответов</h4>
          <Tooltip
            content="Добавить шаблон"
            position="left"
            modifiers={{ offset: 10 }}
          >
            <div className={s["repliesAdd"]} onClick={onAdd}>
              <Icon
                color={"blue"}
                name={"plus_bold"}
                width="18px"
                height="18px"
              />
            </div>
          </Tooltip>
        </div>

        <div>
          <Input
            placeholder="Введите название шаблона"
            onChange={value => setQuery(value)}
            value={query}
          />
        </div>
        <div className={s["repliesControl"]}>
          <Checkbox
            caption="Выбрать все"
            checked={all}
            onChange={() => onSelectAll()}
            disabled={!repliesList.length}
          />

          <Dropdown
            id="1"
            text=""
            items={[
              { id: 0, text: "Перенести в категорию" },
              { id: 1, text: "Удалить шаблон" }
            ]}
            onChange={id => {
              id === 0 ? onChangeCategory() : onRemove();
            }}
            extraClass={[s["actionDropdownBtn"]]}
            component={(onOpen, ref: React.RefObject<HTMLButtonElement>) => (
              <Tooltip
                content="Действия"
                position="top"
                modifiers={{ offset: 10 }}
                isDisabled={!repliesListCount}
              >
                <Button
                  ref={ref}
                  iconName="more"
                  iconColor="blue"
                  type="bare"
                  onClick={onOpen}
                  disabled={!repliesListCount}
                />
              </Tooltip>
            )}
          />
        </div>
      </div>

      {repliesList?.length ? (
        <div className={s["repliesList"]}>
          <ScrollBox
            autoHeight
            hasScrollControlBackground
            onPaginationBottom={() => onNextPage()}
          >
            <div className={s["repliesListContentBlock"]}>
              <div className={s["marginContentBlock"]}></div>
              {repliesList.length &&
                repliesList.map((item, i) => (
                  <RepliesItem
                    key={i}
                    id={item.id}
                    disabled={all}
                    category={item.category}
                    checked={item.checked}
                    title={item.title}
                    text={item.text}
                    attachmentsCount={item.attachmentsCount}
                    onSelect={id => onSelect(id)}
                    onEdit={id => onEdit(id)}
                  />
                ))}
            </div>
          </ScrollBox>
        </div>
      ) : (
        <div className={s["repliesEmpty"]}>Нет совпадений</div>
      )}
    </div>
  );
};

export default RepliesList;
