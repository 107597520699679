import { takeEvery, put, select } from "redux-saga/effects";

import { httpRequest } from "../../ReactCore/shared/httpRequest";
import { ISelect } from "../Common/Interfaces/Store.interfaces";
import { GET_USERS_PROFILE_DATA } from "./consts";
import { UsersProfileTypes, Data } from "./usersProfile";
import {
  usersProfileDataUpdated,
  setUsersProfileData,
  setPhoto
} from "./usersProfile.action";

export function* getUsersProfileData(action) {
  const formData: Data = yield select<ISelect>(
    ({ UsersProfile }) => UsersProfile.formData
  );
  const profileId = action.payload.data;

  try {
    const res = yield httpRequest("GET", `bff/settings/profiles/${profileId}/`);

    if (res?.success) {
      const formedData = ((
        usersProfileData: UsersProfileTypes.DTO,
        formData: Data
      ): Data => {
        return {
          ...formData,
          id: { ...formData.id, value: usersProfileData.id },
          file: { ...formData.file, value: usersProfileData.photo },
          firstname: {
            ...formData.firstname,
            value: usersProfileData.name || ""
          },
          lastname: {
            ...formData.lastname,
            value: usersProfileData.secName || ""
          },
          nickname: {
            ...formData.nickname,
            value: usersProfileData.penName || ""
          },
          email: { ...formData.email, value: usersProfileData.email || "" },
          role: { ...formData.role, value: usersProfileData.roles || [] },
          personnelNumber: {
            ...formData.personnelNumber,
            value: usersProfileData.personnelNumber || null
          },
          phoneNumber: {
            ...formData.phoneNumber,
            value: usersProfileData.phoneNumber || null
          },
          yearOfBirth: {
            ...formData.yearOfBirth,
            value: usersProfileData.yearOfBirth
              ? String(usersProfileData.yearOfBirth)
              : null
          },
          rate: {
            ...formData.rate,
            value: usersProfileData.rate ? [usersProfileData.rate] : []
          },
          organizationId: {
            ...formData.organizationId,
            value: usersProfileData.organizationId
              ? [usersProfileData.organizationId]
              : []
          },
          territoryId: {
            ...formData.territoryId,
            value: usersProfileData.territoryId
              ? [usersProfileData.territoryId]
              : []
          },
          areaId: {
            ...formData.areaId,
            value: usersProfileData.areaId ? [usersProfileData.areaId] : []
          },
          skillGroupId: {
            ...formData.skillGroupId,
            value: usersProfileData.skillGroups || []
          },
          crmLogin: {
            ...formData.crmLogin,
            value: usersProfileData.crmLogin
          }
        };
      })(res.data, formData);

      yield put(setPhoto(res.data?.photo));
      yield put(usersProfileDataUpdated(formedData));
      yield put(setUsersProfileData(res?.data));
    }
  } catch ($error) {
    console.error($error);
  }
}

export default function* () {
  yield takeEvery(GET_USERS_PROFILE_DATA, getUsersProfileData);
}
