import { removeOfflineTimerFromStorage } from "../ReactFeatures/OnlineToggle/Utils";

export default {
  draw: {},

  nav: {
    init: function() {
      setTimeout(function() {
        if ($("input[name=user_login]").val().length > 0) {
          $("input[name=user_login]")
            .parent()
            .addClass("filled");
          $("input[name=user_login]")
            .parent()
            .addClass("filled");
        }
      }, 100);
      $("input[name=user_login]").focus();
      $("body").show();
    }
  },

  authSubmit: function() {
    if ($("#authForm_submit").hasClass("load")) return false;
    else $("#authForm_submit").addClass("load");
    $.ajax({
      url: "/workspace?sec=auth_submit",
      data: $("#auth").serialize(),
      dataType: "JSON",
      type: "POST",
      cache: false,
      success: function(response) {
        $("#authForm_submit").removeClass("load");
        removeOfflineTimerFromStorage();

        if (core.form.check($("#auth"), "authForm", response.error)) {
          location.reload(true);
        } else $("input[name=user_login]").focus();

        workspace.auth.cardSize();
      }
    });
  },

  cardSize: function() {
    var height =
      $("#auth")
        .children(".wsForm_block")
        .outerHeight(true) +
      $("#auth")
        .children(".logo")
        .outerHeight(true);
    $(".welcome").css({
      height: height,
      marginTop: -height / 2
    });
  }
};
