import { takeEvery, put, select } from "redux-saga/effects";

import { httpRequest } from "../../ReactCore/shared/httpRequest";
import { isValidValue } from "../../ReactCore/shared/formValidators";

import Informer from "../../arm/Wrappers/Informer";

import { SEND_USERS_PROFILE_DATA } from "./consts";
import { UsersProfileTypes, Data } from "./usersProfile";

import {
  resetUsersProfileData,
  showUsersProfileError,
  setUsersProfileEmailError,
  setUsersProfileResponseError
} from "./usersProfile.action";
import { ISelect } from "../Common/Interfaces/Store.interfaces";

export function* sendUsersProfileSaga(action) {
  const formData = yield select<ISelect>(
    ({ UsersProfile }) => UsersProfile.formData
  );

  const { close: closeModal, administratorId } = action.payload.data;

  const validateUserProfileData = ((data: Data) => {
    const result = Object.keys(data).reduce((acc, key) => {
      if (
        (data[key].required && !isValidValue(data[key].value)) ||
        data[key].error
      ) {
        acc[key] = { ...data[key], error: true };
      }
      return acc;
    }, {});

    if (result) return result;
  })(formData);

  if (Object.keys(validateUserProfileData).length) {
    const info = new Informer("Исправьте ошибки перед сохранением", 4500);
    info.show();
    yield put(showUsersProfileError(validateUserProfileData));
    return;
  }

  const preparedUsersProfileData = ((
    data: Data,
    administratorId
  ): UsersProfileTypes.DTO => ({
    id: data.id.value,
    photo: data.file.value ? data.file.value : null,
    administratorId: administratorId,
    name: data.firstname.value || null,
    secName: data.lastname.value || null,
    penName: data.nickname.value || null,
    email: data.email.value,
    phoneNumber: data.phoneNumber.value === "" ? null : data.phoneNumber.value,
    yearOfBirth: data.yearOfBirth.value
      ? String(data.yearOfBirth.value)
      : null,
    personnelNumber:
      data.personnelNumber.value === "" ? null : data.personnelNumber.value,
    areaId: data.areaId.value[0],
    organizationId: data.organizationId.value[0],
    territoryId: data.territoryId.value[0],
    rate: data.rate.value[0],
    skillGroups: data.skillGroupId.value,
    roles: data.role.value,
    crmLogin: data.crmLogin.value ? data.crmLogin.value : null
  }))(formData, administratorId);

  try {
    const { id } = preparedUsersProfileData;
    const method = id ? "PUT" : "POST";
    const path = id ? `bff/settings/profiles/${id}/` : `bff/settings/profiles/`;
    const informerMessage = id
      ? "Пользователь был отредактирован"
      : "Пользователь был создан";

    const res = yield httpRequest(method, path, {
      body: preparedUsersProfileData
    });

    if (res?.success) {
      const info = new Informer(informerMessage, 4500);
      info.show();
      yield put(resetUsersProfileData());
    } else {
      yield put(
        setUsersProfileEmailError(
          "Данная почта уже используется другим пользователем"
        )
      );
      yield put(
        setUsersProfileResponseError("Проверьте правильность заполненных полей")
      );
    }

    closeModal();
  } catch ($error) {
    console.error($error);
  }
}

export default function* () {
  yield takeEvery(SEND_USERS_PROFILE_DATA, sendUsersProfileSaga);
}
