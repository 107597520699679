import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import FormFieldConnector from "../../../Components/FormFieldConnector";
import Checkbox from "../../../Components/Checkbox";

import { StoreState } from "../../../ReactCore/store/types";
import { IAction } from "../../../ReactFeatures/Common/Interfaces/Store.interfaces";
import {
  isEmpty,
  Button,
  TextareaDump,
  REQUIRED_FIELD_ERROR,
  ScrollBox,
  Preloader
} from "@omnichat/arm_ui_kit";

import SettingsSection from "../../../ReactFeatures/Common/Components/SettingsSection/SettingsSection";

import { ReactHookFormErrorHandler } from "../../../Utils/HookForm.helper";

import {
  getAutorepliesSettings,
  sendAutorepliesSettings
} from "../Actions/Autoreplies.actions";
import { resetAutorepliesSettings } from "../Reducers/Autoreplies.reducer";
import * as autorepliesSelectors from "../Autoreplies.selectors";
import { GettingItemSettings, SendingItemSettings } from "../Interfaces";

const schema = Yup.object({
  items: Yup.array().of(
    Yup.object().shape({
      active: Yup.boolean().nullable(),
      id: Yup.number(),
      name: Yup.string().nullable(),
      value: Yup.string().required(REQUIRED_FIELD_ERROR),
      description: Yup.string().nullable()
    })
  )
});
const defaultValues = {
  items: []
};

export interface IForm extends Yup.InferType<typeof schema> {}

/**
 * Модель собственных свойств компонента.
 *
 */
interface IOwnProps {}

/**
 * Модель свойств, полученных из store.
 *
 */
interface IStateProps {
  settingsList: GettingItemSettings[];
}

/**
 * Модель экшенов.
 *
 */
interface IDispatchProps {
  getAutorepliesSettings: () => IAction<any>;
  resetAutorepliesSettings: () => IAction<any>;
  sendAutorepliesSettings: (
    data: SendingItemSettings[]
  ) => ActionCreatorWithPayload<SendingItemSettings, string>;
}

type AutorepliesSettingsProviderProps = IOwnProps &
  IStateProps &
  IDispatchProps;

const AutorepliesSettingsProvider: FC<AutorepliesSettingsProviderProps> = ({
  settingsList,
  getAutorepliesSettings,
  sendAutorepliesSettings,
  resetAutorepliesSettings
}) => {
  const methods = useForm<IForm>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset, watch, formState } = methods;
  const { isDirty } = formState;
  const items = watch("items");

  useEffect(() => {
    if (!isEmpty(settingsList)) {
      reset({
        items: settingsList.map((s) => ({
          id: s.id,
          value: s.value,
          name: s.sectionName,
          active: s.active,
          description: s.description
        }))
      });
    }
  }, [settingsList]);

  useEffect(() => {
    getAutorepliesSettings();
    return () => {
      resetAutorepliesSettings();
    };
  }, []);

  const switchItems = (item: any, i): JSX.Element | void => (
    <div style={{ margin: "15px 0 15px 0" }} key={item.id}>
      <FormFieldConnector
        required
        label={item.name}
        name={`items.${i}.value`}
        Component={(p, state) => (
          <TextareaDump
            {...p}
            placeholder="Не указано"
            theme={!isEmpty(state.error) ? "error" : undefined}
            value={p.value}
            onChange={p.onChange}
            rows={4}
          />
        )}
        actionText={item.description}
        customTitle={
          typeof item.active === "boolean" && (
            <FormFieldConnector
              name={`items.${i}.active`}
              Component={(p, state) => (
                <div style={{ margin: "3px 0 -8px 15px" }}>
                  <Checkbox
                    {...p}
                    type="slider"
                    checked={p.value}
                    onChange={p.onChange}
                  />
                </div>
              )}
            />
          )
        }
      />
    </div>
  );

  const onSend = (data: IForm) => {
    const result = settingsList.map((fieldSetting) => {
      const field = data.items.find((i) => i.id === fieldSetting.id);
      return {
        id: fieldSetting.id,
        value: field.value,
        active: field.active
      };
    });
    sendAutorepliesSettings(result);
  };

  return (
    <SettingsSection
      title="Редактирование автоответов"
      content={
        <Preloader size="mini" show={settingsList === null}>
          {isEmpty(settingsList) ? (
            <>Пока настройки отсутствуют. Обратитесь в техподдержку.</>
          ) : (
            <ScrollBox
              autoHeight
              hasScrollControlBackground
              autoHeightMax={530}
            >
              <div style={{ marginRight: "20px" }}>
                <FormProvider {...methods}>
                  {items.map(switchItems)}
                  <Button
                    type="default"
                    text="Сохранить"
                    disabled={!isDirty}
                    theme={!isDirty ? "disabled" : "green"}
                    onClick={handleSubmit(
                      (data) => onSend(data),
                      ReactHookFormErrorHandler
                    )}
                  />
                </FormProvider>
              </div>
            </ScrollBox>
          )}
        </Preloader>
      }
    />
  );
};

const mapStateToProps = (state: StoreState.State): IStateProps => ({
  settingsList: autorepliesSelectors.getAutorepliesData(state)
});

const mapDispatchToProps = (dispatch) => ({
  getAutorepliesSettings: () => dispatch(getAutorepliesSettings()),
  sendAutorepliesSettings: (data) => dispatch(sendAutorepliesSettings(data)),
  resetAutorepliesSettings: () => dispatch(resetAutorepliesSettings())
});

const Connect = connect(
  mapStateToProps,
  mapDispatchToProps
)(AutorepliesSettingsProvider);

export default Connect;
