import { fork } from "redux-saga/effects";

import sendIncidentToSkuf from "./sendIncidentToSkuf.saga";
import getSkufLevelsSaga from "./getSkufCatalogs.saga";
import getIncidentStatus from "./getSkufStatus.saga";

export default function*() {
  yield fork(sendIncidentToSkuf);
  yield fork(getSkufLevelsSaga);
  yield fork(getIncidentStatus);
}
