/**
 * Преобразует URI с помощью decodeURIComponent и заменит искомые символы на указанные
 * при передаче параметров.
 *
 * @param {string} stringToDecode Строка для декодирования.
 * @param {string | RegExp} [searchValue] Значение для поиска в декодированной строке.
 * @param {string} [replaceValue] Значение для замены в декодированной строке.
 */
export function decodeAndReplaceURI(
  stringToDecode: string,
  searchValue?: string | RegExp,
  replaceValue?: string
): string {
  let result = "";

  if (stringToDecode) {
    result = decodeURIComponent(stringToDecode);
  }

  if (searchValue && replaceValue) {
    result = result.replace(searchValue, replaceValue);
  }

  return result;
}
