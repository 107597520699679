import produce from "immer";

import {
  SET_AREAS_LIST,
  SET_ORGANIZATIONS_LIST,
  SET_SKILL_GROUPS_LIST,
  SET_TERRITORIES_LIST,
  USERS_MULTI_PROFILE_DATA_UPDATED,
  SHOW_MULTI_USERS_PROFILE_DATA_ERROR,
  SEND_USERS_MULTI_PROFILE_DATA,
  SET_MULTI_USERS_PROFILE_TEXT_ERROR,
  SET_PROFILE_IDS,
  RESET_USERS_MULTI_PROFILE_DATA
} from "./consts";

import initialState from "../../ReactCore/store/initialState";

export default function usersProfileModalReducer(
  state = initialState.UsersMultiProfile,
  { type, payload }
) {
  switch (type) {
    case SET_PROFILE_IDS:
      return produce(state, draft => {
        draft.checkedIds = payload.data;
      });
    case SET_AREAS_LIST:
      return produce(state, draft => {
        draft.status.areasLoaded = true;
        draft.areas = payload.data;
      });
    case SET_ORGANIZATIONS_LIST:
      return produce(state, draft => {
        draft.status.organizationsLoaded = true;
        draft.organizations = payload.data;
      });
    case SET_SKILL_GROUPS_LIST:
      return produce(state, draft => {
        draft.status.skillGroupsLoaded = true;
        draft.skillGroups = payload.data;
      });
    case SET_TERRITORIES_LIST:
      return produce(state, draft => {
        draft.status.territoriesLoaded = true;
        draft.territories = payload.data;
      });
    case USERS_MULTI_PROFILE_DATA_UPDATED:
      return produce(state, draft => {
        draft.formData = payload.data;
      });
    case SHOW_MULTI_USERS_PROFILE_DATA_ERROR:
      return produce(state, draft => {
        draft.status.usersProfileHandling = false;
        draft.formData = { ...draft.formData, ...payload.data };
      });
    case SET_MULTI_USERS_PROFILE_TEXT_ERROR:
      return produce(state, draft => {
        draft.errorText = payload.data;
      });
    case SEND_USERS_MULTI_PROFILE_DATA:
      return produce(state, draft => {
        draft.status.usersProfileHandling = true;
      });
    case RESET_USERS_MULTI_PROFILE_DATA:
      return produce(state, draft => {
        draft.formData = initialState.UsersMultiProfile.formData;
      });
    default:
      return state;
  }
}
