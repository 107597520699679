import React, { BaseSyntheticEvent, useRef } from "react";

import c from "classnames";
import { CheckboxProps, FormControlLabel } from "@mui/material";
import { Checkbox as MuiCheckbox, Switch as MuiSwitch } from "@mui/material";

/**
 * Свойства компонента Checkbox.
 */
export interface ICheckboxProps extends CheckboxProps {
  /** Тип Checkbox */
  type?: "square" | "slider";
  disabled?: boolean;
  onChange?: (e?: BaseSyntheticEvent) => void;
  extraClass?: (string | Record<string, unknown>)[];
  checked?: boolean;
  caption?: string;
  id?: string;
}

/**
 * Компонент Checkbox.
 */
const Checkbox = ({
  type = "square",
  disabled,
  extraClass = [],
  onChange = () => {},
  checked,
  caption,
  ...props
}: ICheckboxProps) => {
  const ref = useRef(null);
  const CheckboxComponent =
    type === "square" ? (
      <MuiCheckbox
        ref={ref}
        className={c(...extraClass)}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        sx={{
          "& .MuiSvgIcon-root": {
            fontSize: "24px"
          }
        }}
        {...props}
      />
    ) : (
      <MuiSwitch
        ref={ref}
        className={c(...extraClass)}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        {...props}
      />
    );

  if (caption) {
    return <FormControlLabel label={caption} control={CheckboxComponent} />;
  }

  return CheckboxComponent;
};

export default Checkbox;
