import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../ReactCore/shared/httpRequest";
import { GET_USERS_PROFILE_CATALOGS } from "./consts";
import {
  setAreasList,
  setOrganizationsList,
  setSkillGroupList,
  setTerritoriesList,
  setRolesList
} from "./usersProfile.action";

export function* getUsersProfileCatalogsSaga(action) {
  const profileId = action.payload.data;
  try {
    const catalogs = yield httpRequest(
      "GET",
      `bff/settings/profiles/${profileId}/catalogs`
    );

    if (catalogs?.areas?.success) {
      yield put(setAreasList(catalogs.areas.data));
    }
    if (catalogs?.organizations?.success) {
      yield put(setOrganizationsList(catalogs.organizations.data));
    }
    if (catalogs?.territories?.success) {
      yield put(setTerritoriesList(catalogs.territories.data));
    }
    if (catalogs?.skillGroups?.success) {
      yield put(setSkillGroupList(catalogs.skillGroups.data));
    }
    if (catalogs?.roles?.success) {
      yield put(setRolesList(catalogs.roles.data));
    }
  } catch ($error) {
    console.error($error);
  }
}

export default function* () {
  yield takeEvery(GET_USERS_PROFILE_CATALOGS, getUsersProfileCatalogsSaga);
}
