import { takeEvery } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import Informer from "../../../arm/Wrappers/Informer";
import { sendUserlogoff } from "../Store/OnlineToggle.reducer";
import { logoutPhpSession } from "../Utils";

import { logoutUser } from "../Utils";

function* sendUserLogoffSaga() {
  const response = yield httpRequest("PUT", `bff/user/logoff`);

  if (response) {
    logoutUser();
  } else {
    const informer = new Informer("Произошла ошибка. Попробуйте еще раз", 1500);
    informer.show();
  }
}

export default function*() {
  yield takeEvery([sendUserlogoff], sendUserLogoffSaga);
}
