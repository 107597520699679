import { isEmpty } from "@omnichat/arm_ui_kit";

import {
  IValidationRule,
  IValidationResult,
  IRuleErrorTextFunc,
} from "../Interfaces/IValidation";

/**
 * Валидирует поля формы по переданным правилам.
 *
 * @param {any} form Поля формы.
 * @param {IValidationRule[]} rules Правила валидации.
 */
export const validate = (
  form: any,
  rules: IValidationRule[]
): IValidationResult => {
  let isValid = true;
  let errors = {};

  function validateField(keyRules: IValidationRule[], key: string) {
    keyRules.forEach((rule: IValidationRule) => {
      // Если поле обязательное или непустое, то проверяем, иначе считаем валидным.
      if (rule.isRequired || !isEmpty(form[key])) {
        const [isFieldValid, options] = rule.rule(form[key]);

        if (!isFieldValid) {
          let errorText =
            options && options.templateText
              ? (rule.errorText as IRuleErrorTextFunc)(options.templateText)
              : rule.errorText;

          errors[key] = !isEmpty(errors[key]) ? [...errors[key]] : [];
          isValid = false;
          errors[key].push(errorText);
        }
      }
    });
  }

  if (!isEmpty(form) && !isEmpty(rules)) {
    for (var key in form) {
      const keyRules = rules.filter(
        (rule: IValidationRule) => rule.fieldName === key
      );

      if (isEmpty(keyRules)) continue;

      errors[key] = null;

      validateField(keyRules, key);
    }
  }

  return { isValid, errors };
};

/**
 *  Кастомные правила валидации.
 */
export const validationRules = {
  isNotEmpty: (field: any): [boolean] => [!isEmpty(field)],
};

/**
 * Возвратит строку если есть или вернет пустую строку.
 *
 * @param {string | null | undefined} field Поле.
 */
export function getString(field: string | null | undefined): string {
  return field || "";
}

/**
 * Проверит является ли значение null или undefined.
 *
 * @param {any} value Значение.
 */
export function isNil(value: any) {
  return value == null;
}
