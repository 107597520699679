import produce from "immer";

import initialState from "../../ReactCore/store/initialState";

import {
  SKUF_INCIDENT_DATA_UPDATED,
  SET_INCIDENT_APPEAL_ID,
  SET_INCIDENT_SKUF_ID,
  SEND_INCIDENT_TO_SKUF,
  SKUF_INCIDENT_CREATED,
  SKUF_INCIDENT_FAILED,
  LOADED_SKUF_REGIONS,
  LOADED_SKUF_LEVELS,
  SHOW_INCIDENT_DATA_ERRORS,
  SKUF_INCIDENT_SEND_ERROR,
  RESET_INCIDENT_DATA
} from "./consts";

export default function incidentSkufReducer(
  state = initialState.IncidentSkuf,
  { type, payload }
) {
  switch (type) {
    case SET_INCIDENT_APPEAL_ID:
      return produce(state, draft => {
        draft.appealId = payload.data;
      });
    case SKUF_INCIDENT_DATA_UPDATED:
      return produce(state, draft => {
        draft.incidentData = payload.data;
      });
    case RESET_INCIDENT_DATA:
      return produce(state, draft => {
        draft.status = initialState.IncidentSkuf.status;
        draft.incidentData = initialState.IncidentSkuf.incidentData;
        draft.incidentError = initialState.IncidentSkuf.incidentError;
      });
    case SHOW_INCIDENT_DATA_ERRORS:
      return produce(state, draft => {
        draft.status.incidentHandling = false;
        draft.incidentData = { ...draft.incidentData, ...payload.data };
      });
    case LOADED_SKUF_REGIONS:
      return produce(state, draft => {
        draft.status.regionsLoaded = true;
        draft.regions = [...draft.regions, ...payload.data];
      });
    case LOADED_SKUF_LEVELS:
      return produce(state, draft => {
        draft.status.levelsLoaded = true;
        draft.levels = payload.data;
      });
    case LOADED_SKUF_REGIONS:
      return produce(state, draft => {
        draft.status.regionsLoaded = true;
        draft.regions = payload.data;
      });
    case SEND_INCIDENT_TO_SKUF:
      return produce(state, draft => {
        draft.status.incidentHandling = true;
      });
    case SKUF_INCIDENT_CREATED:
      return produce(state, draft => {
        draft.status.incidentHandling = false;
        draft.status.incidentCreated = true;
      });
    case SKUF_INCIDENT_FAILED:
      return produce(state, draft => {
        draft.status.incidentFailed = true;
      });
    case SKUF_INCIDENT_SEND_ERROR:
      return produce(state, draft => {
        draft.status.incidentHandling = false;
        draft.incidentError = true;
      });
    case SET_INCIDENT_SKUF_ID:
      return produce(state, draft => {
        draft.skufId = payload.data;
      });
    default:
      return state;
  }
}
