import { takeEvery, put, select } from "redux-saga/effects";
import {
  GET_INCIDENT_SKUF_STATUS,
  INCIDENT_STATUS_ACTIVE,
  INCIDENT_STATUS_CREATED,
  INCIDENT_STATUS_FAILED
} from "./consts";

import {
  setSkufIncidentCreated,
  setSkufIncidentFailed,
  setSkufIncidentId,
  setSkufIncidentSendError
} from "./incidentSkuf.actions";
import { httpRequest } from "../../ReactCore/shared/httpRequest";
import { ISelect } from "../Common/Interfaces/Store.interfaces";

function* getIncidentStatus() {
  const appealId = yield select<ISelect>(
    ({ IncidentSkuf }) => IncidentSkuf.appealId
  );
  try {
    const response = yield httpRequest(
      "GET",
      `bff/chat/appeals/${appealId}/skuf-incident`
    );

    switch (response?.success) {
      case INCIDENT_STATUS_CREATED:
        yield put(setSkufIncidentId(response.data?.incId));
        yield put(setSkufIncidentCreated());
      case INCIDENT_STATUS_FAILED:
        yield put(setSkufIncidentFailed());
      case INCIDENT_STATUS_ACTIVE:
      default:
    }
  } catch ($error) {
    console.log($error);
  }
}

export default function* () {
  yield takeEvery(GET_INCIDENT_SKUF_STATUS, getIncidentStatus);
}
