import { takeEvery, put, select } from "redux-saga/effects";

import { httpRequest } from "../../ReactCore/shared/httpRequest";
import { SEND_USERS_MULTI_PROFILE_DATA } from "./consts";
import { UsersMultiProfileTypes, UsersMultiData } from "./usersMultiProfile";

import {
  showUsersMultiProfileError,
  setUsersMultiProfileTextError
} from "./usersMultiProfile.action";

import Informer from "../../arm/Wrappers/Informer";
import { isValidValue } from "../../ReactCore/shared/formValidators";
import { ISelect } from "../Common/Interfaces/Store.interfaces";

export function* sendUsersMultiProfileSaga(action) {
  const formData = yield select<ISelect>(
    ({ UsersMultiProfile }) => UsersMultiProfile.formData
  );

  const checkedIds = yield select<ISelect>(
    ({ UsersMultiProfile }) => UsersMultiProfile.checkedIds
  );

  const close = action.payload.data;

  yield put(setUsersMultiProfileTextError(""));

  const validateUsersMultiProfileData = ((data: UsersMultiData) => {
    const result = Object.keys(data).reduce((acc, key) => {
      if (data[key].required && !isValidValue(data[key].value)) {
        acc[key] = { ...data[key], error: true };
      }
      return acc;
    }, {});

    if (result) return result;
  })(formData);

  if (Object.keys(validateUsersMultiProfileData).length) {
    yield put(showUsersMultiProfileError(validateUsersMultiProfileData));
    yield put(setUsersMultiProfileTextError("Заполните обязательные поля"));
    const informer = new Informer("Исправьте ошибки перед сохранением", 3000);
    informer.show();
    return;
  }

  const preparedUsersMultiProfileData = ((
    data: UsersMultiData,
    checkedIds: number[]
  ): UsersMultiProfileTypes.DTO => ({
    userIds: checkedIds,
    appealLimit: data.appeals.value,
    skillGroupIds: data.skillGroup.value,
    userAreaId: data.areasData.value,
    userOrganizationId: data.signOfOrganization.value,
    userTerritoryId: data.territory.value
  }))(formData, checkedIds);

  try {
    const res = yield httpRequest(
      "PUT",
      "bff/settings/profiles/update-common-params",
      {
        body: preparedUsersMultiProfileData
      }
    );

    if (res?.success) {
      const informer = new Informer("Профиль успешно отредактирован", 3000);
      informer.show();
    } else {
      const informer = new Informer("Не удалось отредактировать профиль", 3000);
      informer.show();
      console.error(res?.error);
    }
    close();
  } catch ($error) {
    const informer = new Informer("Ошибка", 3000);
    informer.show();
    console.error($error);
  }
}

export default function* () {
  yield takeEvery(SEND_USERS_MULTI_PROFILE_DATA, sendUsersMultiProfileSaga);
}
