import React, { FC, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button, Select, REQUIRED_FIELD_ERROR } from "@omnichat/arm_ui_kit";

import { Input } from "../../../../Components";
import Checkbox from "../../../../Components/Checkbox";
import FormFieldConnector from "../../../../Components/FormFieldConnector";

import * as s from "./style.module.scss";

import { ReactHookFormErrorHandler } from "../../../../Utils/HookForm.helper";

interface Data {
  id?: number | null;
  externalId?: number;
  name: string;
  channelIds: number[];
  mrfIds: number[];
  skillGroupIds: number[];
  isForAll: boolean;
  status: number;
}

interface Channels {
  value: number | string;
  label: string;
}
export interface Mrfs {
  value: number | string;
  label: string;
}
interface SkillGroups {
  value: number | string;
  label: string;
}

export interface IClassifierData {
  loading: boolean;
  data?: Data;
  channels: Channels[];
  mrfs: Mrfs[];
  skillGroups: SkillGroups[];
  errorMessage: string | null;
  onRemove: () => void;
  onSave: (data: IClassifierForm) => void;
}

const MrfList = ({
  mrfList,
  disabled,
  checkedList = [],
  onChange
}: any): JSX.Element => {
  const result = mrfList.map((mrf: Mrfs, i: number) => {
    const valueIndex = checkedList?.findIndex(
      (item: any) => item === mrf.value
    );
    const checked = valueIndex !== -1 ? true : false;
    const onMrfChange = () => {
      if (!checked) {
        onChange([...checkedList, mrf.value]);
      } else {
        const newList = [...checkedList];

        newList.splice(valueIndex, 1);
        onChange(newList);
      }
    };
    return (
      <div className={s["mrf"]} key={i}>
        <Checkbox
          caption={mrf.label}
          checked={checked ? true : false}
          disabled={disabled}
          onChange={onMrfChange}
        />
      </div>
    );
  });
  return result;
};

const schema = Yup.object({
  name: Yup.string().trim().required(REQUIRED_FIELD_ERROR),
  isForAll: Yup.boolean(),
  channels: Yup.array(),
  mrfs: Yup.array(),
  skillGroups: Yup.array()
});

const defaultValues = {
  name: "",
  isForAll: false,
  channels: [],
  mrfs: [],
  skillGroups: []
};

export interface IClassifierForm extends Yup.InferType<typeof schema> {}

const ClassifierForm: FC<IClassifierData> = ({
  loading,
  data,
  channels,
  mrfs,
  skillGroups,
  errorMessage,
  onRemove,
  onSave
}): JSX.Element => {
  const methods = useForm<IClassifierForm>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset, watch } = methods;

  const isForAll = watch("isForAll");

  useEffect(() => {
    if (data) {
      reset({
        name: data.name || "",
        isForAll: data.isForAll || false,
        channels: data.channelIds || [],
        mrfs: data.mrfIds || [],
        skillGroups: data.skillGroupIds || []
      });
    } else {
      reset(defaultValues);
    }
  }, [data]);

  return (
    <FormProvider {...methods}>
      <div className={s["classifierModal"]}>
        <header className={s["header"]}>
          {data?.id === null
            ? "Новый классификатор"
            : "Редактирование классификатора"}
        </header>

        <div className={s["name"]}>
          <FormFieldConnector
            name="name"
            Component={(p) => (
              <Input
                {...p}
                required
                label="Название"
                onChange={p.onChange}
                value={p.value}
                disabled={data?.externalId}
              />
            )}
          />
        </div>

        <div className={s["forAll"]}>
          <FormFieldConnector
            name="isForAll"
            Component={(p) => (
              <Checkbox
                {...p}
                type="slider"
                caption="Отображать для не занятых групп"
                checked={p.value}
              />
            )}
          />
        </div>

        {!isForAll && (
          <>
            {ENABLE_CLASSIFIER_CHANNELS === "1" && (
              <div className={s["channels"]}>
                <FormFieldConnector
                  name="channels"
                  label="Каналы"
                  Component={(p) => (
                    <Select
                      {...p}
                      isMulti
                      label={""}
                      options={channels}
                      selected={p.value}
                      onSelectOption={p.onChange}
                      disabled={isForAll}
                    />
                  )}
                />
              </div>
            )}
            {ENABLE_CLASSIFIER_MRF === "1" && (
              <div className={s["mrfContainer"]}>
                <FormFieldConnector
                  name="mrfs"
                  label="МРФ"
                  actionText="Созданный классификатор будет применен только для выбранных
                скилл-групп"
                  Component={(p) => (
                    <MrfList
                      {...p}
                      disabled={isForAll}
                      mrfList={mrfs}
                      checkedList={p.value}
                    />
                  )}
                />
              </div>
            )}
          </>
        )}
        <FormFieldConnector
          name="skillGroups"
          label="Группы"
          actionText={
            isForAll
              ? "Созданный классификатор будет применен для всех свободных скилл-групп"
              : "Созданный классификатор будет применен только для выбранных скилл-групп"
          }
          Component={(p) => (
            <Select
              {...p}
              isMulti
              disabled={isForAll}
              label={""}
              options={skillGroups}
              selected={isForAll ? skillGroups.map((s) => s.value) : p.value}
              onSelectOption={p.onChange}
            />
          )}
        />
        <div className={s["notification"]}>{errorMessage}</div>
        <div className={s["buttons"]}>
          <Button
            type="default"
            iconName="check"
            iconColor="white"
            theme={loading ? "disabled" : "green"}
            onClick={handleSubmit(
              (formData) => onSave(formData),
              ReactHookFormErrorHandler
            )}
            text="Cохранить"
          />
          {data?.id && (
            <Button
              type="default"
              iconName="cross"
              iconColor="white"
              theme={loading ? "disabled" : "red"}
              onClick={loading ? () => null : onRemove}
              text="Удалить"
            />
          )}
        </div>
      </div>
    </FormProvider>
  );
};

export default ClassifierForm;
