import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

import produce from "immer";

import { Preloader, ScrollBox, Button } from "@omnichat/arm_ui_kit";

import SubTitle from "../../SkillGroupModal/Components/SubTitle/SubTitle";

import { StoreState } from "../../../ReactCore/store/types";
import ModalWindow from "../../../Components/ModalWindow";
import { Data } from "../ClassifierForm";
import {
  getClassifierCatalogs,
  sendClassifierData,
  getClassifierData,
  resetClassifierData,
  removeClassifier
} from "../Store/ClassifierForm.reducer";
import * as selectors from "../Store/ClassifierForm.selector";

import ClassifierForm from "../Components/ClassifierForm";

import * as s from "./style.module.scss";

const filterDataByCatalog = (catalog, data) => {
  return data?.filter((value) => catalog.find((item) => item.value === value));
};

type IOwnProps = {
  close;
  updateClassifierList: () => null;
};

type IProps = IOwnProps & ConnectedProps<typeof connector>;

const ClassifierFormProvider: FC<IProps> = ({
  close,
  updateClassifierList,
  loading,
  classifierData,
  channels,
  skillGroups,
  mrfs,
  errorMessage,
  getClassifierCatalogs,
  getClassifierData,
  resetClassifierData,
  sendClassifierData,
  removeClassifier
}) => {
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);

  const { classifierId } = useParams<{
    classifierId: string;
  }>();

  useEffect(() => {
    getClassifierCatalogs();
  }, []);

  useEffect(() => {
    if (classifierId) {
      getClassifierData(classifierId);
    }
    return () => resetClassifierData();
  }, [classifierId]);

  const handleRemoveClassifier = () => {
    removeClassifier({
      classifierId,
      close,
      updateClassifierList
    });
  };

  const onSaveClassifier = (formData: Data) => {
    sendClassifierData({ formData, close, updateClassifierList });
  };

  /**
   * Формируем данные созданного классификатора
   * отфильтровах их по каталогу
   */
  const data = classifierData
    ? produce(classifierData, (draft) => {
        draft.channelIds = filterDataByCatalog(channels, draft.channelIds);
        draft.mrfIds = filterDataByCatalog(mrfs, draft.mrfIds);
        draft.skillGroupIds = filterDataByCatalog(
          skillGroups,
          draft.skillGroupIds
        );
      })
    : null;

  return (
    <>
      <ScrollBox autoHeight hasScrollControlBackground>
        <Preloader show={loading}>
          <ClassifierForm
            loading={loading}
            data={data}
            channels={channels}
            mrfs={mrfs}
            skillGroups={skillGroups}
            errorMessage={errorMessage}
            onSave={onSaveClassifier}
            onRemove={() => setOpenRemoveModal(true)}
          />
        </Preloader>
      </ScrollBox>
      <ModalWindow
        open={openRemoveModal}
        onCloseModal={() => {
          setOpenRemoveModal(false);
        }}
        isButtonClose
      >
        <div className={s["confirmModal"]}>
          <SubTitle
            caption={`Подтвердите удаление классификатора «${data?.name}»`}
          />
          <div className={s["buttons"]}>
            <Button
              type="default"
              theme="green"
              onClick={handleRemoveClassifier}
              text="Подтвердить"
            />
            <Button
              type="default"
              theme="red"
              onClick={() => setOpenRemoveModal(false)}
              text="Отменить"
            />
          </div>
        </div>
      </ModalWindow>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getClassifierCatalogs: () => dispatch(getClassifierCatalogs()),
  sendClassifierData: (data) => dispatch(sendClassifierData(data)),
  getClassifierData: (data) => dispatch(getClassifierData(data)),
  resetClassifierData: () => dispatch(resetClassifierData()),
  removeClassifier: (data) => dispatch(removeClassifier(data))
});

const mapStateToProps = (state: StoreState.State) => ({
  loading: selectors.getLoadingStatus(state),
  channels: selectors.channelsList(state),
  skillGroups: selectors.skillGroupList(state),
  mrfs: selectors.mrfsList(state),
  classifierData: selectors.getClassirierState(state).classifierData,
  errorMessage: selectors.getClassirierState(state).errorMessage
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ClassifierFormProvider);
