import React from "react";
import { Radio as MuiRadio, RadioProps } from "@mui/material";

/**
 * Свойства компонента Radio.
 */
export interface IRadioProps extends RadioProps {
  name?: string;
  checked?: boolean;
  value?: string;
  disabled?: boolean;
}

/**
 * Компонент Radio.
 */
const Radio = (props: IRadioProps): JSX.Element => {
  return (
    <MuiRadio
      sx={{
        p: 0,
        color: "#bec2c4",
        "& .MuiSvgIcon-root": {
          fontSize: "24px"
        },
        "&.Mui-checked": {
          color: "#0090DB"
        }
      }}
      {...props}
    />
  );
};

export default Radio;
