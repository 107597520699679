import produce from "immer";

import { SKILL_GROUP_DICTIONARY_INIT, SET_GROUP_DICTIONARY } from "./consts";

import initialState from "../../ReactCore/store/initialState";

export default function SkillGroupModalReducer(
  state = initialState.SkillGroupModal,
  { type, payload }
) {
  switch (type) {
    case SKILL_GROUP_DICTIONARY_INIT:
      return produce(state, draft => {
        draft.dictionaryLoading = true;
      });

    case SET_GROUP_DICTIONARY:
      return produce(state, draft => {
        draft.dictionaryLoading = false;
        draft.dictionaries = { ...payload.data };
      });

    default:
      return state;
  }
}
