const skillGroupModal = {
  dictionaryLoading: false,
  dictionaries: {
    botProfiles: [],
    channels: [],
    mrf: [],
    regions: []
  }
};

export default skillGroupModal;
