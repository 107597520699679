import { combineReducers } from "redux";
import ChatReducer from "../../Chat/store/Chat.reducer";

/**
 * Регистрация reducers для всех ReactFeatures
 */
const featureReducers = {};
const requireFeaturesReducer = require.context(
  "../../ReactFeatures",
  true,
  /\.reducer\.ts/
);
requireFeaturesReducer.keys().forEach((name) => {
  const reducerName = name.split("/")[1];
  featureReducers[reducerName] = requireFeaturesReducer(name).default;
});

/**
 * Регистрация reducers для раздела Settings
 */
const settingsReducers = {};
const requireSettingsReducer = require.context(
  "../../Settings",
  true,
  /\.reducer\.ts/
);
requireSettingsReducer.keys().forEach((name) => {
  const reducerName = name.split("/")[1];
  settingsReducers[reducerName] = requireSettingsReducer(name).default;
});

export default combineReducers({
  ...featureReducers,
  ...settingsReducers,
  Chat: ChatReducer
});
