import React, { FunctionComponent } from "react";

import { Button } from "@omnichat/arm_ui_kit";

import ServiceType from "./ServiceType";
import ThematicsPicker from "./ThematicsPicker";
import ThematicPickedList from "./ThematicPickedList";

import { IThematicsModalProps } from "./types";
import * as s from "./styles.module.scss";

/**
 * Модальное окно выбора тематик.
 */
const ThematicsModal: FunctionComponent<IThematicsModalProps> = ({
  serviceType,
  thematics,
  themesFields,
  activeService,
  regions,
  selectedRegions,
  editItem,
  cancelEdit,
  onSaveFields,
  onFieldsChange,
  onServiceChange,
  onRegionChange,
  onDeleteThematic,
  onEditThematic,
  onSend,
  errorMessage
}): JSX.Element => {
  return (
    <div className={s["modal"]}>
      <div className={s["modalContainer"]}>
        <header className={s["modalHeader"]}>Определите тему обращения</header>
        {serviceType && (
          <ServiceType
            activeService={activeService}
            regions={regions}
            selected={selectedRegions}
            onServiceChange={id => onServiceChange(id)}
            onRegionChange={id => onRegionChange(id)}
          />
        )}
      </div>

      <div className={s["modalContainer"]}>
        <header className={s["modalSubHeader"]}>Добавить тему</header>
        <ThematicsPicker
          themesFields={themesFields}
          onChange={onFieldsChange}
          onSave={onSaveFields}
        />
      </div>

      <div className={s["modalContainer"]}>
        <header className={s["modalSubHeader"]}>Добавить тематику</header>
        <ThematicPickedList
          editItem={editItem}
          cancelEdit={cancelEdit}
          errorMessage={errorMessage}
          thematics={thematics}
          onEdit={onEditThematic}
          onDelete={onDeleteThematic}
        />
      </div>
      <div className={s["modalButtonsContainer"]}>
        <Button
          type="default"
          text="Сохранить"
          theme="green"
          onClick={() => onSend()}
        />
      </div>
    </div>
  );
};

export default ThematicsModal;
