import { keyCodeToCodeMap } from "../Consts/KeyCodes";
import { IKeyCodesStuff } from "../Interfaces/HotKeys";

import { isEmpty } from "@omnichat/arm_ui_kit";
import { regExp } from "./RegExp";

type ITypeHref = "Mail" | "Url" | "HtmlLink" | "Phone";

interface ITagsArray extends Object {
  linkType?: ITypeHref[];
}

export const getKeyFromKeyCode = (keyCode: number, code: string): string => {
  let formattedCode = "";

  if (code) formattedCode = code.replace(/(Digit|Key)/, "");

  return keyCodeToCodeMap.has(keyCode)
    ? (keyCodeToCodeMap.get(keyCode) as string)
    : formattedCode;
};

/**
 * Преобразует массив кодовых кракозябр в читаемые сторки сочетаний клавиш.
 *
 * @param {ITypeHref} regExpType Название выражения из импортированного объекта regExp.
 * @param {string} data Текст.
 */
const regExpTest = (regExpType: ITypeHref, data: string): boolean => {
  if (!Object.keys(regExp).includes(regExpType)) return false;
  regExp[regExpType].lastIndex = 0;
  return regExp[regExpType].test(data);
};

/**
 * Преобразует массив кодовых кракозябр в читаемые сторки сочетаний клавиш.
 *
 * @param {string[]} values Массив строк с + разделителем.
 */
export const convertKeyCodes = (values: string[]): string[] => {
  let result = values;

  if (!isEmpty(values)) {
    result = values.map((hotKeyCodes: string) => {
      let result = hotKeyCodes.split("+");

      if (result.length === 2) {
        result = [
          keyCodeToCodeMap.get(+result[0]) as string,
          keyCodeToCodeMap.get(+result[1]) as string
        ];
      }

      if (result.length === 3) {
        result = [
          keyCodeToCodeMap.get(+result[0]) as string,
          keyCodeToCodeMap.get(+result[1]) as string,
          keyCodeToCodeMap.get(+result[2]) as string
        ];
      }

      return result.join(" + ");
    });
  }

  return result;
};

/**
 * Захватит зажатые клавиши и вернет объект для обработки полученного сочетания клавиш.
 *
 * @param {React.KeyboardEvent<T>} event Синтетическое событие нажатия на клавишу.
 * @param {Set<number> | null} pressedCodes Коллекция зажатых клавиш.
 */
export const captureKeyCodesStuff = <T>(
  event: React.KeyboardEvent<T>,
  pressedCodes: Set<number> | null
): IKeyCodesStuff | null => {
  if (!event.repeat && pressedCodes) {
    const keyFlags = [
      +event.altKey,
      +event.ctrlKey,
      +event.metaKey,
      +event.shiftKey
    ];
    const codes: number[] = [...pressedCodes];
    let newHotKeyDescription = "";
    let newKeyCodes = "";

    let inputValue = event.nativeEvent?.code;

    if (pressedCodes.has(event.keyCode)) return;

    newHotKeyDescription = inputValue = getKeyFromKeyCode(
      event.keyCode,
      event.nativeEvent.code
    );

    if (event.nativeEvent.code === "Period") inputValue = "."; // period
    if (event.nativeEvent.code === "Slash") inputValue = "/"; // forward slash

    if (codes.length) {
      codes.forEach((pressedCode: number, index: number) => {
        const sum = keyFlags.reduce((sum, current) => (sum += current));

        if (
          index === 0 &&
          pressedCode !== 17 &&
          pressedCode !== 18 &&
          pressedCode !== 91 &&
          pressedCode !== 16
        ) {
          return;
        }

        if (index === 0 && sum === 1 && codes.length === 1) {
          newHotKeyDescription = `${keyCodeToCodeMap.get(
            pressedCode
          )} + ${inputValue}`;
          newKeyCodes = `${pressedCode}+${event.keyCode}`;
        }

        if (
          index === 1 &&
          sum === 2 &&
          (pressedCode === 17 ||
            pressedCode === 18 ||
            pressedCode === 91 ||
            pressedCode === 16)
        ) {
          newHotKeyDescription = `${keyCodeToCodeMap.get(
            codes[0]
          )} + ${keyCodeToCodeMap.get(pressedCode)} + ${inputValue}`;
          newKeyCodes = `${codes[0]}+${pressedCode}+${event.keyCode}`;
        }
      });
    }

    return {
      hotKeyCodes: newKeyCodes,
      hotKeyDescription: newHotKeyDescription,
      pressedCodes: pressedCodes.add(event.keyCode)
    };
  }

  return null;
};

/**
 * Обрежет длинную строку и вернет многоточие после указанного количества символов.
 *
 * @param {string} src Исходная строка.
 * @param {number} maxlongHref Максимальная длина строки после которой ставится многоточие.
 */
export const sliceLongHref = (
  src: string,
  maxlongHref: number = 50
): string => {
  if (!src || !src.length) return "ссылка";
  if (src.length <= maxlongHref) return src;

  const dots = src[maxlongHref - 1] === "." ? ".." : "...";
  return src.slice(0, maxlongHref) + dots;
};

export const constructHrefString = (
  target: string = "",
  link: string,
  text: string = ""
): string => {
  if (!text) text = link;
  return link ? `<a ${target}href="${link}">${sliceLongHref(text)}</a>` : text;
};

/**
 * Преобразует техт, определённый как единичная ссылка, в html в виде кликабельной ссылки.
 *
 * @param {ITypeHref} typeHref Тип обрабатываемой ссылки.
 * @param {string[]} ...matches Массив совпадений с RegExp выражением.
 */
export const replacerHref = (typeHref: ITypeHref) => (
  ...matches: string[]
): string => {
  let testedRegExp: RegExp,
    hrefPrefix: string,
    targetFinal: string,
    hrefReplaced: string,
    linkText: string;

  hrefReplaced = linkText = matches[0];

  if (typeHref === "Mail") {
    testedRegExp = /mailto:/i;
    hrefPrefix = "mailto:";
  } else if (typeHref === "Url" || typeHref === "HtmlLink") {
    testedRegExp = /https?:\/\//i;
    hrefPrefix = "http://";

    if (typeHref === "HtmlLink") {
      linkText = matches[6] ? matches[6] : matches[3];
      hrefReplaced = matches[3];

      // если внутри ссылки, переданной с разметкой <a href="...">...</a> в кавычках e-mail или телефон, то изменить поведение обработчика
      if (regExpTest("Mail", hrefReplaced)) {
        testedRegExp = /mailto:/i;
        hrefPrefix = "mailto:";
      } else if (regExpTest("Phone", hrefReplaced)) {
        testedRegExp = /callto:/i;
        hrefPrefix = "callto:";
      }
    }
  } else {
    console.log(
      `Реализован тип ссылки, не предусмотренный обработчиком: ${typeHref}`
    );
    return matches[0];
  }

  targetFinal =
    hrefPrefix === "http://" ? (targetFinal = 'target="_blank" ') : "";
  if (!testedRegExp.test(hrefReplaced))
    hrefReplaced = hrefPrefix + hrefReplaced;

  return constructHrefString(targetFinal, hrefReplaced, linkText);
};

/**
 * Экранирует выявленные по шаблонам ссылки в порядке и по тимам, заданым в массиве linkTypes. Важен порядок: процедура защищает выявленные сопоставления от определения внутри них других шаблонов.
 * @param {string} text
 */
const createTagsWithLink = (
  text: string
): {
  tagsWithLink: ITagsArray;
  resultWithProtectedLinks: string;
} => {
  const linkTypes: ITypeHref[] = ["HtmlLink", "Mail", "Url"];

  const linksByTypes = linkTypes.reduce((linksСollection, linkType) => {
    const matchesByLink = text.match(regExp[linkType]);
    if (matchesByLink) {
      text = text.replace(regExp[linkType], `__${linkType}__`);
      linksСollection[linkType] = matchesByLink;
    }
    return linksСollection;
  }, {});

  return { tagsWithLink: linksByTypes, resultWithProtectedLinks: text };
};

/**
 * Вставляет в текст html-разметку в виде кликабельных ссылок на месте экранирования `__${linkType}__`.
 *
 * @param {ITagsArray} tagsWithLink Массив совпадений с RegExp выражениями, разбитый по типам ссылок.
 * @param {string} resultWithProtectedLinks Строка с экранированными ссылками.
 * @param {boolean} isActiveClick Активность формируемой ссылки.
 */
const restoreLinkFromTags = (
  tagsWithLink: ITagsArray,
  resultWithProtectedLinks: string,
  isActiveClick: boolean = true
): string => {
  for (const linkType in tagsWithLink) {
    tagsWithLink[linkType]?.forEach((tempLink: string): void => {
      let tmp = tempLink.replace(
        regExp[linkType],
        replacerHref(`${linkType}` as ITypeHref)
      );

      if (!isActiveClick)
        tmp = `<a style="pointer-events:none" ${tmp.slice(3)}`;

      resultWithProtectedLinks = resultWithProtectedLinks.replace(
        `__${linkType}__`,
        tmp
      );
    });
  }

  return resultWithProtectedLinks;
};

/**
 * При наличии в тексте ссылок, правит их разметку
 * @param result Текст
 * @param isActiveClick Должен ли быть активным клик по ссылке
 * @returns
 */
export const resolveLink = (result: string, isActiveClick = true): string => {
  if (result == null || result == undefined) return result;
  if (
    ["number", "bigint", "boolean", "object", "array"].includes(typeof result)
  )
    return result;

  result = result
    .replace(/(&quot;)/g, '"')
    .replace(/(&lt;)/g, "<")
    .replace(/(&gt;)/g, ">");

  let { tagsWithLink, resultWithProtectedLinks } = createTagsWithLink(result);

  return restoreLinkFromTags(
    tagsWithLink,
    resultWithProtectedLinks,
    isActiveClick
  );
};
