import { fork } from "redux-saga/effects";

import getUsersMultiProfileCatalogsSaga from "./getUsersMultiProfileCatalogs.saga";
import sendUsersMultiProfileSaga from "./sendUsersMultiProfile.saga";
import deactivateUsersSaga from "./deactivateUsers.saga";

export default function*() {
  yield fork(getUsersMultiProfileCatalogsSaga);
  yield fork(sendUsersMultiProfileSaga);
  yield fork(deactivateUsersSaga);
}
