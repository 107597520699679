import React, { FC, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button, REQUIRED_FIELD_ERROR, Preloader } from "@omnichat/arm_ui_kit";
import { Grid } from "@mui/material";

import { Input, Slider } from "../../../../Components";
import FormFieldConnector from "../../../../Components/FormFieldConnector";
import SectionTitle from "../../../../ReactFeatures/Common/Components/SectionTitle/SectionTitle";

import { ChatbotData } from "../../Chatbots";

import * as s from "./style.module.scss";

import ChatbotAvatar from "../../../../ReactFeatures/AvatarUploader";

import { ReactHookFormErrorHandler } from "../../../../Utils/HookForm.helper";

const INVALID_URL_ERROR = "Неверный формат URL (http://, https://)";

const schema = Yup.object({
  name: Yup.string().trim().required(REQUIRED_FIELD_ERROR),
  postUrl: Yup.string()
    .trim()
    .url(INVALID_URL_ERROR)
    .required(REQUIRED_FIELD_ERROR),
  heartBeatUrl: Yup.string()
    .trim()
    .url(INVALID_URL_ERROR)
    .required(REQUIRED_FIELD_ERROR),
  scoringLevel: Yup.number().required(REQUIRED_FIELD_ERROR),
  botAgentPhoto: Yup.string().trim(),
  botAgentPenName: Yup.string().trim().required(REQUIRED_FIELD_ERROR),
  greeting: Yup.string().trim(),
  transfer: Yup.string().trim()
});

const defaultValues = {
  name: "",
  postUrl: "",
  heartBeatUrl: "",
  scoringLevel: 0,
  botAgentPhoto: "",
  botAgentPenName: "",
  greeting: "",
  transfer: ""
};

type Props = {
  loading: boolean;
  data?: ChatbotData;
  onSave: (data: IChatbotForm) => void;
  onRemove: (id: string) => void;
  error?: string;
};

export interface IChatbotForm extends Yup.InferType<typeof schema> {}

const ChatbotModal: FC<Props> = ({
  data,
  loading,
  onRemove,
  onSave,
  error
}): JSX.Element => {
  const methods = useForm<IChatbotForm, any, IChatbotForm>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset, watch } = methods;

  const photo = watch("botAgentPhoto");
  const scoring = watch("scoringLevel");

  useEffect(() => {
    if (data) {
      reset(data);
    } else {
      reset(defaultValues);
    }
  }, [data]);

  return (
    <div className={s.body}>
      <Preloader show={loading}>
        <FormProvider {...methods}>
          <Grid container spacing={1}>
            <Grid item alignItems="center" xs={12} paddingBottom={2}>
              <SectionTitle
                title={data?.id ? "Профиль чат-бота" : "Новый чат-бот"}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="name"
                required
                Component={(p) => (
                  <Input
                    {...p}
                    required
                    label="Название"
                    onChange={p.onChange}
                    value={p.value}
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="postUrl"
                required
                Component={(p) => (
                  <Input
                    {...p}
                    required
                    label="URL-WEBHOOK"
                    onChange={p.onChange}
                    value={p.value}
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="heartBeatUrl"
                required
                Component={(p) => (
                  <Input
                    {...p}
                    required
                    label="URL-HEARTBEAT"
                    onChange={p.onChange}
                    value={p.value}
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="scoringLevel"
                label={"Результат скоринга"}
                actionText="Обращение будет переведено от чат-бота агенту или скилл-группе, если результат скоринга будет ниже установленного порога"
                required
                Component={(p) => <Slider {...p} />}
                customTitle={<div className={s.perc}>{`${scoring}%`}</div>}
              />
            </Grid>
            <Grid item alignItems="center" xs={12} paddingBottom={2}>
              <SectionTitle title={"Профиль чат-бота в диалоге"} />
            </Grid>
            <Grid container item alignItems="center" xs={12} paddingY={2}>
              <Grid
                container
                item
                xs={6}
                alignItems="center"
                justifyContent="center"
              >
                <img
                  className={s.avatar}
                  src={
                    photo
                      ? `/fileStorage/files/crop/${photo}`
                      : "/www/img/msg_bot_avatar.png"
                  }
                />
              </Grid>
              <Grid
                container
                item
                xs={6}
                alignItems="center"
                justifyContent="center"
              >
                <FormFieldConnector
                  name="botAgentPhoto"
                  Component={(p) => (
                    <ChatbotAvatar name={photo} onChange={p.onChange} />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="botAgentPenName"
                required
                Component={(p) => (
                  <Input
                    {...p}
                    required
                    label="ПСЕВДОНИМ"
                    onChange={p.onChange}
                    value={p.value}
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="greeting"
                Component={(p) => (
                  <Input
                    {...p}
                    label="ПЕРВОЕ СООБЩЕНИЕ ЧАТ-БОТА"
                    onChange={p.onChange}
                    value={p.value}
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={12} paddingBottom={2}>
              <FormFieldConnector
                name="transfer"
                Component={(p) => (
                  <Input
                    {...p}
                    label="СООБЩЕНИЕ О ПЕРЕВОДЕ НА АГЕНТА"
                    onChange={p.onChange}
                    value={p.value}
                  />
                )}
              />
            </Grid>
            <Grid container item alignItems="center">
              {error && (
                <Grid
                  container
                  justifyContent="center"
                  item
                  xs={12}
                  paddingBottom={2}
                >
                  <div className={s.error}>{error}</div>
                </Grid>
              )}
              <Grid container justifyContent="center" item xs={6}>
                <Button
                  type="default"
                  iconName="check"
                  iconColor="white"
                  theme={loading ? "disabled" : "green"}
                  onClick={handleSubmit(
                    (formData) => onSave({ ...(data || {}), ...formData }),
                    ReactHookFormErrorHandler
                  )}
                  text="Cохранить"
                />
              </Grid>
              <Grid container item xs={6} justifyContent="center">
                {data?.id && (
                  <Button
                    type="default"
                    iconName="cross"
                    iconColor="white"
                    theme={loading ? "disabled" : "red"}
                    onClick={loading ? () => null : () => onRemove(data.id)}
                    text="Удалить чат-бота"
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </Preloader>
    </div>
  );
};

export default ChatbotModal;
