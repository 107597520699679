import * as React from "react";
import Modal from "../Wrappers/Modal";

function showSettingsCapacityModal(skillId, skilllName) {
  import("../../ReactFeatures/SkillGroupCapacityModal").then(Module => {
    const SkillGroupCapacityModalProvider = Module.default;
    const modalId = "SkillGroupCapacityModal";

    const modal = new Modal(modalId, 500, close => (
      <SkillGroupCapacityModalProvider
        close={close}
        skillId={skillId}
        skillGroupName={skilllName}
      />
    ));
    modal.open();
  });
}

export { showSettingsCapacityModal };
