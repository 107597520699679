import { createAction } from "@reduxjs/toolkit";
import {
  GET_REGIONS,
  FETCH_RFS,
  FETCH_MRFS,
  GET_REPLIES_CATEGORIES,
  GET_TRANSFER_DICTIONARY,
  LOADED_TRANSFER_DICTIONARY,
  CLEAN_TRANSFER_DICTIONARY
} from "./Consts";

export const getRepliesCategories = page => ({
  type: GET_REPLIES_CATEGORIES,
  payload: page
});

export const getRegions = () => ({
  type: GET_REGIONS
});

export const getMrfs = () => ({
  type: FETCH_MRFS
});

export const getRfs = () => ({
  type: FETCH_RFS
});

/**
 * Создаст экшн для получения списка трансфера скилл-групп.
 */
export const getTransferDictionary = createAction<any | undefined>(
  GET_TRANSFER_DICTIONARY
);

export const loadedTransferDictionary = data => ({
  type: LOADED_TRANSFER_DICTIONARY,
  payload: { data }
});

export const cleanTransferDictionary = createAction<any | undefined>(
  CLEAN_TRANSFER_DICTIONARY
);
