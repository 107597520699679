import { ICombutionLine } from "./Interfaces";
import { IChatInitAppeals } from "../../Chat/Interfaces";
import { IWsNewMessage } from "../../ReactFeatures/Dialog/Interfaces";
import {
  isoIntervalToTimestamp,
  isoToTimestamp
} from "../../ReactCore/shared/dataFormats";

const ORT_MODE = "ort";
const Sl_MODE = "sl";

const getChatInitSlCombutionTimeProperties = (data: IChatInitAppeals) => ({
  // null - опрератор не ответил, 0 - оператор ответил поздно, 1 - оператор ответил вовремя
  active: !Number.isInteger(data.sl),
  countBy: data.client.channel.sl,
  timeStart: +data.dateStart
});

const getChatInitOrtCombutionTimeProperties = (data: IChatInitAppeals) => ({
  active: data.operatorAnswerInResponseTime === null,
  countBy: isoIntervalToTimestamp(data.client.channel.operatorResponseTime),
  timeStart: isoToTimestamp(data.transferToOperator)
});

const getNewMessageSlCombutionTimeProperties = (data: IWsNewMessage) => ({
  active: Boolean(data.isSlAvailable) && !Boolean(data.chatByCode),
  countBy: data.slLevel,
  timeStart: data.appealCreationDate
});

const getNewMessageOrtCombutionTimeProperties = (data: IWsNewMessage) => ({
  active: data.operatorAnswerInResponseTime === null,
  countBy: isoIntervalToTimestamp(data.operatorResponseTime),
  timeStart: isoToTimestamp(data.transferToOperator)
});

export const getChatInitCombutionTime = (
  data: IChatInitAppeals
): ICombutionLine => {
  if (DIALOG_COMBUSTION_TIME_MODE === Sl_MODE) {
    return getChatInitSlCombutionTimeProperties(data);
  } else if (DIALOG_COMBUSTION_TIME_MODE === ORT_MODE) {
    return getChatInitOrtCombutionTimeProperties(data);
  }
};

export const getNewMessageCombutionTime = (
  data: IWsNewMessage
): ICombutionLine => {
  if (DIALOG_COMBUSTION_TIME_MODE === Sl_MODE) {
    return getNewMessageSlCombutionTimeProperties(data);
  } else if (DIALOG_COMBUSTION_TIME_MODE === ORT_MODE) {
    return getNewMessageOrtCombutionTimeProperties(data);
  }
};

const getLeftTime = (
  active: boolean,
  dialogStart: number,
  targetTime: number
) => {
  if (active && dialogStart && targetTime) {
    const currentTime = Math.ceil(+new Date() / 1000);
    const leftTime = currentTime - dialogStart;

    if (leftTime < targetTime) {
      const diff = targetTime - leftTime;

      return diff;
    }

    return 0;
  }
  return 0;
};

export const getCombutionLineProperties = (data: ICombutionLine) => {
  if (
    !DIALOG_COMBUSTION_TIME_MODE ||
    !data ||
    !data.active ||
    !data.countBy ||
    !data.timeStart
  ) {
    return null;
  }
  const leftTime = getLeftTime(data.active, data.timeStart, data.countBy);
  return {
    isActive: data.active,
    targetTime: data.countBy,
    leftTime: leftTime
  };
};
