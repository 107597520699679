// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WnRTPlcObPZZTlJeQlU1{width:550px;margin:15px 20px}.VasRpqOTi6UvSBhUVPQI{display:flex;justify-content:space-between}.tnAam8XtgpburXyTGtmv{display:flex;flex-direction:column}.OCuxEtbTDYQtGIKADfud{display:flex;align-items:center;padding:8px;justify-content:space-between;background:#cee6f2;margin-bottom:10px}.SrGUwkLm5E4qtTiyc5xp{background:none}.isgmfYi4jTCT8H1RKIFg{display:flex;align-items:center;margin-right:5px}.JlEy_HJsezgGyK0M0eHR{margin:15px 0 30px 0;color:#fc2b32}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/ClassifierFieldValueForm/Components/ClassifierFieldValueForm/style.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,gBAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,WAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CAEF,sBACE,eAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,gBAAA,CAEF,sBACE,oBAAA,CACA,aAAA","sourcesContent":[".wrapper {\n  width: 550px;\n  margin: 15px 20px;\n}\n.buttons {\n  display: flex;\n  justify-content: space-between;\n}\n.links {\n  display: flex;\n  flex-direction: column;\n}\n.link {\n  display: flex;\n  align-items: center;\n  padding: 8px;\n  justify-content: space-between;\n  background: #cee6f2;\n  margin-bottom: 10px;\n}\n.empty {\n  background: none;\n}\n.linkName {\n  display: flex;\n  align-items: center;\n  margin-right: 5px;\n}\n.notification {\n  margin: 15px 0 30px 0;\n  color: #fc2b32;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = "WnRTPlcObPZZTlJeQlU1";
export var buttons = "VasRpqOTi6UvSBhUVPQI";
export var links = "tnAam8XtgpburXyTGtmv";
export var link = "OCuxEtbTDYQtGIKADfud";
export var empty = "SrGUwkLm5E4qtTiyc5xp";
export var linkName = "isgmfYi4jTCT8H1RKIFg";
export var notification = "JlEy_HJsezgGyK0M0eHR";
export default ___CSS_LOADER_EXPORT___;
