import { isYesterday, isToday } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import ru from "date-fns/locale/ru";

import { IAbstractObject } from "./../Common/Interfaces/Abstract.intrefaces";
import { ITransferMessageData } from "./../Common/Interfaces/Message.interfaces";
import { IMessage } from "../Common/Interfaces/Message.interfaces";
import { IUser } from "../Common/Interfaces/User.interfaces";
import { EMessagesType } from "../Common/Enums/Message.enums";
import { resolveLink } from "../Common/Utils/TextUtils";
import { EMediaType } from "../Common/Enums/Message.enums";

export interface IMessageSignatureProps {
  /** Тип сообщения (автоматическое, от оператора или от клиента )*/
  type: "auto" | "user" | "client";
  /** Unix-время */
  time: string;
  /** Канал в который отправлено сообщение */
  channel?: string;
  /** Имя написавшего сообщение (выводится только для оператора) */
  name?: string;
}

export const getMessageTime = (time: number): string => {
  const formatTime = (token: string) => {
    return formatInTimeZone(
      new Date(+time),
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      token,
      // @ts-ignore: Ошибка в интерфейсах библиотек
      { locale: ru }
    );
  };

  if (isYesterday(time)) {
    return formatTime("вчера в HH:mm");
  }
  if (isToday(time)) {
    return formatTime("сегодня в HH:mm");
  }
  return formatTime("dd MMMM yyyyг в HH:mm");
};

export const getMessageSignature = (params: IMessageSignatureProps): string => {
  let message;

  let { type, time, name, channel } = params;

  const toCapitalize = (string: string) => {
    return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
  };

  const hasName = name && name.trim().length;
  if (type === "auto") {
    message = "автоматически ";
  } else {
    message = (hasName ? `${name} ` : "") as string;
  }

  message += getMessageTime(+time);

  if (channel) {
    message += type === "client" ? " с " : " на ";
    message += channel;
  }

  return toCapitalize(message);
};

export const getMessageFrom = (
  transferData: ITransferMessageData
): string | void => {
  let groupFrom = transferData.groupFrom && transferData.groupFrom.name;
  let userFrom =
    transferData.userFrom &&
    transferData.userFrom.name +
      " " +
      transferData.userFrom.secName +
      (groupFrom ? " (" + groupFrom + ")" : "");
  return userFrom || groupFrom;
};

export const getMessageTo = (
  transferData: ITransferMessageData
): string | void => {
  let groupTo = transferData.groupTo && transferData.groupTo.name;
  let userTo =
    transferData.userTo &&
    transferData.userTo.name +
      " " +
      transferData.userTo.secName +
      (groupTo ? " (" + groupTo + ")" : "");
  return userTo || groupTo;
};

/**
 * Усечённый интерфейс IUser для локальных целей.
 *
 * @prop {number} admin Является ли пользователь администратором (код 1).
 * @prop {string} [name] Имя пользователя.
 * @prop {string} [secName]
 */

type IUserOperatorProps = Pick<IUser, "admin" | "name" | "secName">;

/**
 * Преобразует разметку ссылок в сообщении и заменит пробельные символы
 * на <br/> при их наличии.
 *
 * @param {string} text Текст для преобразования.
 */
export function resolveMessage(text: string): string {
  let result = resolveLink(text);

  const enterRegexp = /\n/g;

  if (result.match(enterRegexp)) {
    return result.replace(enterRegexp, "<br/>");
  }

  return result;
}

/**
 * Вернет имя оператора.
 *
 * @param {IUserOperatorProps} userData Усечённые данные о пользователе.
 */
export function getOperatorsName(userData?: IUserOperatorProps): string | void {
  if (!userData) return;

  return `${userData.admin ? "Администратор " : ""}${userData.secName} ${
    userData.name
  }`;
}

/**
 * Вернет имя группы.
 *
 * @param {IAbstractObject} skillGroup Данные о группе.
 */
const getSkillGroup = (skillGroup: IAbstractObject): string | void =>
  skillGroup?.name ? `( ${skillGroup.name} )` : "";

/**
 * Вернет имя пользователя и группу, если они существуют.
 *
 * @param {IUserOperatorProps} UserData Данные о пользователе.
 * @param {IAbstractObject} skillGroup Данные о группе.
 */
const formatUserAndSkillGroup = (
  userData?: IUserOperatorProps,
  skillGroup?: IAbstractObject
): string | void =>
  userData
    ? `${getOperatorsName(userData)} ${getSkillGroup(skillGroup)}`
    : getSkillGroup(skillGroup);

/**
 * Вернет информационный текст для системного сообщения о пользователе производившем операцию c обращением, при наличии поля messageData.user.
 *
 * @param {IMessage} messageData Данные по сообщению.
 */
export function getInfoUserField(messageData?: IMessage): string | void {
  if (!messageData) return;

  return messageData.type !== EMessagesType.TransferByUser
    ? formatUserAndSkillGroup(messageData.user, messageData.skillGroup)
    : formatUserAndSkillGroup(messageData.user);
}

/**
 * Вернет информационный текст для системного сообщения о том, кто производил передачу обращения между группами/операторами при наличии поля transfer.userFrom.
 *
 * @param {ITransferMessageData} transferData Данные по сообщению о передаче/переоткрытии обращения между операторами.
 */
export function getInfoTransferFrom(
  transferData?: ITransferMessageData
): string | void {
  if (!transferData) return;

  return formatUserAndSkillGroup(transferData.userFrom, transferData.groupFrom);
}

/**
 * Вернет информационный текст для системного сообщения о том, кто производил передачу обращения между группами/операторами при наличии поля transfer.userTo.
 *
 * @param {ITransferMessageData} transferData Данные по сообщению о передаче/переоткрытии обращения между операторами.
 */
export function getInfoTransferTo(
  transferData?: ITransferMessageData
): string | void {
  if (!transferData) return;

  return formatUserAndSkillGroup(transferData.userTo, transferData.groupTo);
}

/**
 * Добавит милисекунды к дате.
 *
 * @param {string} time Дата.
 */
export function getTimeStampInMilliseconds(time: string): string {
  return time + "000";
}

/**
 * Вернет сторону расположения сообщения.
 *
 * @param {IMessage} messageData Данные сообщения.
 */
export function getMessageSide(messageData: IMessage): "right" | "left" {
  return messageData.clientId ? "left" : "right";
}

/**
 * Вернет тип отправителя сообщения.
 *
 * @param {IMessage} messageData Данные сообщения.
 */
export function getMessageSender(
  messageData: IMessage
): "client" | "user" | "auto" {
  if (messageData.clientId) {
    return "client";
  } else if (
    messageData.type !== EMessagesType.TransferBySystem &&
    (messageData.user?.name ||
      messageData.transfer?.userFrom?.name ||
      messageData.transfer?.userTo?.name)
  ) {
    return "user";
  }
  return "auto";
}

/**
 * Вернет данные по аватару пользователя.
 *
 * @param {IUser} UserData Данные о пользователе.
 * @param {string} avatarUrl Идентификатор ресурса, откуда грузить аватар.
 */
export function getUserAvatar(
  UserData: IUser,
  avatarUrl: string
): string | void {
  if (!UserData.photo) return "";

  return /^http[s]?:\/\//.test(UserData.photo)
    ? UserData.photo
    : avatarUrl + UserData.photo;
}

/**
 * Вернет идентификатор ресурса откуда грузить медиа.
 *
 * @param {string} mediaUrl Индентификатор ресурса медиа.
 */
export function getMediaUrl(mediaUrl: string) {
  return mediaUrl ? mediaUrl.split("/")[0] : mediaUrl;
}

export function getMediaThumb(mediaThumb: string) {
  const splitedMediaThumb = mediaThumb.split("/");
  return splitedMediaThumb.find(el => el === "thumb")
    ? splitedMediaThumb.splice(0, 2).join("/")
    : splitedMediaThumb[0];
}

/**
 * Вернет стили для контейнера с текстом сообщения.
 *
 * @param {EMediaType} mediaType Тип данных, содержащийся в сообщении.
 */
export function getMessageTextContainerStyle(mediaType: EMediaType) {
  return mediaType && mediaType > EMediaType.Text ? { marginTop: "10px" } : {};
}

/**
 * Проверит отображать ли компонент UploadFileLink.
 *
 * @param {IMessage} messageData Данные о сообщении.
 */
export function isUploadFileLinkShown(messageData: IMessage): boolean {
  return messageData.mediaType === EMediaType.File && !!messageData.mediaUrl;
}

/**
 * Проверит отображать ли компонент LocationMessage.
 *
 * @param {IMessage} messageData Данные о сообщении.
 */
export function isLocationMessageShown(messageData: IMessage): boolean {
  return (
    messageData.mediaType === EMediaType.Location &&
    !!messageData.latitude &&
    !!messageData.longitude
  );
}

/**
 * Проверит отображать ли компонент ImageMessageConstructor.
 *
 * @param {IMessage} messageData Данные о сообщении.
 */
export function isImageMessageConstructorShown(messageData: IMessage): boolean {
  return (
    messageData.mediaType === EMediaType.Image &&
    !!messageData.mediaUrl &&
    !!messageData.mediaThumb
  );
}

/**
 * Проверит отображать ли текст сообщения.
 *
 * @param {IMessage} messageData Данные сообщения.
 */
export function isMessageTextShown(messageData: IMessage): boolean {
  return !!messageData.text?.trim();
}

/**
 * Вернет уникальный идентификатор для сообщения.
 *
 * @param {IMessage} messageData Данные сообщения.
 */
export function getUniqueId(messageData: IMessage) {
  return messageData.type + "_" + messageData.date + "_" + messageData.id;
}
