import React from "react";
import {
  DEFAULT_LOWER_TIME_LIMIT,
  DEFAULT_UPPER_TIME_LIMIT
} from "@omnichat/arm_ui_kit";

import { getMessageTime } from "../ReactFeatures/MessageBox/Utils";
import { AppealInfoModalProvider } from "../ReactFeatures/AppealInfoModal";
import {
  DashboardFilters_init,
  DashboardFilters_changeData,
  DashboardFilters_destroy
} from "./Constructors/DashboardFiltersConstructor";

import { showSettingsCapacityModal } from "./Constructors/SkillGroupCapacityModalConstructor";

import Modal from "./Wrappers/Modal";

import {
  toUnixTimeStamp,
  convertToMoscowTimestamp
} from "../ReactFeatures/Common/Utils/DateAndTime.utils";

export default {
  charts_loading: {},

  charts: {
    el: {},

    init: function() {
      core.tools.loadCode("highcharts/highcharts.js", function() {
        msg.stats.charts_init();
        msg.stats.charts.load();
      });
    },

    abort: function() {
      if (!Object.keys(msg.stats.charts_loading).length) return false;

      $.each(msg.stats.charts_loading, function(i, connect) {
        if (connect.readyState == 1) connect.abort();

        delete msg.stats.charts_loading[i];
      });
    },

    load: function() {
      msg.stats.charts.abort();

      var charts = ["appeals", "aht", "csi", "ort", "sl", "lcr"],
        params = msg.stats.prepareDataFromFilterFormToRequest();
      const { date_start } = core.nav.url.params;
      let time = date_start * 1000;

      if (new Date().getTimezoneOffset() !== -180) {
        const moscowTime = +date_start + 180 * 60;
        const offset = new Date().getTimezoneOffset();
        time = (moscowTime + offset * 60) * 1000;
      }

      const startDate = new Date(time);

      $.each(charts, function(i, chart) {
        $("#chart_" + chart + "_text").html("");
        $("#chart_" + chart)
          .removeClass("empty")
          .addClass("load");

        msg.stats.charts_loading[i] = $.ajax({
          url: msg.urlChart + chart,
          data: params,
          dataType: "JSON",
          type: "GET",
          success: function(r) {
            if ($("#chart_" + chart) != false) {
              $("#chart_" + chart).removeClass("load");

              if (r.data.count !== 0) {
                if (chart == "csi") {
                  msg.stats.charts.el[chart].series[0].update(
                    {
                      data: r.data.chart
                    },
                    true
                  );
                } else {
                  msg.stats.charts.el[chart].series[0].update(
                    {
                      pointStart: Date.UTC(
                        r.data.date.y,
                        r.data.date.m,
                        r.data.date.d,
                        startDate.getHours()
                      ),
                      data: r.data.chart,
                      pointInterval: r.data.group == "day" ? 86400000 : 3600000
                    },
                    true
                  );
                }

                if ($("#chart_" + chart + "_text") != false) {
                  if (chart == "appeals") {
                    $("#chart_" + chart + "_text").html(
                      parseFloat(r.data.text).toFixed()
                    );
                  } else {
                    $("#chart_" + chart + "_text").html(r.data.text);
                  }
                }
              } else {
                if ($("#chart_" + chart + "_text") != false) {
                  $("#chart_" + chart + "_text").html("");
                  $("#chart_" + chart).addClass("empty");
                }
              }
            }
          }
        });
      });

      core.nav.history.set(core.nav.getUrl(undefined, "object"));
    },

    agent_check: function() {
      if (
        core.nav.url.params.agent === "all" ||
        core.nav.url.params.agent === undefined
      ) {
        $("#chart_lcr")
          .closest(".stats_chartWrap")
          .css("display", "block");
      } else {
        $("#chart_lcr")
          .closest(".stats_chartWrap")
          .css("display", "none");
      }
    }
  },

  draw: {
    tpls: function(data) {
      var tpls = {};

      if (data.urlCur.sec !== "stats") {
        tpls.stats = "body";
      } else if (data.url.params.state == "appeals") {
        tpls.stats_data_appeals = "stats_data_inner";
      } else if (data.url.params.state == "charts") {
        tpls.stats_data_charts = "stats_data_inner";
      }

      if (data.url.params.skill != data.urlCur.params.skill) {
        tpls.stats_agents_list = "stats_agents_list";
      }

      if (data.history_init == true) {
        tpls.stats_filters = "stats_filters_other";
        tpls.stats_agents_list = "stats_agents_list";
      }

      return tpls;
    }
  },

  nav: {
    init: function() {
      $(".body").removeClass("load");
      core.tools.loadCode("photoswipe.css");
      core.tools.loadCode("photoswipe.min.js");
      core.tools.loadCode("photoswipe-ui-default.min.js");

      DashboardFilters_init();

      core.websocket.connect();

      core.nav.url.params.state = core.nav.url.params.state || "appeals";

      if (core.nav.url.params.state == "charts") {
        msg.stats.charts.agent_check();
        // Костыль с таймаутом для отрисовки графиков:
        // без него запросы на получение данных для графиков
        // будут редиректиться сервером на дефолтную страницу АРМ
        setTimeout(msg.stats.charts.init, 0);
      } else if (core.nav.url.params.state == "appeals") {
        $(".stats_data_innerWrap .wsScroll_content").on({
          scroll: msg.stats.appeals_pagination.load
        });
      }
      if ($(".stats_agents") === undefined) return false;
      $(window)
        .on({
          resize: msg.stats.resize
        })
        .trigger("resize");

      core.tools.loadCode("scroll.js", function() {
        $(".stats_agentsWrap").nanoScroller({
          alwaysVisible: true
        });

        $(".stats_data_innerWrap .wsScroll").nanoScroller({
          alwaysVisible: true
        });
      });

      if ($(".stats_agentsWrap .wsScroll_content")) {
        $(".stats_agentsWrap .wsScroll_content").on({
          scroll: msg.stats.agents_pagination.load
        });
      }
      if ($(".stats_agents_list")) {
        msg.stats.agents_pagination.reset();
      }
      if (
        core.nav.url.params.skill != "all" &&
        core.nav.url.params.skill &&
        core.nav.url.params.agent &&
        core.nav.url.params.agent != "all"
      ) {
        msg.stats.agent_change(false, core.nav.url.params.agent);
      }
    },

    destroy: function(data) {
      DashboardFilters_destroy();
      if (!$(".stats_data_charts")) {
        msg.stats.appeals_pagination.reset();
      } else {
        if (msg.stats.charts.el !== undefined) {
          msg.stats.charts.abort();
          msg.stats.charts_destroy();
        }
      }

      if ($(".stats_agents")) {
        msg.stats.agents_pagination.reset();
      }

      if ($(".stats_agents") === undefined) return false;

      if (
        typeof $(".stats_data_innerWrap_scroll") === "object" &&
        typeof $(".stats_data_innerWrap_scroll").nanoScroller == "function"
      ) {
        $(".stats_data_innerWrap_scroll").nanoScroller({ destroy: true });
      }

      if (
        typeof $(".stats_agentsWrap") === "object" &&
        typeof $(".stats_agentsWrap").nanoScroller == "function"
      ) {
        $(".stats_agentsWrap").nanoScroller({ destroy: true });
      }

      $(window).off({
        resize: msg.stats.resize
      });
    },

    before: function(r) {
      // Дефолтное значение для параметра state
      r.urlCur.params.state = r.urlCur.params.state || "appeals";
      r.url.params.state = r.url.params.state || "appeals";

      // Дефолтное значение для параметра skill
      r.urlCur.params.skill = r.urlCur.params.skill || "all";
      r.url.params.skill = r.url.params.skill || "all";

      // Дефолтное значение для параметра agent
      r.urlCur.params.agent = r.urlCur.params.agent || "all";
      r.url.params.agent = r.url.params.agent || "all";

      if (
        r.urlCur.params.state == "charts" &&
        r.url.params.state == "appeals"
      ) {
        msg.stats.charts.abort();
        msg.stats.charts_destroy();
        $(".stats_data_innerWrap").addClass("load");
      }

      if (r.url.params.state == "charts") {
        $(".stats_data_innerWrap").addClass("load");
      }

      if (r.urlCur.params.state == "appeals") {
        msg.stats.appeals_pagination.reset();
      }

      if (r.url.params.state == "appeals" && r.url.params.skill == "all") {
        $(".stats_data_appeals").addClass("load");
      }
    },

    after: function(r) {
      if ($(".wsScroll")[0]) {
        $(".wsScroll").nanoScroller();
      }

      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 170) window.scrollTo(0, 178);

      if (r.url.params.state == "charts") {
        msg.stats.charts.init();

        if (r.urlCur.params.state == "appeals") {
          msg.stats.appeals_pagination.reset();
          $(".stats_data_innerWrap .wsScroll_content").off({
            scroll: msg.stats.appeals_pagination.load
          });

          $(".stats_data_innerWrap_scroll").nanoScroller();
        }
      } else {
        $(".stats_data_innerWrap_scroll").nanoScroller();

        if (r.urlCur.params.state == "charts") {
          msg.stats.appeals_pagination.reset();
          $(".stats_data_innerWrap .wsScroll_content").on({
            scroll: msg.stats.appeals_pagination.load
          });

          msg.stats.charts.abort();
          msg.stats.charts_destroy();
        }
      }

      if (
        r.urlCur.params.agent != r.url.params.agent ||
        r.urlCur.params.skill != r.url.params.skill
      ) {
        if ($(".stats_agents_list"))
          $(".stats_agents_list")
            .children(".load")
            .removeClass("load");

        if (r.urlCur.params.skill != r.url.params.skill) {
          msg.stats.agents_pagination.reset();
        }
        msg.stats.appeals_pagination.reset();

        if (r.url.params.skill == "all" && r.urlCur.params.skill != "all") {
          $(".stats_agents").removeClass("load");
          $(".stats_agents_filters_itemWrap").addClass("hidden");
          $(".stats_back_btn").removeClass("load");

          $(".stats_agentsWrap").nanoScroller({
            scroll: "top"
          });
        } else if (
          r.url.params.skill != "all" &&
          r.urlCur.params.skill == "all"
        ) {
          $(".stats_agents_filters_itemWrap").removeClass("hidden");
          $(".stats_agentsWrap").nanoScroller({
            scroll: "top"
          });
        }

        $(".stats_data_innerWrap_scroll").nanoScroller({
          scroll: "top"
        });
      }

      $(".stats_data_innerWrap").removeClass("load");

      var data = r.data;

      if (r.history_init == true) {
        DashboardFilters_init();

        var state =
            r.url.params.state != undefined ? r.url.params.state : "charts",
          skill = r.url.params.skill != undefined ? r.url.params.skill : "all",
          agent = r.url.params.agent != undefined ? r.url.params.agent : "all";

        $(".stats_filters_form")
          .find('input[name="state"]')
          .val(state);
        $(".stats_data")
          .find(".stats_data_action")
          .removeClass("sel");
        $(".stats_data")
          .find(".state_" + state)
          .addClass("sel");
      }
      if (!$(".stats_agents")) {
        return false;
      }

      // Если не агент
      var sel_agent = $(".stats_filters_form")
        .find('input[name="agent"]')
        .val();
      if (!sel_agent || sel_agent == "all") {
        $(".stats_agentsWrap").nanoScroller({ scroll: "top" });
      }

      $(".stats_appeals > .item > .date > div").each(function() {
        const elem = $(this);
        const dateInMs = elem.data("date-in-ms");
        elem.text(getMessageTime(dateInMs));
      });
    }
  },

  resize: function() {
    msg.stats.height = core.user.winHeight;
    $("#msg-module .stats").css({
      height: msg.stats.height
    });
  },

  charts_init: function() {
    Highcharts.setOptions({
      credits: true,
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      lang: {
        months: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь"
        ],
        weekdays: [
          "Воскресенье",
          "Понедельник",
          "Вторник",
          "Среда",
          "Четверг",
          "Пятница",
          "Суббота"
        ],
        shortMonths: [
          "Янв",
          "Фев",
          "Мар",
          "Апр",
          "Май",
          "Июнь",
          "Июль",
          "Авг",
          "Сент",
          "Окт",
          "Нояб",
          "Дек"
        ]
      }
    });

    msg.stats.charts.el.appeals = new Highcharts.Chart({
      chart: {
        renderTo: "chart_appeals",
        type: "column",
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        animation: false
      },
      title: {
        text: ""
      },
      yAxis: {
        title: "",
        gridLineColor: "#E9EEEF",
        lineWidth: 0,
        endOnTick: false,
        labels: {
          enabled: true,
          style: {
            color: "#a0abb1"
          }
        }
      },
      xAxis: {
        type: "datetime",
        labels: {
          style: {
            color: "#a0abb1",
            fontSize: "1.0769230769em"
          }
        }
      },
      tooltip: {
        useHTML: true,
        headerFormat: "<b>{point.key}</b><table>",
        pointFormat:
          "<tr><td><small>{series.name}:</small></td>" +
          '<td style="text-align: right"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        hideDelay: 300,
        backgroundColor: "rgba(0,0,0,.8)",
        borderRadius: 5,
        borderWidth: 0,
        shadow: false,
        style: {
          color: "#FFF"
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function() {
              if (this.y != 0) {
                return this.y;
              } else {
                return null;
              }
            }
          },
          color: "#bbe082",
          states: {
            hover: {
              color: "#A4D45B"
            }
          },
          point: {
            events: {
              click: this.updateDateWithToday
            }
          },
          animation: false
        },
        series: {
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            style: {
              color: "#000"
            }
          },
          pointPadding: -0.3,
          states: {
            hover: {
              brightness: -0.2
            }
          }
        }
      },
      series: [
        {
          name: msg.lng.stats.chartAppeal,
          data: {},
          pointStart: 0,
          pointInterval: 0
        }
      ]
    });

    msg.stats.charts.el.aht = new Highcharts.Chart({
      chart: {
        renderTo: "chart_aht",
        type: "column",
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        animation: false
      },
      title: {
        text: ""
      },
      yAxis: {
        title: "",
        gridLineColor: "#E9EEEF",
        lineWidth: 0,
        endOnTick: false,
        labels: {
          enabled: true,
          style: {
            color: "#a0abb1"
          }
        }
      },
      xAxis: {
        type: "datetime",
        labels: {
          style: {
            color: "#a0abb1",
            fontSize: "1.0769230769em"
          }
        }
      },
      tooltip: {
        useHTML: true,
        headerFormat: "<b>{point.key}</b><table>",
        pointFormat:
          "<tr><td><small>{series.name}:</small></td>" +
          '<td style="text-align: right"><b>{point.y} сек</b></td></tr>',
        footerFormat: "</table>",
        hideDelay: 300,
        backgroundColor: "rgba(0,0,0,.8)",
        borderRadius: 5,
        borderWidth: 0,
        shadow: false,
        style: {
          color: "#FFF"
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function() {
              if (this.y != 0) {
                return this.y;
              } else {
                return null;
              }
            }
          },
          color: "#E9EB88",
          states: {
            hover: {
              color: "#E1E45C"
            }
          },
          point: {
            events: {
              click: this.updateDateWithToday
            },
            animation: false
          }
        },

        series: {
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            // format: '{point.y:.1f} мин',
            formatter: function() {
              if (this.y != 0) {
                return this.y;
              } else {
                return null;
              }
            },
            style: {
              color: "#000"
            }
          },
          pointPadding: -0.3,
          states: {
            hover: {
              brightness: -0.2
            }
          }
        }
      },
      series: [
        {
          name: msg.lng.stats.chartAht,
          data: {},
          pointStart: 0,
          pointInterval: 0
        }
      ]
    });

    msg.stats.charts.el.csi = new Highcharts.Chart({
      chart: {
        renderTo: "chart_csi",
        type: "column",
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0
      },
      title: {
        text: ""
      },
      yAxis: {
        title: "",
        gridLineColor: "#E9EEEF",
        lineWidth: 0,
        endOnTick: false,
        labels: {
          enabled: true,
          style: {
            color: "#a0abb1"
          }
        }
      },
      xAxis: {
        type: "category",
        labels: {
          style: {
            color: "#a0abb1",
            fontSize: "1.0769230769em"
          }
        },
        startOnTick: false
      },
      tooltip: {
        useHTML: true,
        headerFormat: "<b>CSI {point.key}</b><table>",
        pointFormat:
          "<tr><td><small>{series.name}:</small></td>" +
          '<td style="text-align: right"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        hideDelay: 300,
        backgroundColor: "rgba(0,0,0,.8)",
        borderRadius: 5,
        borderWidth: 0,
        shadow: false,
        style: {
          color: "#FFF"
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            formatter: function() {
              if (this.y != 0) {
                return this.y;
              } else {
                return null;
              }
            }
          },
          events: {
            stickyTracking: false
          },
          pointPadding: -0.2,
          states: {
            hover: {
              brightness: -0.15
            }
          },
          animation: false
        },
        column: {
          colorByPoint: true,
          colors: [
            "#dedede",
            "#ff9b8f",
            "#ffd88f",
            "#ffef8f",
            "#f6fc8e",
            "#c2f389"
          ],
          point: {
            events: {
              click: function(event) {
                msg.stats.csi_update(event.point.category);
              }
            }
          }
        }
      },
      series: [
        {
          name: msg.lng.stats.chartCsi,
          data: {}
        }
      ]
    });

    msg.stats.charts.el.ort = new Highcharts.Chart({
      chart: {
        renderTo: "chart_ort",
        type: "column",
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        animation: false
      },
      title: {
        text: ""
      },
      yAxis: {
        title: "",
        gridLineColor: "#E9EEEF",
        lineWidth: 0,
        endOnTick: false,
        labels: {
          enabled: true,
          style: {
            color: "#a0abb1"
          }
        }
      },
      xAxis: {
        type: "datetime",
        labels: {
          style: {
            color: "#a0abb1",
            fontSize: "1.0769230769em"
          }
        }
      },
      tooltip: {
        useHTML: true,
        headerFormat: "<b>{point.key}</b><table>",
        pointFormat:
          "<tr><td><small>{series.name}: </small></td>" +
          '<td style="text-align: right"><b>{point.y} %</b></td></tr>',
        footerFormat: "</table>",
        hideDelay: 300,
        backgroundColor: "rgba(0,0,0,.8)",
        borderRadius: 5,
        borderWidth: 0,
        shadow: false,
        style: {
          color: "#FFF"
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function() {
              if (this.y != 0) {
                return this.y;
              } else {
                return null;
              }
            }
          },
          color: "#ffd88f",
          states: {
            hover: {
              color: "#FFC65C"
            }
          },
          point: {
            events: {
              click: this.updateDateWithToday
            }
          },
          animation: false
        },
        series: {
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            style: {
              color: "#000"
            }
          },
          pointPadding: -0.3,
          states: {
            hover: {
              brightness: -0.2
            }
          }
        }
      },
      series: [
        {
          name: "Среднее время ответа оператора (ORT)",
          data: {},
          pointStart: 0,
          pointInterval: 0
        }
      ]
    });

    msg.stats.charts.el.sl = new Highcharts.Chart({
      chart: {
        renderTo: "chart_sl",
        type: "column",
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        animation: false
      },
      title: {
        text: ""
      },
      yAxis: {
        title: "",
        gridLineColor: "#E9EEEF",
        lineWidth: 0,
        endOnTick: false,
        labels: {
          enabled: true,
          style: {
            color: "#a0abb1"
          }
        }
      },
      xAxis: {
        type: "datetime",
        labels: {
          style: {
            color: "#a0abb1",
            fontSize: "1.0769230769em"
          }
        }
      },
      tooltip: {
        useHTML: true,
        headerFormat: "<b>{point.key}</b><table>",
        pointFormat:
          "<tr><td><small>{series.name}: </small></td>" +
          '<td style="text-align: right"><b>{point.y} %</b></td></tr>',
        footerFormat: "</table>",
        hideDelay: 300,
        backgroundColor: "rgba(0,0,0,.8)",
        borderRadius: 5,
        borderWidth: 0,
        shadow: false,
        style: {
          color: "#FFF"
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function() {
              if (this.y != 0) {
                return this.y;
              } else {
                return null;
              }
            }
          },
          color: "#ffd88f",
          states: {
            hover: {
              color: "#FFC65C"
            }
          },
          point: {
            events: {
              click: this.updateDateWithToday
            }
          },
          animation: false
        },
        series: {
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            style: {
              color: "#000"
            }
          },
          pointPadding: -0.3,
          states: {
            hover: {
              brightness: -0.2
            }
          }
        }
      },
      series: [
        {
          name: "Средний уровень SL",
          data: {},
          pointStart: 0,
          pointInterval: 0
        }
      ]
    });

    msg.stats.charts.el.lcr = new Highcharts.Chart({
      chart: {
        renderTo: "chart_lcr",
        type: "column",
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        animation: false
      },
      title: {
        text: ""
      },
      yAxis: {
        title: "",
        gridLineColor: "#E9EEEF",
        lineWidth: 0,
        endOnTick: false,
        labels: {
          enabled: true,
          style: {
            color: "#a0abb1"
          }
        }
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {},
        labels: {
          style: {
            color: "#a0abb1",
            fontSize: "1.0769230769em"
          }
        }
      },
      tooltip: {
        useHTML: true,
        headerFormat: "<b>{point.key}</b><table>",
        pointFormat:
          "<tr><td><small>{series.name}:</small></td>" +
          '<td style="text-align: right"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        hideDelay: 300,
        backgroundColor: "rgba(0,0,0,.8)",
        borderRadius: 5,
        borderWidth: 0,
        shadow: false,
        style: {
          color: "#FFF"
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function() {
              if (this.y != 0) {
                return this.y;
              } else {
                return null;
              }
            }
          },
          color: "#ff9b8f",
          states: {
            hover: {
              color: "#FF6D5C"
            }
          },
          point: {
            events: {
              click: this.updateDateWithToday
            }
          },
          animation: false
        },
        series: {
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            style: {
              color: "#000"
            }
          },
          pointPadding: -0.3,
          states: {
            hover: {
              brightness: -0.2
            }
          }
        }
      },
      series: [
        {
          name: "Количество веб-чатов",
          data: {},
          pointStart: 0,
          pointInterval: 0
        }
      ]
    });
  },

  charts_destroy: function() {
    if (!Object.keys(msg.stats.charts.el).length) return false;

    $.each(msg.stats.charts.el, function(name, chart) {
      if (typeof chart.destroy == "function") chart.destroy();

      delete msg.stats.charts.el[name];
    });
  },

  load: function(data) {
    var method = data.callback_name == "before" ? "addClass" : "removeClass";

    $.each(data.tpls, function(i, tpl) {
      $(tpl)[method]("load");
    });

    if (data.url.params.state == "charts") {
      $(".stats_data_charts")[method]("load");
    }
  },

  /**
   * Загрузка документа по указанному URL-адресу в новое окно
   * @param {String} url - строка загрузки Excel-файла
   */
  exportFile: function(url) {
    if (!url) {
      return;
    }
    window.open(url, "_self");
  },

  export_do: function(sol, userId) {
    if (!sol) return;
    var url = core.tools.copyArr(core.nav.url);
    url.params.state = sol === "/excel/appealGroup" ? "appeals" : "charts";
    if (url.params.page) delete url.params.page;
    if (url.params.tpls) delete url.params.tpls;
    url.sol = sol;
    url = core.nav.getUrl(url, "string");
    const urlAndUserId = userId ? `${url}&user_id=${userId}` : url;

    if (userId) {
      msg.stats.exportEmail(urlAndUserId);
    } else {
      msg.stats.exportFile(urlAndUserId);
    }
  },

  /** Осуществляет экспорт отчета на почту актуального пользователя. */
  exportEmail: function(url) {
    $.ajax({
      url: url,
      dataType: "JSON",
      type: "GET",
      success: function(response) {
        if (response.success === 0) {
          if (response.inprocess === 1) {
            core.informer.show(msg.lng.stats.reportIsAlreadyFormed, 3000);
          } else {
            core.informer.show(msg.lng.stats.somethingWentWrong, 3000);
          }
        } else {
          core.informer.show(msg.lng.stats.reportWillBeGenerated, 3000);
        }
      },
      error: function() {
        core.informer.show(msg.lng.stats.failedRequest, 3000);
      }
    });
  },

  /** Отправляет запрос на проверку почты актуального пользователя */
  checkEmailBeforeExportReport: function() {
    $.ajax({
      url: "/msg?sec=check_email_before_export_report",
      dataType: "JSON",
      type: "GET",
      success: function(response) {
        if (!response.data.isEmail) {
          core.informer.show(msg.lng.stats.emailIsUndefined, 3000);
        } else {
          const formData = msg.stats.prepareDataFromFilterFormToRequest();

          if (
            !core.nav.url.params.date_start ||
            !core.nav.url.params.date_end
          ) {
            core.nav.do({
              url: "/msg?sec=stats&" + formData
            });
          }

          msg.stats.export_do(msg.urlAppealsDownload, response.data.userId);
        }
      },
      error: function() {
        core.informer.show(msg.lng.stats.failedRequest, 3000);
      }
    });
  },

  export: function(url) {
    var formData = msg.stats.prepareDataFromFilterFormToRequest();
    if (!core.nav.url.params.date_start || !core.nav.url.params.date_end) {
      core.nav.do({
        url: "/msg?sec=stats&" + formData
      });
    }
    msg.stats.export_do(url);
  },

  dropdown_show_el: undefined,

  dropdown_show: function(el) {
    var dropdown = $(".dropdown_wrap");

    if (!el.hasClass("sel")) {
      msg.stats.dropdown_show_el = el;
      el.addClass("sel");
      dropdown.show();

      $(window).on({
        click: msg.stats.dropdown_hide,
        scroll: msg.stats.dropdown_hide
      });
      $("svg").on({
        mouseup: msg.stats.dropdown_hide
      });
    } else {
      $(window).click();
    }
  },

  dropdown_hide: function(e) {
    var target = $(e.target),
      isDropdown = target.closest(".dropdown_wrap").length,
      isCalendar = target.closest(".calendar_wrap").length,
      isModal = target.closest(".modal-container").length;

    if (isDropdown || isModal || isCalendar) {
      return;
    }

    if (msg.stats.dropdown_showed === true) {
      $(window).off("click", msg.stats.dropdown_hide);
      $(window).off("scroll", msg.stats.dropdown_hide);
      $("svg").off("mouseup", msg.stats.dropdown_hide);

      $(".dropdown_wrap").hide();
      msg.stats.dropdown_show_el.removeClass("sel");

      if (e !== undefined) {
        core.tools.cancelBubble(e);
      }
      msg.stats.dropdown_showed = false;
      return false;
    } else {
      msg.stats.dropdown_showed = true;
    }
  },

  dropdown_showed: false,

  report_modal: function(el) {
    if (el.hasClass("load")) {
      return false;
    }
    el.addClass("load");

    $.ajax({
      url: "/msg?sec=" + core.nav.url.sec,
      data: {
        tpls: {
          stats_report_settings: "window"
        }
      },
      dataType: "JSON",
      type: "GET",
      success: function(r) {
        core.draw.window({
          id: "msg_reportSettings",
          body: r.tpls.window,
          callback: {
            beforeClose: function() {
              msg.fancyChoice.rest.length = 0;
              msg.fancyChoice.list.length = 0;
            }
          }
        });
        el.removeClass("load");
      }
    });
  },

  report_submit: function(form) {
    /*
     * в отличие от класса load, обычно используемого везде в АРМ,
     * класс wsForm_processing добавляет невидимый слой над
     * модальным окном, который делает невозможным взаимодйствие с элементами
     * окна и делает cursor: wait;
     *
     */
    if (form.hasClass("wsForm_processing")) {
      return false;
    }
    form.addClass("wsForm_processing");
    form.find(".wsForm_fancyChoice_textarea").prop("contenteditable", false);

    var data = {
      date_start: core.tools.convertDatesToUTC(
        form.find("input[name='date_start']").val(),
        [0, 0, 0]
      ),
      date_end: core.tools.convertDatesToUTC(
        form.find("input[name='date_end']").val(),
        [23, 59, 59]
      ),
      is_all_skill: form.find("input[name='is_all_skill']").is(":checked")
        ? 1
        : 0,
      user_id: core.user.id,
      "skill_id[]": []
    };

    form.find("input[name='skill_id[]']").each(function(index, input) {
      data["skill_id[]"].push($(input).val());
    });

    $.ajax({
      url: "/msg?sec=create_chatbots_report",
      data: data,
      dataType: "JSON",
      type: "POST",
      success: function(r) {
        if (core.form.check(form, "reportSettings", r.errors) && r.data) {
          var message =
            r.data.status.trim().toLowerCase() == "processed"
              ? msg.lng.stats.appealsExportProcessed
              : msg.lng.stats.appealsExportError;

          msg.stats.report_update(r.data, message);
        } else {
          form.removeClass("wsForm_processing");
          form
            .find(".wsForm_fancyChoice_textarea")
            .prop("contenteditable", true);
        }
      },
      error: function() {
        msg.stats.report_update(
          { status: "error" },
          msg.lng.stats.appealsExportError
        );
      }
    });
  },

  report_update: function(data, message) {
    if (!~["ready", "processed", "error"].indexOf(data.status)) {
      return;
    }

    if ($(".stats_message").length) {
      $(".stats_message")
        .prev()
        .remove();
      $(".stats_message").remove();
    }

    var anchor_el = $("#msg_reportSettings .wsForm_section_border"),
      clear_fix_el = $("<div>").addClass("wsForm_field nosel clear_fix"),
      stats_message_el = $("<div>").addClass(
        "wsForm_field nosel stats_message clear_fix"
      ),
      message_el = $("<div>").addClass("message " + data.status),
      hint_el = $("<div>")
        .addClass("wsForm_fieldHint")
        .text(msg.lng.stats.appealsExportHint);

    switch (data.status) {
      case "ready":
        if (!data.filename) {
          data.filename = "report.csv";
        }
        message_el.append(
          $("<a>")
            .attr("href", "/msg?sec=download_chatbots_report")
            .text(data.filename)
        );
        if ($(".wsForm_button").prop("disabled")) {
          $(".wsForm_button").attr("disabled", false);
        }
        break;
      case "processed":
        if (message) {
          message_el.text(message);
        }
        if (!$(".wsForm_button").prop("disabled")) {
          $(".wsForm_button").attr("disabled", true);
        }
        break;
      case "error":
        if (message) {
          message_el.text(message);
        }
        if ($(".wsForm_button").prop("disabled")) {
          $(".wsForm_button").attr("disabled", false);
        }
        break;
      default:
        if (message) {
          message_el.text(message);
        }
        if ($(".wsForm_button").prop("disabled")) {
          $(".wsForm_button").attr("disabled", false);
        }
    }

    var fc_container = $(".wsForm_fancyChoice_container"),
      endDate = new Date(),
      startDate = msg.addDays(endDate, -6),
      form = $("#reportSettings_form");

    if (data.status !== "processed") {
      if (fc_container.find(".wsForm_fancyChoice_tag").length) {
        fc_container.find(".wsForm_fancyChoice_tag").click();
        setTimeout(function() {
          if (
            !$(".wsForm_checkbox")
              .find("input")
              .prop("checked")
          ) {
            $(".wsForm_checkbox").click();
          } else {
            $(".wsModal_title").click();
          }
        }, 0);
      }

      form.find("#input_date_end").val(msg.formatDate(endDate));
      form.find("#input_date_start").val(msg.formatDate(startDate));
      form.removeClass("wsForm_processing");
      form.find(".wsForm_fancyChoice_textarea").prop("contenteditable", true);
    }

    stats_message_el.append(message_el).append(hint_el);
    stats_message_el.insertAfter(anchor_el);
    clear_fix_el.insertAfter(anchor_el);
  },

  /**
   * Вернет дату начала отсчета + 23 ч. 59 мин. (конец дня).
   *
   * @param {Date} date_start Дата начала отсчета.
   */
  getDateEnd(date_start) {
    return new Date(+date_start + (23 * 60 * 60 + 59 * 60) * 1000);
  },

  updateDateWithToday(event) {
    let date_start = new Date(event.point.x);
    let timeOffsetInMS = date_start.getTimezoneOffset() * 60000;

    date_start = new Date(event.point.x + timeOffsetInMS);
    date_start.setHours(0, 0, 0, 0);
    const date_end = msg.stats.getDateEnd(date_start);

    msg.stats.date_update(
      "set_custom_date_ms",
      toUnixTimeStamp(convertToMoscowTimestamp(date_start)),
      toUnixTimeStamp(convertToMoscowTimestamp(date_end))
    );
  },

  date_update: function(period, date_start, date_end) {
    var start = new Date();
    var end = msg.formatDate(start);
    var week_length = 7; // days
    var quarter_length = 3; // months

    //warn: js Date obj has autocheks for invalid dates (e.g. (01.01.2018 - 1 month) will be 01.12.2017), but has no checks for edge cases (leap years and so on)
    switch (period) {
      case "today":
        start = end;
        break;
      case "yesterday":
        start.setDate(start.getDate() - 1);
        start = end = msg.formatDate(start);
        break;
      case "week":
        start.setDate(start.getDate() - week_length);
        start = msg.formatDate(start);
        break;
      case "month":
        start = msg.addMonths(start, -1);
        start = msg.formatDate(start);
        break;
      case "quarter":
        start = msg.addMonths(start, -quarter_length);
        start = msg.formatDate(start);
        break;
      case "set_custom_date_ms":
        if (date_start && !date_end) {
          start = end = date_start;
        } else if (!date_start && date_end) {
          start = end = date_end;
        } else if (date_start && date_end) {
          start = date_start;
          end = date_end;
        } else {
          console.warn("no date to set for stats");
          return false;
        }
        break;
      default:
        console.warn("unexpected stats period requested");
        return false;
    }
    let newUrl = "";
    for (let key in core.nav.url.params) {
      newUrl += key + "=";
      if (key === "date_start") {
        newUrl += start + "&";
      } else if (key === "date_end") {
        newUrl += end + "&";
      } else {
        newUrl += core.nav.url.params[key] + "&";
      }
    }
    core.nav.do({
      url: "/msg?sec=stats&" + newUrl
    });
    DashboardFilters_changeData();
  },

  csi_update: function(val) {
    if ($(".stats_filters_form").hasClass("others_hide"))
      $(".stats_filters_form").removeClass("others_hide");

    $(".stats_filters_csi")
      .val(val)
      .trigger("change");

    const urlParams = { ...core.nav.url.params };
    urlParams.csi = val;

    const searchParams = new URLSearchParams(urlParams).toString();

    core.nav.do({
      url: "/msg?sec=stats&" + searchParams
    });
    DashboardFilters_changeData();
  },

  prepareDataFromFilterFormToRequest: function() {
    let formData = new FormData();

    const { params } = core.nav.url;

    function createDate(defaultLimit) {
      let result = new Date(); // по умолчанию.

      if (defaultLimit) {
        const [hours, minutes, seconds] = defaultLimit.split(":");

        isFinite(+hours) && result.setHours(+hours);
        isFinite(+minutes) && result.setMinutes(+minutes);
        isFinite(+seconds) && result.setSeconds(+seconds);
      }

      // const timestamp = convertToMoscowTimestamp(result);
      // result = toUnixTimeStamp(timestamp);

      return Math.floor(result.getTime() / 1000);
    }

    if (!params["user_id"]) {
      params["user_id"] = core.user.id;
    }

    if (!params.date_start) {
      params.date_start = createDate(DEFAULT_UPPER_TIME_LIMIT);
    }

    if (!params.date_end) {
      params.date_end = createDate(DEFAULT_LOWER_TIME_LIMIT);
    }

    if (params.client) {
      const decodedClientParam = decodeURI(params.client).replaceAll("+", " ");
      params.client = decodedClientParam;
    }

    for (let key in params) {
      switch (key) {
        case "project_id":
        case "page":
        case "agent":
        case "skill":
          break;
        default:
          formData.append(key, params[key]);
      }
    }

    formData.append(
      "project_id",
      $(".stats_filters input[name=project_id]").val()
    );
    formData.append("agent", $(".stats_filters input[name=agent]").val());
    formData.append("skill", $(".stats_filters input[name=skill]").val());
    formData.append("state", $(".stats_filters input[name=state]").val());

    formData = new URLSearchParams(formData).toString();

    return formData;
  },

  stats_state: function(el, state) {
    if (el.hasClass("sel")) return false;

    $(".stats_data_header")
      .children(".stats_data_action")
      .removeClass("sel");
    el.addClass("sel");

    $("input[name=state]").val(state);
    msg.stats.appeals_pagination.step = 0;

    var formData = msg.stats.prepareDataFromFilterFormToRequest();

    core.nav.do({
      url: "/msg?sec=stats&" + formData
    });

    $(".stats_data_innerWrap .wsScroll_content").scrollTop(0);
  },

  group_change: function(el, skill_id) {
    if (el.hasClass("load")) return false;

    $(".stats_agents_list")
      .children(".sel")
      .removeClass("sel");

    el.addClass("load");

    if (skill_id == "all") {
      $(".stats_agents").addClass("load");
      $('input[name="agent"]').val("");
    } else {
      el.addClass("sel");
    }
    $('input[name="skill"]').val(skill_id);
    var formData = msg.stats.prepareDataFromFilterFormToRequest();

    core.nav.do({
      url: "/msg?sec=stats&" + formData
    });
  },

  agent_change: function(el, agent_id) {
    if (!el || el.hasClass("sel")) return false;

    $(".stats_agents_list")
      .children(".sel")
      .removeClass("sel");
    $(".stats_agents_list")
      .find(".stats_agent_wrap")
      .removeClass("sel");

    el.addClass("load").addClass("sel");

    $(".stats_filters input[name=agent]").val(agent_id);

    var formData = msg.stats.prepareDataFromFilterFormToRequest();

    core.nav.do({
      url: "/msg?sec=stats&" + formData,
      cb: () => {
        el.removeClass("load");
      }
    });
  },

  skill_set: {
    init: (e, el, skillId, skilllName) => {
      e.stopPropagation();
      showSettingsCapacityModal(skillId, skilllName);
    }
  },

  agents_pagination: {
    page: 0,
    next_step: 0,
    stop: false,
    loading: false,
    stuck_state: false,
    stuck_limit: 0,

    resize: function() {
      if (!$(".stats_agents") || !$(".stats_agents_list")) {
        return false;
      }

      $(".stats_agents").height(core.user.winHeight);
      msg.stats.agents_pagination.stuck_limit = 178; // Научиться высчитывать
      msg.stats.agents_pagination.next_step =
        $(".stats_agents_list").height() - core.user.winHeight;
      $(".stats_data").css({ minHeight: core.user.winHeight });

      core.tools.loadCode("scroll.js", function() {
        $(".stats_agentsWrap").nanoScroller({
          flash: !0,
          alwaysVisible: true
        });
      });
    },

    scroll: function() {
      if (!$(".stats_agents") || !$(".stats_agents_list")) {
        return false;
      }

      if (!msg.stats.agents_pagination.stuck_state) {
        if (msg.stats.agents_pagination.stuck_limit < core.user.scrollTop) {
          $(".stats_agents").addClass("sticky");
          msg.stats.agents_pagination.stuck_state = true;
        }
      } else {
        if (msg.stats.agents_pagination.stuck_limit > core.user.scrollTop) {
          $(".stats_agents").removeClass("sticky");
          if (
            msg.stats.agents_pagination.stuck_limit - 10 >
            core.user.scrollTop
          )
            msg.stats.agents_pagination.stuck_state = false;
        }
      }
    },
    step: 20,

    reset: function() {
      if (!$(".stats_agents") || !$(".stats_agents_list")) {
        return false;
      }
      if ($(".stats_agents_list")) {
        msg.stats.agents_pagination.next_step =
          $(".stats_agents_list").height() - core.user.winHeight;
      }
      msg.stats.agents_pagination.page = 0;
      msg.stats.agents_pagination.loading = false;
      msg.stats.agents_pagination.stop = false;
      if (
        typeof $(".stats_agentsWrap") === "object" &&
        typeof $(".stats_agentsWrap").nanoScroller == "function"
      ) {
        $(".stats_agentsWrap").nanoScroller();
      }
    },

    load: function() {
      var agentCount = $(".stats_agent_wrap").length;
      if (
        msg.stats.agents_pagination.loading ||
        msg.stats.agents_pagination.stop ||
        agentCount < msg.stats.agents_pagination.step
      ) {
        return false;
      }

      var scr = $(this).scrollTop();

      if (scr - 100 > msg.stats.agents_pagination.next_step) {
        msg.stats.agents_pagination.loading = true;

        $(".stats_agents_list").addClass("load");

        var search = $(".stats_agents_filters_item input").val();

        $.ajax({
          url: core.nav.getUrl(),
          data: {
            agent_page: msg.stats.agents_pagination.page + 1,
            agent_search: search.length ? search : undefined,
            tpls: {
              stats_agents_list: "stats_agents_list"
            }
          },
          type: "GET",
          cache: false,
          dataType: "JSON",
          success: function(r) {
            $(".stats_agents_list").removeClass("load");
            if (!$.trim(r.tpls.stats_agents_list).length) {
              msg.stats.agents_pagination.stop = true;
            }
            msg.stats.agents_pagination.page++;
            $(".stats_agents_list").append(r.tpls.stats_agents_list);
            msg.stats.agents_pagination.next_step =
              $(".stats_agents_list").height() - core.user.winHeight;
            msg.stats.agents_pagination.loading = false;
            $(".stats_agentsWrap").nanoScroller();
          }
        });
      }
    }
  },

  appeals_pagination: {
    page: 0,
    step: 0,
    stop: false,
    loading: false,

    reset: function() {
      msg.stats.appeals_pagination.page = 0;
      msg.stats.appeals_pagination.loading = false;
      msg.stats.appeals_pagination.step =
        $(document).height() - core.user.winHeight;
      msg.stats.appeals_pagination.stop = false;
    },

    load: function(e) {
      if (
        msg.stats.appeals_pagination.stop ||
        msg.stats.appeals_pagination.loading
      )
        return false;

      if ($(this).scrollTop() >= msg.stats.appeals_pagination.step) {
        $(".stats_data_appeals").addClass("load");
        msg.stats.appeals_pagination.page++;
        msg.stats.appeals_pagination.loading = true;

        var url = core.nav.url;

        url.params.page = msg.stats.appeals_pagination.page;

        url.params.tpls = {
          stats_data_inner: "stats_appeals"
        };

        $.ajax({
          url: core.nav.getUrl(url),
          type: "GET",
          cache: false,
          dataType: "JSON",
          success: function(r) {
            if (!$.trim(r.tpls.stats_appeals).length)
              msg.stats.appeals_pagination.stop = true;

            $(".stats_data_appeals").removeClass("load");
            msg.stats.appeals_pagination.step =
              $(".stats_data_inner").height() - core.user.winHeight * 1.5;

            if ($(".stats_appeals") && r.tpls.stats_appeals)
              $(".stats_appeals").append(r.tpls.stats_appeals);
            msg.stats.appeals_pagination.loading = false;

            $(".stats_data_innerWrap .wsScroll").nanoScroller();

            $(".stats_appeals > .item > .date > div").each(function() {
              const elem = $(this);
              const dateInMs = elem.data("date-in-ms");
              elem.text(getMessageTime(dateInMs));
            });
          }
        });
      }
    }
  },

  agent_search_load: undefined,

  agent_search_delay: undefined,

  agent_search: function(el) {
    if (msg.stats.agent_search_delay != undefined) {
      clearTimeout(msg.stats.agent_search_delay);
    }

    if (
      msg.stats.agent_search_load != undefined &&
      msg.stats.agent_search_load.readyState == 1
    ) {
      msg.stats.agent_search_load.abort();
    }

    msg.stats.agent_search_delay = setTimeout(function() {
      $(".stats_agents").addClass("load_search");

      $.ajax({
        url: "/msg?sec=stats",
        data: {
          project_id: core.nav.url.params.project_id || 1,
          skill: $("input[name=skill]").val(),
          agent_search: el.val(),
          tpls: {
            stats_agents_list: "stats_agents_list"
          }
        },
        dataType: "JSON",
        type: "GET",
        success: function(r) {
          $(".stats_agents_list").html(r.tpls.stats_agents_list);
          msg.stats.agents_pagination.reset();
          $(".stats_agents").removeClass("load_search");
        }
      });
    }, 300);
  },

  appeals: {
    init: function(appealId) {
      const modalId = "appealInfoModal";
      const modal = new Modal(modalId, 980, (close) => (
        <AppealInfoModalProvider
          filesUrl={core.fileStorage}
          appealId={appealId}
          close={close}
        />
      ));
      modal.open();
    }
  },

  timer: {
    tooltip: function(el) {
      core.tooltip.show(el, {
        body: msg.lng.stats.tooltipTimer,
        align: "top_left",
        position: {
          top: 0,
          left: -22
        }
      });
    }
  },

  theme_tooltip: function(el, field) {
    core.tooltip.show(el, {
      body: field + ":",
      align: "top_center",
      position: {
        top: -5,
        left: 5
      }
    });
  }
};
