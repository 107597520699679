import { takeLatest, put, actionChannel } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  GET_REPLIES_CATEGORIES,
  SET_REPLIES_CATEGORIES,
  ADD_REPLIES_CATEGORIES
} from "../Consts";

export function* getCategoriesSaga(action) {
  const page = action.payload;

  try {
    const response = yield httpRequest("GET", "bff/settings/categories", {
      query: {
        page
      }
    });

    if (response?.success) {
      if (page === 0) {
        yield put({
          type: SET_REPLIES_CATEGORIES,
          payload: response.data?.content.map((el) => ({
            id: el.id,
            value: el.id,
            label: el.name
          }))
        });
      } else {
        yield put({
          type: ADD_REPLIES_CATEGORIES,
          payload: response.data?.content.map((el) => ({
            id: el.id,
            value: el.id,
            label: el.name
          }))
        });
      }
    }
  } catch (e) {
    console.error("Saga: getCategoriesSaga error", e);
  }
}

export default function* () {
  yield takeLatest(GET_REPLIES_CATEGORIES, getCategoriesSaga);
}
