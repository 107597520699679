import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../ReactCore/shared/httpRequest";
import { SKILL_GROUP_DICTIONARY_INIT } from "./consts";
import { setSkillGroupDictionary } from "./skillGroupModal.actions";

export function* skillGroupDictionaryInit(action) {
  try {
    const response = yield httpRequest(
      "GET",
      `bff/settings/skill-groups/modal/init`
    );
    if (
      response?.botProfiles?.success &&
      response?.regions?.success &&
      response?.mrf?.success &&
      response?.channels?.success
    ) {
      const { botProfiles, regions, mrf, channels } = response;
      yield put(
        setSkillGroupDictionary({
          botProfiles: botProfiles.data,
          regions: regions.data,
          mrf: mrf.data,
          channels: channels.data
        })
      );
    }
  } catch ($error) {
    console.error($error);
  }
}

export default function* () {
  yield takeEvery(SKILL_GROUP_DICTIONARY_INIT, skillGroupDictionaryInit);
}
