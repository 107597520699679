import { createAction } from "@reduxjs/toolkit";

export const resetDialogList = createAction("RESET_DIALOG_LIST");
export const setChatInitialDataLoaded = createAction(
  "SET_CHAT_INITIAL_DATA_LOADED"
);
export const updateDialogList = createAction<any>("UPDATE_DIALOG_LIST");
export const fetchClientDataForDialogPreview = createAction<any>(
  "FETCH_CLIENT_DATA_FOR_DIALOG_PREVIEW"
);
export const setCsiSettings = createAction<any>("SET_CSI_SETTINGS");
export const getChatInitialData = createAction<(data: {}) => {}>(
  "GET_CHAT_INITIAL_DATA"
);
export const setIntegration = createAction<
  (data: { integrationType: string; data: {} }) => {}
>("SET_CHAT_INTEGRATION");
