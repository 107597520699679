import React, { useRef, useState, useEffect } from "react";

import {
  Select,
  Icon,
  ScrollBox,
  Tooltip,
  isEmpty,
} from "@omnichat/arm_ui_kit";

import CategoryItem from "./CategoryItem";
import { MinCategoryListOffsetTop } from "./Consts";

import * as s from "./styles.module.scss";

/**
 * Модель данных
 */
export interface ICategoryListProps {
  skillGroups: {
    value: number | string;
    label: string;
  }[];
  skillGroup: number;
  categories: {
    id: number | string;
    name: string;
    current: boolean;
    editable: boolean;
  }[];
  onSelectSkillgroup: (id: number) => void;
  onSelectCategory: (id: number | string) => void;
  onEditCategory: (id?: number) => void;
  onNextPage: () => void;
  onAdd: () => void;
}

/**
 * Компонент списка категорий
 */
const CategoryList = ({
  skillGroups,
  categories,
  onSelectCategory,
  onEditCategory,
  onAdd,
  onSelectSkillgroup,
  skillGroup,
  onNextPage,
}: ICategoryListProps): JSX.Element => {
  const [categoryListOffsetTop, setCategoryListOffsetTop] = useState(
    MinCategoryListOffsetTop
  );
  const categoryHeaderRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (categoryHeaderRef?.current?.clientHeight) {
      setCategoryListOffsetTop(
        Math.max(
          MinCategoryListOffsetTop,
          categoryHeaderRef.current.clientHeight
        )
      );
    }
  });

  return (
    <div className={s["categories"]}>
      <div ref={categoryHeaderRef} className={s["categoriesHeader"]}>
        <div className={s["categoriesHeaderBtnWrap"]}>
          <h4 className={s["categoriesTitle"]}>Категории</h4>
          <Tooltip
            content="Добавить категорию"
            position="top"
            modifiers={{ offset: 10 }}
          >
            <div className={s["categoriesAdd"]} onClick={onAdd}>
              <Icon
                color={"blue"}
                name={"plus_bold"}
                width="18px"
                height="18px"
              />
            </div>
          </Tooltip>
        </div>

        <div className={s["categoriesSelect"]}>
          <Select
            options={skillGroups}
            selected={[skillGroup]}
            onSelectOption={(selected) => onSelectSkillgroup(+selected[0])}
          />
        </div>
      </div>
      <div className={s["categoriesList"]}>
        <ScrollBox
          autoHeight
          hasScrollControlBackground
          onPaginationBottom={() => onNextPage()}
        >
          <div
            style={{ marginTop: categoryListOffsetTop }}
            className={s["categoriesListContentBlock"]}
          >
            {!isEmpty(categories) &&
              categories.map(({ id, name, current, editable }, i) => (
                <CategoryItem
                  key={i}
                  id={id}
                  name={name}
                  current={current}
                  editable={editable}
                  onSelect={(id) => onSelectCategory(id)}
                  onEdit={(id) => onEditCategory(id)}
                />
              ))}
          </div>
        </ScrollBox>
      </div>
    </div>
  );
};

export default CategoryList;
