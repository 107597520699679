import { createSlice } from "@reduxjs/toolkit";
import {
  setFulfilledReducer,
  setPendingReducer,
  setRejectedReducer
} from "../../ReactFeatures/Common/Reducers/LoadingData.reducers";
import { EDOWNLOAD_STATUS } from "../../ReactFeatures/Common/Enums/LoadingData.enums";
import { CHANNELS_DATA_STATE_KEY, NAMESPACE } from "./consts";
import {
  isPendingAction,
  isFulfilledAction,
  isRejectedAction
} from "../../ReactFeatures/Common/Utils/LoadingData.utils";

const SettingsChannelsSlice = createSlice({
  name: NAMESPACE,
  initialState: {
    [CHANNELS_DATA_STATE_KEY]: {
      status: EDOWNLOAD_STATUS.IDLE,
      data: null
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(isPendingAction, setPendingReducer)
      .addMatcher(isFulfilledAction, setFulfilledReducer)
      .addMatcher(isRejectedAction, setRejectedReducer);
  }
});

export default SettingsChannelsSlice.reducer;
